import React from "react";
import CanvasThree from './CanvasThree';
import style from "../../css/Work.module.css";
import ic_gxh from "../../images/ic_gxh.jpg";
import ic_home from "../../images/ic_home.jpg";
import ic_lt from "../../images/ic_lt.jpg";
import ic_fit from "../../images/ic_fit.jpg";
import img_models from "../../images/img_models.jpg";

class PhChoose extends React.PureComponent {
	constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight
    }
    this.aiPhone = "拍照分析身型专属量体";
    this.fit = '面料样式我自主';
    this.living_home = '特有平面智能立裁快速适衣';
    this.individuality = '服装与涂鸦';
	}

  render() {
    return(
			<div className={ style.phChoose } style={{ height: this.state.screenHeight * 0.549 }}>
          <div className={ style.phOneChoose }>
            <div className={ style.phChooseVerticalOne } style={{top: this.state.screenHeight * 0.15}} >
              <div className={ style.verTextOne }>
                <div>
                  <img src={ ic_lt } alt = "lt" width={ this.state.screenHeight * 0.06 } />
                  <div className={ style.verTextOneCircle } style={{ width: this.state.screenHeight * 0.06,height: this.state.screenHeight * 0.06}}>
                    <div className={ style.verTextOneCircleLittle }></div>
                  </div>
                </div>
                <div style={{ marginLeft: this.state.screenWidth * 0.037 }}>
                  <span>AI量体</span>
                  <br />
                  <div className={style.aiPhone}>
                    {this.aiPhone.split("").map((e,i) => {
                      return <span key={i} style={{'--s':(0.5/10)*(i+1)+'s'}}>{e}</span>})}
                  </div>
                </div>
              </div>
              <div className={style.verTextTwo}>
                <div>
                  <img src={ic_fit} alt = "fit" width={this.state.screenHeight * 0.06}/>
                  <div className={style.verTextTwoCircle} style={{ width: this.state.screenHeight * 0.06,height: this.state.screenHeight * 0.06 }}>
                    <div className={style.verTextTwoCircleLittle}></div>
                  </div>
                </div>
                <div style={{ marginRight: this.state.screenWidth * 0.037 }}>
                  <span>自由</span>
                  <br />
                  <div className={style.fit} id="fit">
                    {this.fit.split("").map((e,i)=>{
                      return <span key={i} style={{'--s':0.5/7*(i+1)+'s'}}>{e}</span>})}
                  </div>
                </div>
              </div>
            </div>
            <div className={style.phChooseVerticalTwo} style={{ top: this.state.screenHeight*0.41, }}>
              <div className={style.phChooseTwoVertOne}>
                <div>
                  <img src={ic_home} alt = "home" width={this.state.screenHeight * 0.06}/>
                  <div className={style.phChooseTwoVertOneCircle} style={{ width: this.state.screenHeight * 0.06,height: this.state.screenHeight * 0.06 }}>
                    <div className={style.phChooseTwoVertOneCircleLittle}></div>
                  </div>
                </div>
                <div style={{ marginLeft: this.state.screenWidth * 0.037 }}>
                  <span>合体</span>
                  <br />
                  <div className={style.living_home}>
                    { this.living_home.split("").map((e,i)=>{
                      return <span key={i} style={{'--s':0.5/12*(i+1)+'s'}}>{e}</span>})}
                  </div>
                </div>
              </div>
              <div className={style.phChooseTwoVertTwo}>
                <div>
                  <img src={ic_gxh} alt = "gxh" width={this.state.screenHeight * 0.06}/>
                  <div className={style.phChooseTwoVertTwoCircle} style={{ width: this.state.screenHeight * 0.06, height: this.state.screenHeight * 0.06 }}>
                    <div className={style.phChooseTwoVertTwoCircleLittle}></div>
                  </div>
                </div>
                <div style={{ marginRight: this.state.screenWidth * 0.037 }}>
                  <span>定制</span>
                  <br />
                  <div className={style.individuality}>
                    { this.individuality.split("").map((e,i)=>{
                      return <span key={i} style={{'--s':0.5/5*(i+1)+'s'}}>{e}</span>})}
                  </div>
                </div>
              </div>
            </div>
            <div className={style.phChooseModels}>
              <img style={{width: this.state.screenWidth * 0.106}} src={img_models} alt = "models"/>
            </div>
          </div>
          <div className={style.phTwoChoose}><CanvasThree /></div>
        </div>
		)
  }
}
export default PhChoose;