import React from "react";

class Circle extends React.Component {
  render() {
    return (
      <li className="navigation-circle-list-item">
        <span className="navigation-circle-list-item__point" onClick={this.props.onClick} onMouseEnter={this.props.calculateOffset} onMouseLeave={this.props.onMouseLeave} >
          <div className="navigation-circle-list-item__meta" style={this.props.style}>
            <span className="navigation-circle-list-item__title">
              {this.props.title}
            </span>
            <span className="navigation-circle-list-item__subtitle">
              {this.props.introduce}
            </span>
          </div>
        </span>
      </li>
    );
  }
}

export default Circle;