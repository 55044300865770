import React from "react";

class CanvasPhTwo extends React.PureComponent {
  render() {
    return (
      <canvas id="canvasTwo" height={this.state.screenHeight} width={this.state.screenWidth} style={{ height: this.state.screenHeight, width: this.state.screenWidth }}></canvas>
    )
  }
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    }
    this.canvasTwo = "";
    this.ctx = "";
    this.linearGradient = "";
    this.y = parseInt(this.state.screenHeight * 0.15);
    this.minY = 0;
    this.maxY = parseInt(this.state.screenHeight * 0.3);
    this.Ystatus = false;
    this.timer = '';
  }

  componentDidMount() {
    this.animat();
  }

  componentWillUnmount(){
    window.cancelAnimationFrame(this.timer);
  }
  
  animat = () => {
    this.timer = window.requestAnimationFrame(this.animat);
    this.canvasTwo = document.getElementById("canvasTwo");
    this.ctx = this.canvasTwo.getContext('2d');
    let screenWidth = this.state.screenWidth, screenHeight = this.state.screenHeight;
    this.canvasTwo.width = screenWidth;
    this.canvasTwo.height = screenHeight;  
    
    this.ctx.imageSmoothingEnabled = true;
    this.linearGradient = this.ctx.createLinearGradient(0, screenWidth, 0, screenHeight);
    this.linearGradient.addColorStop(0, "#d5443e");
    this.linearGradient.addColorStop(1, "#ed5e58");
    if (this.Ystatus === false && this.y < this.maxY) {
      this.y += 0.5;
    } else if (this.Ystatus === true && this.y > this.minY) {
      this.y -= 0.5;
    }
    if (this.y === this.minY) {
      this.Ystatus = false;
    } else if (this.y === this.maxY) {
      this.Ystatus = true;
    }
    this.ctx.fillStyle=this.linearGradient;
    this.ctx.fillStyle="#fff";
    this.ctx.fillRect(0, 0, screenWidth, screenHeight*0.25);
    this.ctx.beginPath();
    this.ctx.fillStyle="#ed5e58";
    this.ctx.moveTo(0, 0);
    this.ctx.lineTo(0, screenHeight * 0.15);
    this.ctx.quadraticCurveTo(screenWidth / 4, screenHeight * 0.05 + this.y,
      screenWidth / 2, screenHeight * 0.15);
    this.ctx.quadraticCurveTo(screenWidth / 4 * 3, screenHeight * 0.25 - this.y,
      screenWidth, screenHeight * 0.15);
    this.ctx.lineTo(screenWidth, 0);
    this.ctx.fill();
    const height = 0.96;
    this.ctx.beginPath();
    this.ctx.fillStyle="#d5443e";
    this.ctx.moveTo(0, screenHeight * 0.8);
    this.ctx.bezierCurveTo(
      screenWidth / 12 * 5, screenHeight * 0.8,
      screenWidth / 12 * 4, screenHeight * height,
      screenWidth / 2, screenHeight * height
    );
    this.ctx.bezierCurveTo(
      screenWidth / 12 * 8, screenHeight * height,
      screenWidth / 12 * 7, screenHeight * 0.8,
      screenWidth, screenHeight * 0.8
    );
    this.ctx.lineTo(screenWidth, screenHeight * 0.8);
    this.ctx.lineTo(screenWidth, screenHeight);
    this.ctx.lineTo(0, screenHeight);
    this.ctx.lineTo(0, screenHeight * 0.8);
    this.ctx.fill();
  }
}

export default CanvasPhTwo;