import React from "react";
import Module from "./Module";
import molecule from "./img/ic_molecule.jpg";
import gltf from "../../gltf/we.gltf";

class We extends React.Component {
  render() {
    return <Module src={molecule} zhVal="我们自己" enVal="Ourselves" module={gltf}  camera="4.6" x="1.2" y="-2.2"/>
  }
}

export default We;