import React from "react";
import style from '../css/Flame.module.css';

class Flame extends React.PureComponent {
  render() {
    return (<>
      <div className={style.main}></div>
      <div className={style.footer}>
        <div className={style.bubbles}>
          <div className={style.bubble} style={{ "--size": "0.7213421480238473rem", "--distance": "4.505346460759434rem", "--position": "40.22788841130817%", "--time": "2.5579428080835602s", "--delay": "-2.551388062067941s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.500247300710568rem", "--distance": "4.2118813086522735rem", "--position": "87.07174911124845%", "--time": "3.7950243388260327s", "--delay": "-3.270237306503343s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.3242940429011956rem", "----distance": "4.044944494811669rem", "--osition": "81.28750378546107%", "--time": "2.629329053292902s", "--delay": "-2.4302631916260564s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.368267979463531rem", "--distance": "4.984903032149823rem", "--position": "24.672901605467377%", "--time": "3.6922347100113684s", "--delay": "-2.7637748546263308s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.308292712367696rem", "----distance": "4.240308566969028rem", "--position": "-2.2618473747363588%", "--time": "2.7783399326076745s", "--delay": "-2.422323317653233s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.589049422686883rem", "----distance": "4.620168432414982rem", "--position": "57.960067264348396%", "--time": "3.1677481470068454s", "--delay": "-3.9895937435743383s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.012494033150956rem", "----distance": "4.215070310722501rem", "--osition": "81.90156330955398%", "--time": "3.581799241298079s", "--delay": "-3.2842101855509367s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.486463553636168rem", "--distance": "3.178724244451335rem", "--osition": "80.8496390776951%", "--time": "3.942288747270057s", "--delay": "-2.4935541596119926s," }}></div>
          <div className={style.bubble} style={{ "--size": "1.5838969336339064rem", "--distance": "3.512391306969278rem", "--position": "0.42123986082513554%", "--time": "2.1258922491501977s", "--delay": "-3.333826864507246s" }} ></div>
          <div className={style.bubble} style={{ "--size": "1.079803260056025rem", "--distance": "4.388128560454028rem", "--position": "20.300017386843756%", "--time": "2.659418516406643s", "--delay": "-3.078550380210786s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.6577705907998803rem", "--distance": "4.905163352149756rem", "--position": "-3.9018298355419367%", "--time": "3.2166315563669725s", "--delay": "-3.749328245209214s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.2293257420881094rem", "--distance": "4.3675369567357185rem", "--position": "20.20898718445759%", "--time": "2.31254483043571s", "--delay": "-3.5507459589952064s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.180004160345056rem", "--distance": "4.267635853498593rem", "--osition": "86.60912315927816%", "--time": "3.1210635860440274s", "--delay": "-2.2517472307884163s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.0066820744313763rem", "----distance": "4.817672455095477rem", "--position": "56.22441021925781%", "--time": "3.1747716675531716s", "--delay": "-3.989920132346828s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.057806550977351rem", "----distance": "4.631286325199999rem", "--position": "53.439190329260384%", "--time": "2.538318608236518s", "--delay": "-3.6058974363494176s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.153210319185682rem", "--distance": "4.748442380597138rem", "--position": "46.17466989898253%", "--time": "2.1432715254569605s", "--delay": "-2.4479428801056713s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.98933774997945rem", "--distance": "3.666064909668048rem", "--position": "52.397769148841%", "--time": "3.8833002688420803s", "--delay": "-2.3399768430183183s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.76341936376016rem", "--distance": "3.835404006581397rem", "--position": "83.40657411802158%", "--time": "2.3694877694487455s", "--delay": "-2.5515274663024976s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.1595918171618855rem", "--distance": "4.786646387293258rem", "--position": "59.394174388666585%", "--time": "2.3760267516199907s", "--delay": "-2.0849342793522982s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.429443953565971rem", "----distance": "4.575782798489372rem", "--position": "61.249755683418286%", "--time": "2.842353131011543s", "--delay": "-2.1027326065636345s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.2144705898238275rem", "--distance": "4.087950458446308rem", "--position": "11.578968385739547%", "--time": "2.9795769810180297s", "--delay": "-3.516113904361346s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.7066806965064094rem", "--distance": "4.436928894924051rem", "--position": "33.432023550513605%", "--time": "3.7613500043898074s", "--delay": "-3.53445226410586s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.104383756779172rem", "--distance": "4.620940616235805rem", "--position": "-0.39255488604776456%", "--time": "3.5310311679177038s", "--delay": "-2.161171256635479s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.9413132397930957rem", "----distance": "4.947766367749181rem", "--position": "65.25160647497219%", "--time": "3.2219451773698653s", "--delay": "-2.2569783138488404s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.0686757597012067rem", "--distance": "4.893296163121473rem", "--position": "2.1134983030132286%", "--time": "2.7885612369031696s", "--delay": "-3.1196641649327104s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.5749252792442308rem", "--distance": "3.66338452173652rem", "--position": "26.416830191268613%", "--time": "3.91957671849049s", "--delay": "-2.772278139472696s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.4852892532083226rem", "--distance": "4.372980787806562rem", "--position": "81.69893117792282%", "--time": "3.079033426857241s", "--delay": "-2.2962941351541066s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.5629607641353385rem", "--distance": "4.15017701520257rem", "--position": "3.105398966952176%", "--time": "3.8554306889400634s", "--delay": "-3.7866730609283232s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.156331504196541rem", "----distance": "4.893943184322224rem", "--position": "29.941061639204904%", "--time": "3.7116694507976793s", "--delay": "-2.3337675677493954s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.3215545411427385rem", "--distance": "4.004429773836247rem", "--position": "56.96669459182739%", "--time": "2.8864193873030617s", "--delay": "-3.578385350044105s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.967042994441611rem", "----distance": "4.806617503673871rem", "--position": "27.904419423545683%", "--time": "3.1064701594693727s", "--delay": "-3.0952280145141877s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.1923907792593242rem", "--distance": "4.366909375003944rem", "--position": "86.29562524283297%", "--time": "2.708956028455526s", "--delay": "-2.676118835629987s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.8303927620487226rem", "--distance": "3.320021996730327rem", "--position": "57.044744087435625%", "--time": "3.8946154027139723s", "--delay": "-2.736024629506094s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.840433976595762rem", "----distance": "4.124291394138918rem", "--position": "19.615879909260173%", "--time": "3.182682726127182s", "--delay": "-2.4383518393902364s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.359738453378172rem", "--distance": "4.694070654732667rem", "--position": "-3.5304309415941915%", "--time": "2.3799895423072206s", "--delay": "-3.7871528366504954s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.9172723450704963rem", "----distance": "4.219471945888807rem", "--position": "71.23776687031318%", "--time": "2.691815593023614s", "--delay": "-2.225664489249637s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.4260549202674433rem", "--distance": "3.943991779712878rem", "--position": "-2.938815542903006%", "--time": "3.667901586848475s", "--delay": "-2.0318348941666984s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.218428909922971rem", "----distance": "4.182218880714959rem", "--position": "47.599720118871986%", "--time": "3.1996277643035635s", "--delay": "-2.464039938715248s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.564796444492704rem", "--distance": "3.913141460070921rem", "--position": "69.83741678384754%", "--time": "2.39189940545819s", "--delay": "-2.7063538327823906s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.098964865677504rem", "--distance": "4.95039554834426rem", "--position": "40.157073574007555%", "--time": "2.275800428245597s", "--delay": "-2.2722033002581936s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.599047497163818rem", "--distance": "4.377199757122415rem", "--position": "29.859268972166625%", "--time": "3.1570487162264005s", "--delay": "-3.7780660129491483s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.812990480636346rem", "--distance": "4.101286142156095rem", "--position": "56.0338888659367%", "--time": "3.3562636721239847s", "--delay": "-3.4442407120291434s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.483552302179455rem", "--distance": "3.417061474225672rem", "--position": "68.298840831868%", "--time": "2.428506649257031s", "--delay": "-2.36440965304464s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.2990132709879783rem", "--distance": "4.105634132691563rem", "--position": "32.73888158484342%", "--time": "2.6526667425255837s", "--delay": "-2.997246613468293s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.081841190111142rem", "----distance": "4.749104399277604rem", "--position": "82.88764394700121%", "--time": "3.4435901938197864s", "--delay": "-3.0643170692531396s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.852384871348338rem", "--distance": "4.790080620464186rem", "--position": "88.84725368719968%", "--time": "3.559321194899361s", "--delay": "-2.3701954209883715s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.6558378765008577rem", "--distance": "4.04095445363605rem", "--position": "80.35247944714001%", "--time": "3.3688096550555784s", "--delay": "-2.6850064312814657s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.503592657387973rem", "----distance": "4.375452014583938rem", "--position": "72.78497998584217%", "--time": "2.088851135591279s", "--delay": "-3.124184418812894s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.028220423585446rem", "----distance": "4.020594686399985rem", "--position": "43.49083124302713%", "--time": "2.3012971951004886s", "--delay": "-3.367429486079976s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.807397819266087rem", "--distance": "4.737428987328318rem", "--position": "61.94042762872539%", "--time": "2.9311315760417296s", "--delay": "-3.0308064251628073s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.415711305392612rem", "--distance": "4.021648548930554rem", "--position": "56.59277539485453%", "--time": "2.268753416727909s", "--delay": "-3.5104872716555873s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.286603132698011rem", "--distance": "4.297420030694423rem", "--position": "14.8166867949476%", "--time": "2.314812495746604s", "--delay": "-2.2206490941502603s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.084582189084247rem", "--distance": "4.053806489526212rem", "--position": "17.221042938135533%", "--time": "3.262647968703504s", "--delay": "-3.8502081602963183s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.2762484661878393rem", "--distance": "4.78193977982611rem", "--position": "26.01894751803159%", "--time": "2.8439436154366557s", "--delay": "-2.681880163843175s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.597148427575343rem", "----distance": "4.250631215865188rem", "--osition": "81.45952345497969%", "--time": "3.367563745446926s", "--delay": "-2.9517864406891903s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.3645212886613383rem", "--distance": "4.82698266080802rem", "--position": "-2.641352198721856%", "--time": "2.7665246609510623s", "--delay": "-3.9029501481358766s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.1050058132039293rem", "--distance": "4.720731777636572rem", "--position": "71.20447371348473%", "--time": "2.467643196205392s", "--delay": "-2.6366823226599325s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.59419080877091rem", "--distance": "4.09935734477768rem", "--position": "-4.617665283320269%", "--time": "2.662075916876629s", "--delay": "-3.6100650996417034s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.414198576522754rem", "--distance": "3.691727587152284rem", "--position": "20.93958233042159%", "--time": "2.8003242399615313s", "--delay": "-3.4586762374857827s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.2922500389749256rem", "--distance": "4.82228036779411rem", "--position": "15.824079658804212%", "--time": "3.723359257283692s", "--delay": "-2.9762422882675503s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.069825573512012rem", "----distance": "4.729884512245837rem", "--position": "44.63860741203062%", "--time": "2.1920700008241996s", "--delay": "-2.604461568979024s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.1435358039543413rem", "----distance": "4.054227965775346rem", "--position": "87.643756550518%", "--time": "2.332925217565753s", "--delay": "-2.5761875943663064s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.3583559019401976rem", "--distance": "4.764733021851988rem", "--position": "3.7290635087002144%", "--time": "3.5068003806723724s", "--delay": "-2.5828712893239647s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.8248090471581566rem", "--distance": "3.967250080121786rem", "--position": "44.77044595200322%", "--time": "2.3789602530789296s", "--delay": "-3.3242472302432606s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.6017134126261476rem", "----distance": "4.429923842885037rem", "--position": "11.201154402683716%", "--time": "3.381121786305072s", "--delay": "-3.8498781033523586s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.588935474433315rem", "--distance": "4.197034051349954rem", "--position": "28.536984285473594%", "--time": "3.3999929861860396s", "--delay": "-3.0419132451241637s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.1127196995697926rem", "--distance": "4.145314830288614rem", "--position": "30.358607237008435%", "--time": "2.6837213243338347s", "--delay": "-2.9263376776930183s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.732193803481975rem", "--distance": "4.012915182836787rem", "--osition": "80.61195558524308%", "--time": "2.4874746483665158s", "--delay": "-2.6057830012904732s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.026851135692353rem", "--distance": "4.053400484757766rem", "--position": "69.76565213213304%", "--time": "2.3190141309213708s", "--delay": "-3.6131310656799482s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.175015430336033rem", "----distance": "4.911237376175875rem", "--position": "3.079285659018584%", "--time": "3.936965614168768s", "--delay": "-2.252697285627746s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.357711116900091rem", "--distance": "4.686136767110483rem", "--osition": "80.13888313407351%", "--time": "3.311645659600232s", "--delay": "-2.079592162888921s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.024172603169807rem", "--distance": "3.19851429890631rem", "--position": "21.03229169624483%", "--time": "3.5885519731256346s", "--delay": "-3.7752430526702585s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.2088820812271965rem", "----distance": "4.974270011243829rem", "--position": "50.527018423999195%", "--time": "2.6938933537801732s", "--delay": "-3.4065858981988364s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.69295283226577rem", "--distance": "4.733479032371475rem", "--position": "2.731346525241076%", "--time": "2.266090454533562s", "--delay": "-3.7088136637491065s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.6255135879330656rem", "----distance": "4.332470193004621rem", "--position": "74.4317495925434%", "--time": "3.0146205486299165s", "--delay": "-3.694373829082397s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.354832649763977rem", "----distance": "4.561964939596561rem", "--position": "18.79690420195934%", "--time": "2.174260917162371s", "--delay": "-3.3907345055954297s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.878103515413006rem", "----distance": "4.742046558072401rem", "--osition": "81.5978626583434%", "--time": "2.041979215717721s", "--delay": "-3.686092180792864s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.041698891717493rem", "----distance": "4.5412588551913675rem", "--position": "1.2600821219082352%", "--time": "2.1507202592129384s", "--delay": "-2.5763284500008026s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.990912876392974rem", "--distance": "3.536116770068723rem", "--osition": "81.39362061841086%", "--time": "2.131444860961228s", "--delay": "-3.379001265005689s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.9468202047884047rem", "----distance": "4.607643793153973rem", "--position": "58.399907460561025%", "--time": "3.868603530457586s", "--delay": "-3.3227943979887766s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.5132716422531667rem", "--distance": "3.723162083649797rem", "--position": "45.63921251648086%", "--time": "2.7819123278551414s", "--delay": "-2.9246090524729467s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.901037284441659rem", "--distance": "4.375928138271362rem", "--position": "-2.022440184413934%", "--time": "3.0931282962799718s", "--delay": "-3.6206444850380866s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.43585336717728rem", "----distance": "4.0981886486306065rem", "--position": "70.98674097255997%", "--time": "3.545305874917485s", "--delay": "-2.860125978121704s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.8959781160583793rem", "----distance": "4.365826512997043rem", "--position": "68.0439973024214%", "--time": "3.3847564704214843s", "--delay": "-3.822661909267979s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.190862602120104rem", "--distance": "3.324286464231738rem", "--osition": "80.63326135594109%", "--time": "3.125738092636041s", "--delay": "-2.0231464816737583s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.815190322409424rem", "--distance": "4.98783615178845rem", "--osition": "80.07315713020596%", "--time": "2.199719637569192s", "--delay": "-2.912553623383784s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.4408431189917605rem", "--distance": "3.410814913416157rem", "--position": "73.43163843646305%", "--time": "2.520105191761856s", "--delay": "-3.173699519177059s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.323125867269262rem", "--distance": "3.681705567438865rem", "--position": "22.458012814024496%", "--time": "2.862860362033104s", "--delay": "-2.365329024943425s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.87248576235681rem", "--distance": "3.317992059759069rem", "--position": "75.35975316725816%", "--time": "3.574332205732238s", "--delay": "-2.0620789526907592s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.923072090620831rem", "--distance": "3.953823542200809rem", "--osition": "81.36512832329207%", "--time": "3.9979594498259594s", "--delay": "-3.7852433176584195s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.990225616669818rem", "--distance": "4.561203225220017rem", "--osition": "80.06838506645019%", "--time": "3.329215784120532s", "--delay": "-2.5657704976757043s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.038886688818636rem", "--distance": "3.157407024912434rem", "--osition": "82.10808210320384%", "--time": "2.5260408405632795s", "--delay": "-3.9834394017712014s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.922533507877614rem", "--distance": "4.409188909824167rem", "--position": "40.74950762515172%", "--time": "3.4490603559034727s", "--delay": "-3.1504161951412s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.304866751928903rem", "--distance": "3.17082928129505rem", "--position": "78.17787284577825%", "--time": "3.021405986111895s", "--delay": "-2.3560818679987223s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.274752031680482rem", "--distance": "4.643662561373309rem", "--position": "80.59934371496355%", "--time": "3.6682255755161366s", "--delay": "-3.445785165457048s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.688111382200143rem", "--distance": "4.3735178052196035rem", "--position": "53.09900512842178%", "--time": "3.679651166985848s", "--delay": "-3.0800302372107873s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.2965456751252633rem", "--distance": "3.19342506137584rem", "--position": "84.51683735770193%", "--time": "2.8903826307972222s", "--delay": "-3.27219653403073s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.857684238315157rem", "--distance": "4.987822960839322rem", "--position": "76.40824609598796%", "--time": "2.2033353153987325s", "--delay": "-2.0857868352460733s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.8311666093840486rem", "--distance": "4.056656169129441rem", "--osition": "81.75369531713136%", "--time": "3.238665028465137s", "--delay": "-3.0869610042225903s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.026279731555598rem", "--distance": "4.64416472144952rem", "--position": "61.507905782837355%", "--time": "3.1096327064309572s", "--delay": "-2.1459712727145948s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.191838015185356rem", "--distance": "4.641130360103336rem", "--position": "29.405436395932412%", "--time": "3.6979364627682267s", "--delay": "-2.33003702994407s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.6738738249427367rem", "--distance": "4.397758050465163rem", "--position": "56.62843424136389%", "--time": "2.05339438757333s", "--delay": "-3.097069224410533s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.9689697800818013rem", "--distance": "3.923317056558894rem", "--position": "89.61472261891207%", "--time": "3.568321445357821s", "--delay": "-3.062280764427173s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.7308690910983353rem", "--distance": "4.034091295321979rem", "--osition": "85.18516928297055%", "--time": "2.1987466740265735s", "--delay": "-2.673276913204559s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.0606516717041234rem", "--distance": "4.731995109400946rem", "--position": "21.681294253664735%", "--time": "3.0547960956189457s", "--delay": "-2.936950183380303s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.2127705015434254rem", "--distance": "4.565405704313665rem", "--position": "22.949210979230962%", "--time": "2.5194559438580977s", "--delay": "-3.2130811015389042s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.287275482426196rem", "--distance": "4.345355474535856rem", "--position": "81.3272172051778%", "--time": "3.8992614799259213s", "--delay": "-2.429611188687952s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.375349805714187rem", "--distance": "3.467951418782178rem", "--position": "75.2346607242206%", "--time": "2.968618595924795s", "--delay": "-3.102602431631936s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.5124182856311945rem", "--distance": "4.410912635278137rem", "--position": "50.3121190694711%", "--time": "2.1046043881190415s", "--delay": "-3.864488748320254s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.303271146847994rem", "--distance": "4.916894344879198rem", "--position": "52.92868977349407%", "--time": "2.7618828241990587s", "--delay": "-2.062524343564053s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.040491985758599rem", "--distance": "4.57807806349563rem", "--position": "35.336179518242986%", "--time": "2.372794158084391s", "--delay": "-3.252204372688638s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.527800361993938rem", "--distance": "4.893002412737905rem", "--position": "73.30718171595429%", "--time": "2.3807104552362035s", "--delay": "-2.0305809947227043s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.326922902551383rem", "--distance": "4.763167328216768rem", "--position": "26.120625856823157%", "--time": "3.2000747953161173s", "--delay": "-3.8336604508695173s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.0663068584138395rem", "--distance": "4.253582015456707rem", "--position": "45.68822347440018%", "--time": "2.047669798719274s", "--delay": "-2.0217884966775723s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.433783103970837rem", "----distance": "4.731650665182993rem", "--osition": "80.5619401479021%", "--time": "3.197785647114986s", "--delay": "-2.278000217379241s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.535130920561998rem", "--distance": "4.563238560840214rem", "--osition": "80.38543878805035%", "--time": "2.6009374093809017s", "--delay": "-3.9620898462356235s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.7736097597670666rem", "----distance": "4.092122240919534rem", "--position": "4.19409372828035%", "--time": "3.944537091483916s", "--delay": "-3.5522558966887234s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.196700918304565rem", "--distance": "4.866800044518289rem", "--position": "85.4866854205699%", "--time": "3.80356164347633s", "--delay": "-2.3938021269163925s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.019927157285969rem", "--distance": "4.440070353055896rem", "--position": "54.21936789912436%", "--time": "3.621408966959689s", "--delay": "-3.2521322688634338s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.0023955442663635rem", "----distance": "4.902975504792589rem", "--position": "34.68295652583254%", "--time": "3.432446464404519s", "--delay": "-2.95320078542353s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.95427132094331rem", "--distance": "4.5401468949965595rem", "--position": "7.799302251353605%", "--time": "3.743357377555648s", "--delay": "-2.6125475015928497s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.75317430613015rem", "----distance": "4.826386598380069rem", "--position": "52.51983092459515%", "--time": "2.124234914387385s", "--delay": "-3.6494572994791192s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.835033555584724rem", "--distance": "4.514672983333199rem", "--position": "34.478549285056424%", "--time": "3.1128459101333497s", "--delay": "-3.3211003781172583s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.462592723003933rem", "--distance": "4.113323175337479rem", "--position": "59.42444587490829%", "--time": "3.917908559690792s", "--delay": "-3.083917805114765s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.332017116355855rem", "--distance": "4.20109562809317rem", "--position": "89.89629298569815%", "--time": "3.9657333359313878s", "--delay": "-3.1702474150892903s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.42300701718518rem", "--distance": "4.08759700084305rem", "--position": "89.33381212959698%", "--time": "2.651336689969463s", "--delay": "-3.5628301121720436s" }}></div>
          <div className={style.bubble} style={{ "--size": "0.0561960731821873rem", "----distance": "4.62103107772183rem", "--position": "88.32461520906821%", "--time": "2.745963142880734s", "--delay": "-3.420878814942758s" }}></div>
          <div className={style.bubble} style={{ "--size": "1.694873032244396rem", "--distance": "3.701184590459242rem", "--position": "78.91091813317597%", "--time": "2.3396013625830787s", "--delay": "-3.5463553962908274s" }}></div>
        </div>
        <div className={style.content}></div>
      </div>
      <svg style={{ position: "fixed", top: "100vh" }}>
        <defs>
          <filter id="blob">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="blob"></feColorMatrix>
            <feComposite in="SourceGraphic" in2="blob" operator="atop"></feComposite>
          </filter>
        </defs>
      </svg>
    </>)
  }
}
export default Flame;