import React from "react";
import style from "../../css/PcScpage.module.css";

// banner two page circleChange
class CanvasPcTwo extends React.Component {
  render() {
    return (
      <canvas id = "canvanPcTwo" height={this.props.pcScreenWidth} width={this.props.pcScreenWidth} className={style.canvanPcTwo}></canvas>
    );
  }
  constructor(props) {
    super(props);
    this.canvasPcCanvasTwo = "";
    this.ctxPcCanvasTwo = "";
    this.x = 0.5;
    this.y = 1;
    this.canStu = 0;
    this.canYStu = 0;
    this.canvasTwotimer = "";
  }

  componentDidMount() {
    this.canvasPcCanvasTwo = document.getElementById("canvanPcTwo");
    this.ctxPcCanvasTwo = this.canvasPcCanvasTwo.getContext("2d");
    this.action();
  }

  componentWillUnmount(){
    window.cancelAnimationFrame(this.canvasTwotimer);
  }

  action = () => {
    this.canvasTwotimer = window.requestAnimationFrame(this.action);
    this.canvasPcCanvasTwo.width = this.props.pcScreenWidth;
    this.canvasPcCanvasTwo.height = this.props.pcScreenHeight;
    if (this.canStu === 0) {
      this.x += 0.2;
    } else if (this.canStu === 1) {
      this.x -= 0.2;
    }
    if (this.x >= this.canvasPcCanvasTwo.width) {
      this.canStu = 1;
    } else if (this.x <= 0) {
      this.canStu = 0;
    }
    if (this.canYStu === 0) {
      this.y += 0.2;
    } else if (this.canYStu === 1) {
      this.y -= 0.2;
    }
    if (this.y >= this.canvasPcCanvasTwo.height) {
      this.canYStu = 1;
    } else if (this.y <= 0) {
      this.canYStu = 0;
    }
    this.ctxPcCanvasTwo.clearRect(0,0,this.canvasPcCanvasTwo.width,this.canvasPcCanvasTwo.height);
    this.ctxPcCanvasTwo.fillStyle="rgba(223,79,72)";
    this.ctxPcCanvasTwo.arc(0 + this.x,0 + this.y,this.canvasPcCanvasTwo.height * 0.13,0,Math.PI * 2);
    this.ctxPcCanvasTwo.fill();
  }
}

export default CanvasPcTwo;
