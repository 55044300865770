import React from "react";
import style from "./css/Virtual.module.css";
import QueueAnim from "rc-queue-anim";
import top from "./img/img_external.jpg";
import bottom from "./img/img_interior.jpg";
import jia from "./img/ic_jia.jpg";
import btn from "./img/ic_btn.jpg";

class Virtual extends React.Component {
  render() {
    return (
      <QueueAnim type="left">
        <div key="a" className={style.content1} style={{ height: window.innerHeight }} >
          <div className={style.virtual}>
            <div className={style.img_top} style={{ height: this.state.dragwidth }} >
              <img src={top} style={{ height: window.innerHeight }} onTouchStart={this.onstop} onTouchEnd={this.onstart} alt="top"/>
            </div>
            <div className={style.img_bottom} style={{ height: window.innerHeight - this.state.dragwidth }} >
              <img src={bottom} style={{ height: window.innerHeight }} onTouchStart={this.onstop}
                onTouchEnd={this.onstart} alt="bottom"/>
            </div>
          </div>
          <div className={style.add}>
            <div className={style.add_font}>
              <span className={style.add_zh}>超越虚拟现实</span>
              <div className={style.font_virtual}>
                <span className={style.add_en}>Virtual Reality</span>
                <img className={style.add_img} src={jia} alt="jia"/>
              </div>
            </div>
          </div>
          <div className={style.bar} style={{ top: this.state.dragwidth }}>
            <img src={btn} className="barimg" style={{ touchAction: 'none' }} onTouchStart={this.onDragStart} onTouchEnd={this.onDragEnd} onTouchMove={this.ondrag} draggable="true" alt="barimg"/>
          </div>
        </div>
      </QueueAnim>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      dragwidth: window.innerHeight / 2,
      start: "",
      stop: ""
    };
  }

  onstart=()=> {
    this.props.GETstart("start");
  };

  onstop=()=> {
    this.props.GETstop("stop");
  };

  onDragStart=(e)=> {
    this.setState({ start: e.touches[0].clientY });
    this.onstop()
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  };

  onDragEnd=()=> {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    this.onstart()
  };

  ondrag=(e)=> {
    var sctop1 = document.body.scrollTop || document.documentElement.scrollTop;
    var sctop2 = document.getElementsByClassName(style.content1)[0].offsetTop;

    this.setState({ stop: e.touches[0].clientY });
    var heights = this.state.start - (this.state.start - this.state.stop) + sctop1 - sctop2;
    if (heights > 0 && heights < window.innerHeight) {
      this.setState({ dragwidth: this.state.start - (this.state.start - this.state.stop) + sctop1 - sctop2 });
    }
  };
}

export default Virtual;
