import React from "react";
import Swiper from "swiper";
import CanvasPhTwo from "./CanvasPhTwo";
import Lanren from "../../utils/Lanren";
import PhCanvas from "../../utils/Canvas";
import { Carousel } from "antd";
import CanvasOne from "./CanvasOne";
import BannerContent from "./BannerContent";
import style from "../../css/CellPhoneScpage.module.css";
import ic_white_under from "../../images/ic_white_under.jpg";
import img_model from "../../images/img_model.jpg";
import female_annimation4 from "../../images/img_female_annimation4.gif";
import female_annimation5 from "../../images/img_female_annimation5.gif";
import female_annimation6 from "../../images/img_female_annimation6.gif";
import man_annimation4 from "../../images/img_man_annimation4.gif";
import man_annimation5 from "../../images/img_man_annimation5.gif";
import man_annimation6 from "../../images/img_man_annimation6.gif";
import ic_red_l from "../../images/ic_red_l.jpg";
import ic_white_r from "../../images/ic_white_r.jpg";
import error from "../../images/ic_load.gif"

class Hand extends React.PureComponent {
  constructor(proos) {
    super(proos);
    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
    this.numLeft = 150;
    this.female = [0,1,2,3,4,5]
  }

  componentDidMount() {
    const parent = this;
    var SwiperOne = new Swiper(".swiper-containerOne");
    new Swiper(".swiper-containerThree", {
      navigation: {
        nextEl: ".swiper-button-nextTwo",
        prevEl: ".swiper-button-prevTwo",
      },
      on: {
        slideChangeTransitionStart: function () {
          parent.phCanvas.setDefault(this.activeIndex);
        },
        slideChangeTransitionEnd: function () {
          parent.lanren.setDefault(this.activeIndex);
        },
      },
      controller: { control: SwiperOne },
    });
    let img = new Image();
    img.src = man_annimation4;
    img.setAttribute("style",`width:${this.state.screenWidth}px`)
    img.onload = () => {
      document.getElementById("error1").remove();
      document.getElementById("img_1").appendChild(img);
      let img1 = new Image();
      img1.src = man_annimation5;
      img1.setAttribute("style",`width:${this.state.screenWidth}px`)
      img1.onload = () => {
        document.getElementById("error2").remove();
        document.getElementById("img_2").appendChild(img1)
        let img2 = new Image();
        img2.src = man_annimation6;
        img2.setAttribute("style",`width:${this.state.screenWidth}px`)
        img2.onload = () => {
          document.getElementById("error3").remove();
          document.getElementById("img_3").appendChild(img2)
          let img3 = new Image();
          img3.src = female_annimation4;
          img3.setAttribute("style",`width:${this.state.screenWidth}px`)
          img3.onload = () => {
            document.getElementById("error4").remove();
            document.getElementById("img_4").appendChild(img3)
            let img4 = new Image();
            img4.src = female_annimation5;
            img4.setAttribute("style",`width:${this.state.screenWidth}px`)
            img4.onload = () => {
              document.getElementById("error5").remove();
              document.getElementById("img_5").appendChild(img4)
              let img5 = new Image();
              img5.src = female_annimation6;
              img5.setAttribute("style",`width:${this.state.screenWidth}px`)
              img5.onload = () => {
                document.getElementById("error6").remove();
                document.getElementById("img_6").appendChild(img5)
              }
            }
          }
        }
      }
    }
  }

  PhCanvasRef = (ref) => {
    this.phCanvas = ref;
  }
  lanrenRef = (ref) => {
    this.lanren = ref;
  } 

  scrollIntoView = (status) => {
    const anchorElement = document.getElementById(status);
    if (anchorElement) { anchorElement.scrollIntoView({ block: "start", behavior: "smooth" }); }
  };

  render() {
  
    return (
      <>
        <div className={style.phBanner} style={{ height: this.state.screenHeight }}>
          <BannerContent />
          <div className={style.phBannerCanvasOne}>
            <div className="swiper-containerOne">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className={style.phCanvasTwo}>
                    <CanvasPhTwo />
                    <div className={style.phCanvasTwoOne} style={{ width: this.state.screenWidth }}>
                      <Carousel className={style.CarouselGif} id="CarouselGif" autoplay="false" effect="fade" dots="false">
                        {this.female.map((e,i)=>{
                          return <div key={i} id={`img_${i + 1}`}>
                            <img id={`error${i + 1}`} src={error} className={style.error} alt="" />
                          </div>
                        })}
                      </Carousel>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className={style.phCanvasOne}><CanvasOne /></div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${style.phBannerCanvasTwo} swiper-containerThree`}>
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className={`${style.phBannerLeftButton} swiper-button-nextTwo`} style = {{left:this.state.screenWidth*0.95}}>
                  <img src={ic_red_l} alt="left" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className={`${style.phBannerRightButton}  swiper-button-prevTwo`}>
                  <img src={ic_white_r} alt="right" />
                </div>
                <div>
                  <img className={style.imgModel} src={img_model} height={this.state.screenHeight * 0.55} alt="model"/>
                </div>
                <div className={style.phBannerLanren} style={{ width: this.state.screenWidth * 0.5, height: this.state.screenHeight * 0.63}}>
                  <Lanren status = {true} lanrenStu={this.state.lanrenStu} onRef={this.lanrenRef} />
                </div>
              </div>
            </div>
          </div>
          <div className={style.phBannerBottomButton} onClick={() => this.scrollIntoView("splitOne")}>
            <img className={style.phBannerBottomButtonImg} src={ic_white_under} alt="under"/>
          </div>
        </div>
        <PhCanvas status = {true} onRef={this.PhCanvasRef} />
        <div id="splitOne"></div>
      </>
    );
  }
}

export default Hand;
