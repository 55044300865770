import React from "react";
import style from "../pc/css/Public.module.css";
import QueueAnim from "rc-queue-anim";
import TextyAnim from "rc-texty";
import *as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import OrbitControls from "three-orbitcontrols";

class Module extends React.Component {
  render() {
    var parent = this;
    return (
      <div className={style.box}>
        <QueueAnim className={style.box_ant} type="left">
          <div className={style.box_left}>
            <div className={style.triangletop}></div>
            <div className={style.trianglecentre} style={{ height: window.innerHeight, minHeight: "723px" }}>
              <div className={style.box_left_content}>
                <span className={style.science_zh}>
                  <TextyAnim style={{ display: "flex" }} mode="smooth" type="right" split={function () { var str = parent.props.zhVal; return str.split("@"); }}>
                    {parent.props.zhVal}
                  </TextyAnim>
                </span>
                <span className={style.science_en}>
                  <TextyAnim style={{ display: "flex" }} mode="smooth" type="right" split={function () { var str = parent.props.enVal; return str.split("@"); }}>
                    {parent.props.enVal}
                  </TextyAnim>
                </span>
              </div>
            </div>
            <div className={style.trianglebottom}></div>
          </div>
        </QueueAnim>
        <div className={style.box_right}>
          <div className={style.model}></div>
          <div className={style.box_pro}>
            <div className={style.progress}></div>
          </div>
        </div>
        <div className={style.box_right_font}>We Digitize</div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      length: 0
    };
    this.renderer=""
  }

  componentDidMount() {
    var i = this.state.length;
    var parent = this;
    this.inttime = setInterval(() => {
      i = i + 0.04;
      parent.setState({ length: i });
      if (i > 20) {
        clearInterval(this.inttime);
      }
    }, 10);
    this.init()
  }

  init = () => {
    var parent = this;
    var mixers = [];
    var clock = new THREE.Clock();
    var scene = new THREE.Scene();
    this.scene = scene
    var camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.camera = camera
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.Type = THREE.PCFSoftShadowMap;
    this.renderer.setSize(window.innerWidth / 1.5, window.innerHeight / 1.5)
    if (document.getElementsByClassName(style.model).length > 0) {
      document.getElementsByClassName(style.model)[0].appendChild(this.renderer.domElement);
    }

    camera.position.x = parent.props.camera;
    camera.position.y = 0.55;
    camera.position.z = 0;

    var ambient = new THREE.AmbientLight(0xffffff, 0.8);
    scene.add(ambient);

    var spotLight = new THREE.SpotLight(0xffffff, 0.5, 0, 0.3);
    spotLight.position.set(10, 10, 10);
    spotLight.castShadow = true;

    spotLight.shadow.mapSize.width = 2048;
    spotLight.shadow.mapSize.height = 2048;
    scene.add(spotLight);

    var planeGeometry = new THREE.PlaneGeometry(5, 5);
    var planeMaterial = new THREE.MeshLambertMaterial({
      side: THREE.BackSide,
      color: 0xffffff
    });
    var plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.rotateX(Math.PI / 2);
    plane.receiveShadow = true;
    plane.position.y = parent.props.y
    scene.add(plane)
    this.mixers = mixers;
    this.loads(scene, mixers)

    var animate = function () {
      var delta = clock.getDelta() / 4;
      for (var i = 0; i < mixers.length; i++) {
        mixers[i].update(delta);
      }
      parent.animate = requestAnimationFrame(animate);
      parent.renderer.render(scene, camera);
    };

    animate();
    new OrbitControls(camera, this.renderer.domElement);
  }
  loads = (scene, mixers) => {
    var parent = this;
    var name = scene.getObjectByName("Scene");
    this.scene.remove(name);
    var loader = new GLTFLoader();
    loader.load(this.props.module, function (gltf) {
      gltf.scene.traverse(function (child) {
        if (child.isMesh) {
          child.receiveShadow = true;
          child.castShadow = true;
          var material = new THREE.MeshLambertMaterial({ color: 0xC0C0C0 });
          child.material = material;
        }
      });
      scene.add(gltf.scene);
      gltf.scenes[0].position.y = parent.props.y;
      gltf.scenes[0].position.x = parent.props.x;
      var mixer = new THREE.AnimationMixer(gltf.scenes[0]);
      for (var i = 0; i < gltf.animations.length; i++) {
        mixer.clipAction(gltf.animations[i]).setDuration(1).play();
      }
      mixers.push(mixer);
    })
  }

  componentWillUnmount() {
    clearInterval(this.inttime);
    cancelAnimationFrame(this.animate)
    this.renderer.forceContextLoss();
  }
}

export default Module;
