import React from "react";
import WorkPhone from "../../utils/PhoneWork";
import Swiper from "swiper";
import WorkCanvas from "./WorkCanvas";
import Choose from "./PhChoose";
import style from "../../css/Work.module.css";
import ic_red_under from "../../images/ic_red_under.jpg";
import img_phone from "../../images/img_phone.jpg";
import ic_white_l from "../../images/ic_white_l.jpg";
import ic_white_r from "../../images/ic_white_r.jpg";
import ModalCooperate from "./ModalCooperate";

class PhWork extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight
    };
    this.canvasOneTwo = "";
    this.ctxThree = "";
  }

  showModal = () => {
    var parent = this;
    // var userAgentInfo = navigator.userAgent;
    // if (userAgentInfo.includes("Android")) {
    //   parent.modalCooperate.setDefault(true,true);
    // } else {
      parent.modalCooperate.setDefault(false,true);
    // }
  }

  componentDidMount() {
    const parent = this;
    new Swiper(".swiper-phContainerPhone", {
      loop: true,
      effect: "coverflow",
      initialSlide: 0,
      autoplay: {delay: 6000,disableOnInteraction: false},
      on: {
        click(e) {
          if (e.target.getAttribute("data-id") === "true") {parent.showModal(); }
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  modalCooperateRef = (ref) => {
    this.modalCooperate = ref;
  }

  scrollIntoView = (status) => {
    const anchorElement = document.getElementById(status);
    if (anchorElement) {anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });}
  };

  render() {
    return (
      <>
        <div className={style.phWork} style={{ height: this.state.screenHeight }}>
          <div className={style.phWorkTop}>
            <div className={style.phWorkOne}><span>我可以</span></div>
            <div className={style.phWorkTwo}><span>I Do</span></div>
            <div className={style.line}>
              <div className={style.lineOne}></div>
              <div className={style.lineTwo}></div>
              <div className={style.lineThree}></div>
            </div>
            <div className={style.phWorkThree} 
                style={{ marginTop: this.state.screenWidth * 0.106,
                width: this.state.screenWidth * 0.62,
                marginLeft: this.state.screenWidth * 0.164,
                height: this.state.screenHeight * 0.615,}}>
              <div>
                <img src={img_phone} alt="phone" onError={(e)=>{e.target.onerror = null;e.target.src = img_phone}}
                  style={{ height: this.state.screenHeight * 0.653, width: this.state.screenWidth * 0.69 }}/>
              </div>
              <div className={style.WorkPhoneContentOne}>
                <div className={`${style.workSwiperContaniner} swiper-phContainerPhone`} id = "contaniner">
                  <div className="swiper-wrapper">
                    <WorkPhone status = {true} />
                  </div>
                  <div className="swiper-button-prev">
                    <img src={ic_white_l} className={style.phPhoneLeft} alt="left" />
                  </div>
                  <div className="swiper-button-next">
                    <img src={ic_white_r} className={style.phPhoneRight} alt="right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.workCanvas}><WorkCanvas /></div>
          <div className={style.phBannerBottomButton} onClick={() => this.scrollIntoView("splitTwo")}>
            <img className={style.phBannerBottomButtonImg} src={ic_red_under} alt="under"/>
          </div>
        </div>
        <div id="splitTwo"></div>
        <Choose />
        <ModalCooperate onRef={this.modalCooperateRef} />

      </>
    );
  }
}

export default PhWork;
