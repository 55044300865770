import React from "react";
import WorkPhone from "./PcPhone";
import Background from "./Background";
import ContentBottom from "./ContentBottom";
import style from "../../css/PcScpage.module.css";
import img_phone from "../../images/img_phone.jpg";

class PcWork extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      screenHeight: ((window.screen.height - (window.screen.height-window.innerHeight)) * window.innerWidth) / window.screen.width,
      screenWidth: window.innerWidth,
    }
  }

  scrollIntoView = (status) => {
    const anchorElement = document.getElementById(status);
    if (anchorElement) { anchorElement.scrollIntoView({ block: "start", behavior: "smooth" })}
  }

  componentDidMount(){
    this.props.onRef && this.props.onRef(this);
  }

  resize = () =>{
    const parent = this;
    this.setState({
      screenHeight: ((window.screen.height - 111) * window.innerWidth) / window.screen.width,
      screenWidth: window.innerWidth,
      contentImgTwo: 0,
    },()=>{
      this.bottom.setDefault(this.state.contentImgTwo,this.state.screenHeight);
    })

    if (parent.state.screenHeight > 487) {
      parent.setState({
        lineTop: parent.state.screenHeight * 0.75,
        contentImgTwo: ((-window.innerWidth * (window.screen.height - 111)) / window.screen.width) * 0.1,
      },()=>{
        this.bottom.setDefault(this.state.contentImgTwo,this.state.screenHeight);
      });
    } else {
      parent.setState({
        lineTop: 400,
        contentImgTwo: -14.61,
      },()=>{
        this.bottom.setDefault(this.state.contentImgTwo,this.state.screenHeight);
      });
    }
  }

  ContentBottomRef = (ref) => {
    this.bottom = ref;
  }

  BackgroundRef = (ref) => {
    this.background = ref;
  }

  WorkPhoneRef = (ref) => {
    this.workPhone = ref;
  }

  UNSAFE_componentWillMount() {
    const parent = this;
    if (parent.state.screenHeight > 487) {
      parent.setState({ contentImgTwo: ((-window.innerWidth * (window.screen.height - 111)) / window.screen.width) * 0.1});
    } else {
      parent.setState({ contentImgTwo: 0 });
    }
  }

  render() {
    let screenWidth = this.state.screenWidth;
    return (
      <div className={style.pcScoageWork} style={{ height: this.state.screenHeight, minHeight: "487px" }}>
          <div className={style.pcWorkTop}>
            <span className={style.workOne} style={{ fontSize: screenWidth * 0.014, height:"100%" }}>我可以</span>
            <br />
            <span className={style.workTwo} style={{ fontSize: screenWidth * 0.010 }}>I Do</span>
            <div className={style.as}>
              <div className={style.lineOne} style={{ width: screenWidth * 0.018 }}></div>
              <div className={style.lineTwo} style={{ width: screenWidth * 0.007 }}></div>
              <div className={style.lineThree} style={{ width: screenWidth * 0.005 }}></div>
            </div>
            <div className={style.workcellPhone} style = {{minWidth:'139px', height:"100%"}}>
              <div style={{minHeight: this.state.screenHeight * 0.68}}>
                <img src={img_phone} alt="phone" width={screenWidth * 0.169} style={{ minWidth: "141px"}}/>
              </div>
              <div className={style.pcPhoneContent}>
                <WorkPhone onRef={this.WorkPhoneRef} pcScreenHeight = {this.props.pcScreenHeight} pcScreenWidth = {this.props.pcScreenWidth}/>
              </div>
            </div>
          </div>
          <div className={style.pcPhoneCanvas} style={{ top: 0 }}>
            <div className={style.header}>
              <svg className={style.waves} viewBox="0 24 150 28" preserveAspectRatio="none"style={{ top: this.state.lineTop }}>
                <defs height="200">
                  <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v10h-352z"/>
                </defs>
                <g className={style.parallax}>
                  <use xlinkHref="#gentle-wave" x="48" y="0" stroke="rgba(228,84,78,1)" fill="rgba(255,255,255,1)"/>
                </g>
              </svg>
            </div>
          </div>
          <div className={style.pcWorkText} style={{ height: this.state.screenHeight }}>
            <Background onRef={this.BackgroundRef} />
          </div>
          <ContentBottom onRef={this.ContentBottomRef} scrollIntoView={this.scrollIntoView} screenWidth={screenWidth} />
        </div>
    );
  }
}

export default PcWork;
