import React from "react";
import style from "./css/DT.module.css";
import diamonds from "./img/ic_diamonds.jpg";
import Pint from "../widget/Tween";

class DT extends React.Component {
  render() {
    return (
      <div className={style.content2} style={{ height: window.innerHeight }}>
        <Pint id="ball1" />
        <div className={style.dt}>
          <div className={style.dt_font}>
            <span className={style.dt_zh}>数字转型DT</span>
            <span className={style.dt_en}>Digital Transformation</span>
          </div>
          <img className={style.dt_chunk} src={diamonds} alt="diamonds" />
        </div>
      </div>
    );
  }
}

export default DT;
