import React from "react";
import Pint from "../widget/Tween";
import style from "../../css/Foot.module.css";
import PcAntai from "../pc/img/ic_logo_antai.jpg";
import PcAslk from "../pc/img/ic_logo_aslk.jpg";
import PcCt from "../pc/img/ic_logo_ct.jpg";
import PcIntel from "../pc/img/ic_logo_intel.jpg";
import PcMoke from "../pc/img/ic_logo_moke.jpg";
import PcSi from "../pc/img/ic_logo_si.jpg";
import PcVarian from "../pc/img/ic_logo_VARIAN.jpg";
import PhoneAntai from "../phone/img/ic_logo_antai2.jpg";
import PhoneAslk from "../phone/img/ic_logo_aslk2.jpg";
import PhoneCt from "../phone/img/ic_logo_telecom2.jpg";
import PhoneIntel from "../phone/img/ic_logo_intel2.jpg";
import PhoneMoke from "../phone/img/ic_logo_moke2.jpg";
import PhoneSi from "../phone/img/ic_logo_sike2.jpg";
import PhoneVarian from "../phone/img/ic_logo_varianN.jpg";

class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partner1: "",
      partner2: "",
      partner3: "",
      partner4: "",
      partner5: "",
      partner6: "",
      partner7: "",
      win: '',
      win1: '',
      win2: '',
      win3: '',
    };
  }

  UNSAFE_componentWillMount() {
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.setState({ partner1: PcAntai, partner2: PcCt, partner3: PcIntel, partner4: PcSi, partner5: PcAslk, partner6: PcMoke, partner7: PcVarian });
    } else {
      this.setState({ partner1: PhoneAntai, partner2: PhoneCt, partner3: PhoneIntel, partner4: PhoneSi, partner5: PhoneAslk, partner6: PhoneMoke, partner7: PhoneVarian, win: '1.27rem' });
    }
  }
  
  render() {
    return (
      <>
        <Pint id="ball2" />
        <div className={style.partner}>合作伙伴 Partner</div>
        <div className={style.brand}>
          <div className={style.brand_time}>
            <img src={this.state.partner1} style={{ width: this.state.win }} alt="partnerImg" />
          </div>
          <div className={style.brand_time}>
            <img src={this.state.partner2} style={{ width: this.state.win }} alt="partnerImg" />
          </div>
          <div className={style.brand_time}>
            <img src={this.state.partner3} style={{ width: this.state.win }} alt="partnerImg" />
          </div>
          <div className={style.brand_time}>
            <img src={this.state.partner4} style={{ width: this.state.win }} alt="partnerImg" />
          </div>
          <div className={style.brand_time}>
            <img src={this.state.partner5} style={{ width: this.state.win }} alt="partnerImg" />
          </div>
          <div className={style.brand_time}>
            <img src={this.state.partner6} style={{ width: this.state.win }} alt="partnerImg" />
          </div>
          <div className={style.brand_time}>
            <img src={this.state.partner7} style={{ width: this.state.win }} alt="partnerImg" />
          </div>
        </div>
      </>
    );
  }
}

export default Partner;
