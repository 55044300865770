import React from "react";
import style from "../../css/PcScpage.module.css";
import img_logo_right from "../../images/ic_logo_right.jpg";
import img_logo_right_red from "../../images/ic_logo_right_red.jpg";

export default class BannerImg extends React.Component {
  constructor(props){
    super(props);
    this.state = { img_login: 0 }
  }
  render() {
    let pcScreenWidth = this.props.pcScreenWidth;
    return (
      <div className={style.bannerOneImg}>
        <img src={this.state.img_logo ? img_logo_right_red : img_logo_right} height={pcScreenWidth * 0.03} alt="logo"/>
      </div>
    );
  }
  componentDidMount(){
    this.props.onRef && this.props.onRef(this);
  }
  setDefault = (data) => {
    this.setState({ img_logo: data })
  }
}
