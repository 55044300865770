import React, {Component} from 'react';
import './css/Cws.css';
import qrcode from '../../img/img_cws_code.jpg'

// FIXME CWS Pc Home Page
class CwsAdv extends Component {
    render() {
        return (
          <div className='pc-root-container'>
            <div className="pc-invitation-text">INVITATION</div>

            <div className="pc-content-layer">
              <div className="pc-left-layer">

                <div className="pc-title-layer">
                  <div className="pc-title-num">2020</div>
                  <div className="pc-title-chinese">汽车生态云展览</div>
                  <div className="pc-title-english">2020 Auto Ecosystem C-Exhibition</div>
                </div>

                <div className="pc-slogan-layer">
                  <div className="pc-slogan-front">心随车动，拥抱车彩</div>
                  <div className="pc-slogan-behind">心随车动，拥抱车彩</div>
                </div>

                <div className="date-copyright-layer">
                  <div className="pc-start-date">2020/12/25-12/30</div>
                  <div className="pc-start-text">相聚云端</div>

                  <div className='pc-copyright-text '>
                    <a href="http://beian.miit.gov.cn/" style={{color:'#fff'}}>
                      ©沪ICP备16002679号</a>-盾钰(上海)互联网科技有限公司 COPYRIGHT© 2015-Now ALL RIGHTS RESERVED BY DUNYU CO,.LTD
                  </div>
                </div>

                <div className='pc-express-text'>
                  鸣谢单位：<br/>上海市物联网行业协会、上海市浦东新区光电子行业协会、北京汽车协会、<br/>
                  上海市科学技术委员会、上海市金桥管委会、上海市张江管委会、上海市外高桥管委会、<br/>
                  上海市浦东新区科学技术管委会
                </div>
              </div>

              <div className="pc-right-layer">
                <div className="pc-hot-title">火热招商</div>
                <ul className="pc-hot-content">
                  <li>名车整车馆 ：整车生态、平行进口、二手经销 等</li>
                  <li>车载综合馆 ：轮胎轮毂、车载电子、车友用品 等</li>
                  <li>养护服务馆 ：养护耗材、特色耗材、出行服务 等</li>
                  <li>美妆改装馆 ：美妆材料、改装搭配、汽改服务 等</li>
                  <li>智能出行馆 ：智能科技、车联物联、智能汽车 等</li>
                </ul>

                <div className="pc-qrcode-layer">
                  <img  className='pc-qrcode-img' src={qrcode} alt=""/>
                  <p  className='pc-qrcode-text'> 诚挚邀请，扫码观展<br/>Scan the QR code to explore C-Exhibition</p>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default CwsAdv;