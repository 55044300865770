import React from "react";
import svg1 from "./img/ic_flow_1.jpg";
import svg2 from "./img/ic_flow_2.jpg";
import svg3 from "./img/ic_flow_3.jpg";
import svg4 from "./img/ic_flow_4.jpg";
import svg5 from "./img/ic_flow_5.jpg";
import svg6 from "./img/ic_flow_6.jpg";
import Circle from "../widget/Circle";

var parent;
class Blockchain extends React.Component {
  render() {
    return (
      <div className="main" style={{ height: window.innerHeight, minHeight: '723px ' }}>
        <span className="blockback_zh">区块溯源</span>
        <span className="blockback_en">Block Tracking</span>
        <div className="navigation-circle">
          <div className="navigation-circle__inner">
            <img src={this.state.svg} className="conimg" alt="svg" />
            <svg className="navigation-circle-svg navigation-circle-svg--opaque" x="0px" y="0px"
              viewBox="0 0 320 320" style={{ enableBackground: "new 0 0 320 320" }} >
              <circle cx="160" cy="160" r="158" fill="none" strokeWidth="1" stroke="#5BCAE0"
                strokeLinecap="round" strokeMiterlimit="10" style={{ strokeDashoffset: 0, strokeDasharray: "none" }}>
              </circle>
            </svg>
            <svg className="navigation-circle-svg navigation-circle-svg--mask" x="0px" y="0px"
              viewBox="0 0 320 320" style={{ enableBackground: "new 0 0 320 320" }}>
              <circle id="mask-circle" cx="160" cy="160" r="158" fill="none" strokeWidth="2" stroke="#3DDEF0"
                strokeLinecap="round" strokeMiterlimit="10" style={{ strokeDasharray: "1005.3088px" }}>
              </circle>
            </svg>
            <ul className="navigation-circle__list">
              <Circle onClick={() => this.onClick(1)} calculateOffset={this.calculateOffset} onMouseLeave={this.onMouseLeave} title="一物一码" introduce="粘贴码系统生成二维码，一物一码" />
              <Circle onClick={() => this.onClick(2)} calculateOffset={this.calculateOffset} onMouseLeave={this.onMouseLeave} title="赋码" introduce="将生成的二维码进行产品贴标，数字化自动喷码，提升生产效率" />
              <Circle onClick={() => this.onClick(3)} calculateOffset={this.calculateOffset} onMouseLeave={this.onMouseLeave} title="出入库" introduce="出入库扫码，实时掌握企业生产周期，大数据管理，更便捷" />
              <Circle onClick={() => this.onClick(4)} calculateOffset={this.calculateOffset} onMouseLeave={this.onMouseLeave} title="经销商" introduce="支持多级经销商管理，人员数据管理更清晰，产品防窜，防假货" />
              <Circle onClick={() => this.onClick(5)} calculateOffset={this.calculateOffset} onMouseLeave={this.onMouseLeave} title="物流" introduce="物流信息全面追踪，原产地更清晰，杜绝造假，保护企业权益" />
              <Circle onClick={() => this.onClick(6)} calculateOffset={this.calculateOffset} onMouseLeave={this.onMouseLeave} title="防伪追溯" introduce="一物一码防伪追溯" />
            </ul>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = { svg: svg1 };
    this.pointCount = 6;
    this.circleRadius = 160;
    this.startAnimDelta = 5;
    this.circumference = Math.PI * this.circleRadius * 2;
    this.selectedItemIndex = -1;
    this.buffer = 500;
  }

  componentDidMount() {
    let delay = 1000 * (1 + this.pointCount / this.startAnimDelta - 1 / this.startAnimDelta) + this.buffer;
    this.inttime = setTimeout(() => {
      this.onClick(1);
    }, delay);
    parent = this;
  }

  onClick = (index) => {
    var SVG = [svg1, svg2, svg3, svg4, svg5, svg6];
    this.setState({ svg: SVG[index - 1] });
    this.selectedItemIndex = this.selectedItemIndex === index ? -1 : index;
    this.calculateOffset(index);
    let activeListItem = document.querySelectorAll(".navigation-circle-list-item.active");
    if (activeListItem.length > 0) activeListItem[0].classList.remove("active");
    let listItem = document.querySelectorAll(".navigation-circle-list-item:nth-of-type(" + this.selectedItemIndex + ")");
    if (listItem.length > 0) listItem[0].classList.add("active");
  }

  calculateOffset(index = 0) {
    var circlePath = document.getElementById("mask-circle");
    let offset = 0;
    if (index !== 0 && index != null)
      offset = (parent.circumference / parent.pointCount) * (parent.pointCount - index);
    if (circlePath) {
      circlePath.style.strokeDashoffset = `${offset}px`;
    }
  }

  onMouseLeave = () => {
    let index = this.selectedItemIndex !== -1 ? this.selectedItemIndex : 0;
    this.calculateOffset(index);
  };

  componentWillUnmount() {
    clearInterval(this.inttime);
  }
}

export default Blockchain;
