import React from "react";
import slice from "../../img/img_slice.jpg"
import * as THREE from "three/build/three.module";

class Wengold extends React.Component {
  render() {
    return <div id="wengold"></div>
  }

  componentDidMount() {
    var parent = this;
    var pointer = {
      element: null,
      mouse: { x: 0, y: 0 },
      delta: { x: 0, y: 0 },
      position: { x: 0, y: 0 },
      active: false,
      steps: 0,
    }
    this.renderer = "";
    this.container = "";
    var group;
    var spin;
    var Dir = [];
    // var scales = [];
    var cuboids = [];
    var mouseX = 0;
    var mouseY = 0;
    var number = 0;
    var rotat = 0;
    var isFirst = true;
    var windowHalfX = window.innerWidth / 2;
    var windowHalfY = window.innerHeight / 2;
    var mouse = new THREE.Vector2();
    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 100;
    var geometry = new THREE.PlaneGeometry(20, 20, 1);
    addCuboidRing(geometry);
    var shadowLight = new THREE.AmbientLight(0xffffff);
    scene.add(shadowLight);
    let getTails = () => Math.random() < 0.5;

    for (var num = 0; num < 60; num++) {
      // var scale = Math.random() / 2;
      var xRotateDir = getTails() ? -1 : 1;
      if (Dir.length < 60) {
        Dir.push(xRotateDir);
        // scales.push(scale);
      }
    }

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      parent.renderer.setSize(window.innerWidth, window.innerHeight);
    }
    var positionX = [];
    var positionY = [];
    var positionZ = [];

    cuboids.forEach(function (cuboid, i) {
      positionX.push(cuboid.position.x);
      positionY.push(cuboid.position.y);
      positionZ.push(cuboid.position.z);
    });

    window.onscroll = function () {
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      var Opacity = 1 - scrollTop / window.innerHeight;
      cuboids.forEach(function (cuboid, i) {
        if (1 - scrollTop / window.innerHeight > 0) {
          cuboid.scale.set(
            1 - scrollTop / window.innerHeight,
            1 - scrollTop / window.innerHeight,
            1 - scrollTop / window.innerHeight
          );
        }
        cuboid.material.opacity = Opacity;
        cuboid.position.x = positionX[i] + (scrollTop * Math.sin(Math.PI / 4 + 90 * i)) / 10;
        cuboid.position.y = positionY[i] + (scrollTop * Dir[i] * Math.cos(Math.PI / 4 + 90 * i)) / 10;
        cuboid.position.z = positionZ[i] + (scrollTop * Dir[i] * Math.sin(Math.PI / 4 + 90 * i)) / 10;
      });
    }

    function addCuboidRing(ringGeometry) {
      group = new THREE.Group();
      for (var i = 0; i < 60; i++) {
        var cuboid = createCuboid(i, ringGeometry);
        cuboid.position.x = (30 - i) * 1.5;
        cuboid.rotateY(Math.PI / 2);
        cuboid.position.y = Math.sin(1.5 * Math.PI / 2 + 7 * i * Math.PI / 120) * 20 + Math.abs((30 - i) / 1.5) - 20;
        cuboids.push(cuboid);
        group.add(cuboid);
      }
      scene.add(group);
    }

    function createCuboid(i, cubiodGeometry) {
      var texture = new THREE.TextureLoader().load(slice);
      var material = new THREE.MeshPhongMaterial({
        side: THREE.DoubleSide,
        map: texture,
        opacity: 1,
        transparent: true,
      });
      return new THREE.Mesh(cubiodGeometry, material);
    }

    var animate = function () {
      parent.animate = requestAnimationFrame(animate);
      upimg();
      parent.renderer.render(scene, camera);
    }

    function upimg() {
      rotat = Math.abs(group.children[0].rotation.z.toFixed(2));
      group.children.forEach(function (e, i) {
        e.rotation.z += number;
      });
    }

    function onDocumentMouseMove(event) {
      pointer.mouse.x = event.clientX;
      pointer.mouse.y = event.clientY;
      pointer.delta.x = pointer.mouse.x - pointer.position.x;
      pointer.delta.y = pointer.mouse.y - pointer.position.y;
      pointer.position.x += pointer.delta.x;
      pointer.position.y += pointer.delta.y;
      pointer.element.style.left = pointer.position.x + "px";
      pointer.element.style.top = pointer.position.y + "px";
      mouseX = (event.clientX - windowHalfX) * 0.02;
      mouseY = (event.clientY - windowHalfY) * 0.02;
      camera.position.x += (mouseX - camera.position.x) * 0.2;
      camera.position.y += (-mouseY - camera.position.y) * 0.02;
      camera.lookAt(scene.position);
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      var raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(group.children);
      if (intersects.length > 0) {
        b();
      }
    }

    function test() {
      number = 0.05;
      spin = parseFloat((rotat + 3.05).toFixed(2));
      var time = setInterval(function () {
        if (spin < rotat) {
          number = 0;
          isFirst = !isFirst;
          clearInterval(time);
        }
      });
    }

    var once = function (fn) {
      return function () {
        if (isFirst) {
          isFirst = !isFirst;
          fn();
        }
      }
    }

    var b = once(test);
    pointer.element = document.createElement("div");
    pointer.element.id = "pointer";
    pointer.element.innerHTML =
      `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100">` +
      `<circle cx="50" cy="50" r="48" fill="none" stroke="#4df0e0" stroke-width="5"/></svg>`;

    if (!document.getElementById("pointer")) {
      document.body.appendChild(pointer.element);
    }

    this.container = document.getElementById("wengold");
    this.container.appendChild(this.renderer.domElement);
    this.renderer.domElement.className = "wengold";
    document.body.addEventListener("mousemove", onDocumentMouseMove, false);
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.addEventListener("resize", onWindowResize);
    }

    animate();
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animate);
    this.renderer.forceContextLoss();
  }
}

export default Wengold;