import React from "react";

class WorkCanvas extends React.PureComponent {
  render() {
    return (
      <canvas id="canvasWork" height={this.state.screenHeight} width={this.state.screenWidth} style={{ height: this.state.screenHeight, width: this.state.screenWidth }}></canvas>
    )
  }

  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight
    }
    this.canvasWork = "";
    this.ctxWork = "";
    this.y = parseInt(window.innerHeight * 0.15);
    this.minY = 0;
    this.maxY = parseInt(window.innerHeight * 0.3);
    this.Ystatus = false;
  }
  
  componentDidMount() {
    this.canvasWork = document.getElementById("canvasWork");
    this.ctxWork = this.canvasWork.getContext('2d');
    this.canvasWork.width = this.state.screenWidth;
    this.canvasWork.height = this.state.screenHeight;
    this.animat();
  }

  componentWillUnmount(){
    window.cancelAnimationFrame(this.animat);
  }

  animat = () =>{
    window.requestAnimationFrame(this.animat);
    let screenWidth = this.canvasWork.width;
    let screenHeight = this.canvasWork.height;
    this.ctxWork.clearRect(0,0,screenWidth,screenHeight*0.85);
    this.minY = -screenHeight*0.1;
    this.maxY = screenHeight*0.1;
    if(this.y<this.maxY && this.bannerFourStu === false){
        this.y+=Math.random();
    }else if(this.y>this.minY && this.bannerFourStu === true){
        this.y-=Math.random();
    }
    if(this.y>=this.maxY){
        this.bannerFourStu = true;
    }else if(this.y<=this.minY){
      this.bannerFourStu = false;
    }
    this.ctxWork.strokeStyle="#cac6c6";
    this.ctxWork.moveTo(-screenWidth*0.02, screenHeight * 0.4);
    this.ctxWork.bezierCurveTo(
      screenWidth * 0.2,
      screenHeight * 0.4-this.y,
      screenWidth * 0.5,
      screenHeight * 0.6+this.y,
      screenWidth*1.02,
      screenHeight*0.5-this.y
    );
    this.ctxWork.lineWidth = 0.5;
    this.ctxWork.stroke();

    this.ctxWork.beginPath();
    this.ctxWork.moveTo(0, screenHeight * 0.35+this.y/5);
    this.ctxWork.bezierCurveTo(
        screenWidth * 0.2,
        screenHeight * 0.55-this.y,
        screenWidth * 0.5,
        screenHeight * 0.5+this.y,
        screenWidth*1.02,
        screenHeight*0.53-this.y
    );
    this.ctxWork.lineWidth = 0.5
    this.ctxWork.stroke();
  }
}

export default WorkCanvas;