import React from "react";
import { createHashHistory } from 'history';
import style from "./css/Productname.module.css";
import blue from "./img/ic_blue.jpg";

const history = createHashHistory();
class Product extends React.Component {
  render() {
    return (
      <div className={style.content_left} onClick={
        this.props.number === "04" ? this.NextQKSPage : (this.props.number === "06" ? this.NextDicoPage : null)
        }>
        <div className={style.mark}>
          <img src={blue} alt="blue" />
          <span>{this.props.number}</span>
        </div>
        <div className={style.column} onClick={this.props.click?this.props.click:null}>
          <span className={style.column_zh}>{this.props.zh}</span>
          <span className={style.column_en}>{this.props.en}</span>
        </div>
      </div>
    );
  }
  NextQKSPage = () => {
    window.open('https://www.quantkernel.com', '_blank');
  }
  NextDicoPage = () => {
    history.push("/pager/Dico");
  }
}

export default Product;
