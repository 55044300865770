import React from "react";
import PcCode from "./pc/Code";
import PcPaste from "./pc/Paste";
import PcBlockchain from "./pc/Blockchain";
import PhoneCode from "./phone/Code";
import PhonePaste from "./phone/Paste";
import PhoneBlockchain from "./phone/Blockchain";
import Foot from "./Foot";

class Business extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: "#000022" }}>
        <div>{this.state.one}</div>
        <div>{this.state.two}</div>
        <div>{this.state.three}</div>
        <div><Foot /></div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      one: "",
      two: "",
      three: ""
    }
  }

  UNSAFE_componentWillMount() {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.setState({ one: <PcCode />, two: <PcPaste />, three: <PcBlockchain /> });
    } else {
      this.setState({ one: <PhoneCode />, two: <PhonePaste />, three: <PhoneBlockchain /> });
    }
  }
}

export default Business;
