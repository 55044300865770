import React from "react";
import style from "../../css/PcScpage.module.css";

class CanvasPcFour extends React.Component {
  render() {
    return (
      <canvas className={style.BannerCanvasThree} id="BannerCanvasThree" width={this.props.pcScreenWidth} height={this.props.pcScreenHeight}></canvas>
    );
  }
  constructor(props) {
    super(props);
    this.BannerCanvas = "";
    this.BannerCtx = "";
    this.x = 0;
    this.minX = -window.innerWidth * 0.02;
    this.maxX = window.innerWidth * 0.05;
    this.timerFour = '';
    this.bannerThreeStu = false;
    this.state = {
      screenWidth:window.innerWidth,
      screenHeight:this.props.status?window.innerHeight:(window.screen.height-111)*window.innerWidth/window.screen.width
    }
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    const parent = this;
    parent.BannerCanvas = document.getElementById("BannerCanvasThree");
    parent.BannerCtx = parent.BannerCanvas.getContext("2d");
    parent.BannerCanvas.width = parent.props.pcScreenWidth;
    parent.BannerCanvas.height = parent.props.pcScreenHeight;
    parent.bannerThree();
  }

  resize = () => {
    this.setState({
      screenWidth:window.innerWidth,
      screenHeight:this.props.status?window.innerHeight:(window.screen.height-111)*window.innerWidth/window.screen.width
    })
  }

  bannerThree = () => {
    const parent = this;
    this.timerFour = window.requestAnimationFrame(this.bannerThree);
    if (this.x <= this.maxX && this.bannerThreeStu === false) {
      this.x += 0.5;
    } else if (this.x >= this.minX && this.bannerThreeStu === true) {
      this.x -= 0.5;
    }
    if (this.x >= this.maxX) {
      this.bannerThreeStu = true;
    } else if (this.x <= this.minX) {
      this.bannerThreeStu = false;
    }
    parent.BannerCtx.clearRect(0,0,parent.state.screenWidth,parent.state.screenHeight);
    parent.BannerCtx.fillStyle="#EE605A";
    parent.BannerCtx.beginPath();
    parent.BannerCtx.arc(parent.state.screenWidth * 0.026,0,parent.state.screenWidth * 0.067,0,Math.PI * 2);
    parent.BannerCtx.fill();
    parent.BannerCtx.globalCompositeOperation = "destination-over";
    parent.BannerCtx.beginPath();
    parent.BannerCtx.arc(parent.state.screenWidth * 0.97,parent.state.screenHeight,parent.state.screenWidth * 0.1,0,Math.PI * 2);
    parent.BannerCtx.fill();
    parent.BannerCtx.beginPath();
    parent.BannerCtx.lineWidth = 1;
    parent.BannerCtx.moveTo(parent.state.screenWidth * 0.88, 0);
    parent.BannerCtx.bezierCurveTo(
      parent.state.screenWidth * 0.98,
      parent.state.screenHeight * 0.05,
      parent.state.screenWidth * 0.93,
      parent.state.screenHeight * 0.2,
      parent.state.screenWidth * 1.1,
      parent.state.screenHeight * 0.18
    );
    parent.BannerCtx.lineTo(parent.state.screenWidth, 0);
    parent.BannerCtx.stroke();
    parent.BannerCtx.fill();
  
    parent.BannerCtx.beginPath();
    var colTwo = parent.BannerCtx.createLinearGradient(
      parent.state.screenWidth * 0.3125,0,parent.state.screenWidth * 0.52,parent.state.screenHeight);
    colTwo.addColorStop(0, "rgba(219,74,67)");
    colTwo.addColorStop(1, "rgba(239,95,89)");
    parent.BannerCtx.lineWidth = 2;
    parent.BannerCtx.strokeStyle=colTwo;
    parent.BannerCtx.moveTo(parent.state.screenWidth * 0.16 + this.x, 0);
    parent.BannerCtx.bezierCurveTo(
      parent.state.screenWidth * 0.344,
      parent.state.screenHeight * 0.3611,
      parent.state.screenWidth * 0.1041,
      parent.state.screenHeight * 0.516,
      parent.state.screenWidth * 0.1641 + this.x / 2,
      parent.state.screenHeight
    );
    parent.BannerCtx.lineTo(
      parent.state.screenWidth * 0.417,
      parent.state.screenHeight
    );
    parent.BannerCtx.lineTo(parent.state.screenWidth * 0.417, 0);
    parent.BannerCtx.fill();
    parent.BannerCtx.closePath();
    parent.BannerCtx.beginPath();
    parent.BannerCtx.strokeStyle=colTwo;
    parent.BannerCtx.moveTo(parent.state.screenWidth * 0.526 + this.x, 0);
    parent.BannerCtx.lineWidth = 2;
    parent.BannerCtx.bezierCurveTo(
      parent.state.screenWidth * 0.397,
      parent.state.screenHeight * 0.361,
      parent.state.screenWidth * 0.578,
      parent.state.screenHeight * 0.515,
      parent.state.screenWidth * 0.526 + this.x,
      parent.state.screenHeight
    );
    parent.BannerCtx.lineTo(
      parent.state.screenWidth * 0.416,
      parent.state.screenHeight
    );
    parent.BannerCtx.lineTo(parent.state.screenWidth * 0.416, 0);
    parent.BannerCtx.fill();
    parent.BannerCtx.closePath();
  };

  componentWillUnmount(){
    cancelAnimationFrame(this.timerFour);
  }
}



export default CanvasPcFour;
