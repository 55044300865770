import React from "react";
import PcDirving from "./pc/Dirving";
import PcHealth from "./pc/Health";
import PcIndustrial from "./pc/Industrial";
import PcConsumption from "./pc/Consumption";
import PcWe from "./pc/We";
import PcDT from "./pc/DT";
import Pint from "./widget/Tween";
import PhoneDirving from "./phone/Dirving";
import PhoneHealth from "./phone/Health";
import PhoneIndustrial from "./phone/Industrial";
import PhoneConsumption from "./phone/Consumption";
import PhoneWe from "./phone/We";
import PhoneDT from "./phone/DT";
import Foot from "./Foot";

class Home extends React.Component {
  render() {
    return (
      <div style={{ position: "relative" }} onTouchMove={this.onmove}>
        <div>{this.state.one}</div>
        <div>{this.state.two}</div>
        <div>
          <div style={{ height: window.innerHeight, minHeight: this.state.sh + "px ", position: "relative" }}>
            <Pint id="ball1" />
            {this.state.three}
          </div>
        </div>
        <div><Foot show="show" /></div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      one: "",
      two: "",
      three: "",
      inttime: null,
      status: "none",
      sh: "",
    }
    this.module = []
  }

  UNSAFE_componentWillMount() {
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.setState({
        three: <PcHealth />,
        two: <PcDT />,
        one: <PcDirving />,
        sh: 723,
        offsetheight: 723
      });
    } else {
      this.setState({
        three: <PhoneHealth />,
        two: <PhoneDT />,
        one: <PhoneDirving />,
        sh: window.innerHeight
      });
    }
  }

  componentDidMount() {
    var i = 0;
    var parent = this;
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.module = [<PcHealth />, <PcIndustrial />, <PcConsumption />, <PcWe />];
    } else {
      this.module = [<PhoneHealth />, <PhoneIndustrial />, <PhoneConsumption />, <PhoneWe />];
    }
    this.inttime = setInterval(() => {
      // return
      i++;
      parent.setState({ three: parent.module[i] });
      if (i === 3) {
        i = -1;
      }
    }, 5000);
    this.inttime2 = setInterval(() => {
      if (document.querySelectorAll(".times").length > 0) {
        document.getElementsByClassName("times")[0].style.display = "block";
        clearInterval(this.inttime2);
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.inttime);
    clearInterval(this.inttime2);
    if (document.querySelectorAll(".times").length > 0) {
      document.getElementsByClassName("times")[0].style.display = 'none';
    }
  }
}

export default Home;
