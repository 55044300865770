import React from "react";
import { Link } from "react-router-dom";
import style from "./css/Vision.module.css";
import { Carousel } from "antd";
import Ball from "../widget/Three";
import Jumps from "./Jumps";

class Vision extends React.Component {
  render() {
    return (
      <div className={style.content1} style={{ height:"100vh", minHeight: '723px ' }}>
        <Ball />
        <Jumps status="left" />
        <div className={style.banner} style={{ height: "80vh", minHeight: 723 * 0.8 + 'px ' }}>
          <div className={style.banner_right}>
            <span className={style.banner_zh}>VISION</span>
            <Carousel dots={false} ref={el => (this.slider = el)}>
              <div className={style.banner_en}>内部 Inside</div>
              <div className={style.banner_en}>探索 Explore</div>
              <div className={style.banner_en}>推演 Inference</div>
              <div className={style.banner_en}>孪生 Twins</div>
            </Carousel>
            <div>
              <Link to="/pager/Commodity" className={style.banner_product}>
                <span className={style.banner_product_zh}>产品</span>
                <span className={style.banner_product_en}>Look Inside</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  prev = () => {
    this.slider.slick.slickPrev();
  }

  componentDidMount() {
    var parent = this;
    this.inttime = setInterval(function () {
      parent.slider.next();
    }, 5000);
  }

  home = () => {
    document.getElementsByClassName("times")[0].style.display = "block";
  }

  DigitalTwins = () => {
    document.getElementsByClassName("times")[0].style.display = 'none';
  }

  componentWillUnmount() {
    this.home = () => {
      return;
    };
    clearInterval(this.inttime);
  }
}

export default Vision;
