import React from "react";

class CanvasOne extends React.Component {
  render() {
    return (
      <div>
        <canvas id="canvas" height={this.state.screenHeight} width={this.state.screenWidth} style={{ height: this.state.screenHeight, width: this.state.screenWidth }}></canvas>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight
    };
    this.canvas = "";
    this.ctx = "";
    this.linearGradient = "";
    this.y = parseInt(this.state.screenHeight * 0.15);
    this.minY = 0;
    this.maxY = parseInt(this.state.screenHeight * 0.3);
    this.Ystatus = false;
    this.timer = "";
  }

  componentDidMount() {
    this.canvas = document.getElementById("canvas");
    this.ctx = this.canvas.getContext("2d");
    this.canvas.width = this.state.screenWidth;
    this.canvas.height = this.state.screenHeight;
    this.animat();
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.timer);
  }

  animat = () => {
    let screenWidth = this.state.screenWidth;
    let screenHeight = this.state.screenHeight;
    this.timer = window.requestAnimationFrame(this.animat);
    this.linearGradient = this.ctx.createLinearGradient(0,screenWidth,0,screenHeight);
    this.linearGradient.addColorStop(0, "#d5443e");
    this.linearGradient.addColorStop(1, "#ed5e58");
    if (this.Ystatus === false && this.y < this.maxY) {
      this.y += 0.5;
    } else if (this.Ystatus === true && this.y > this.minY) {
      this.y -= 0.5;
    }
    if (this.y === this.minY) {
      this.Ystatus = false;
    } else if (this.y === this.maxY) {
      this.Ystatus = true;
    }
    this.ctx.fillStyle = this.linearGradient;
    this.ctx.strokeStyle = this.linearGradient;
    this.ctx.fillRect(0, 0, screenWidth, screenHeight);
    this.ctx.beginPath();
    this.ctx.fillStyle = "#ed5e58";
    this.ctx.moveTo(0, 0);
    this.ctx.lineTo(0, screenHeight * 0.2);
    this.ctx.quadraticCurveTo(
      screenWidth / 4,
      screenHeight * 0.05 + this.y,
      screenWidth / 2,
      screenHeight * 0.15
    );
    this.ctx.quadraticCurveTo(
      (screenWidth / 4) * 3,
      screenHeight * 0.25 - this.y,
      screenWidth,
      screenHeight * 0.2
    );
    this.ctx.lineTo(screenWidth, 0);
    this.ctx.fill();
    this.ctx.beginPath();
    this.ctx.fillStyle = "#d5443e";
    this.ctx.moveTo(0, screenHeight * 0.8 + this.y / 4);
    this.ctx.bezierCurveTo(
      screenWidth * 0.2,
      screenHeight * 0.8,
      screenWidth * 0.3,
      screenHeight * 0.9 - this.y,
      screenWidth,
      screenHeight * 0.8 + this.y / 2
    );
    this.ctx.lineTo(screenWidth, screenHeight);
    this.ctx.lineTo(0, screenHeight);
    this.ctx.lineTo(0, screenHeight * 0.8);
    this.ctx.fill();
  }
}

export default CanvasOne;
