import React from "react";
import style from "./css/Dss.module.css";

class Dss extends React.Component {
  render() {
    return (
      <div className={style.content} style={{ height: "100vh", minHeight: '723px ', position: "relative" }}>
        <span className={style.content_title_zh}>数字科学仿真DSS</span>
        <span className={style.content_title_en}>
          Digital Scientific Simulation
        </span>
        <div className={style.starmap}>
          <div id="classify-training-data"></div>
          <div id="classify-test-data"></div>
          <div id="train-vs-test" ></div>
        </div>
      </div>
    );
  }
}

export default Dss;
