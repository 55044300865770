import React from "react";
import style from "../../css/CellPhoneScpage.module.css";
import ic_android from "../../images/ic_android.jpg";
import ic_ewm from "../../images/ic_ewm.jpg";
import ic_ios from "../../images/ic_ios.jpg";

export default class DownPh extends React.PureComponent {
  render() {
    return (
      <>
        <div style={{ opacity: this.state.img_logo_stu === 0 ? 1 : 0 }}>
          <img src={ic_android} alt="android" className={style.actionImg} />
        </div>
        <div style={{ opacity: this.state.img_logo_stu === 1 ? 1 : 0 }}>
          <img src={ic_ewm} alt="ewm" className={style.actionImg} />
        </div>
        <div style={{ opacity: this.state.img_logo_stu === 2 ? 1 : 0 }}>
          <img src={ic_ios} alt="ios" className={style.actionImg} />
        </div>
      </>
    );
  }

	constructor(props){
		super(props);
		this.state = {
			img_logo_stu: 1,
		}
		this.timer = '';
	}

	componentDidMount(){
		this.timer = window.setInterval(this.actionIos, 4000);
	}

	componentWillUnmount(){
		window.clearInterval(this.timer);
	}

	actionIos = () => {
    if (this.state.img_logo_stu >= 2) {
      this.setState({ img_logo_stu: 0, });
    } else {
      this.setState({ img_logo_stu: this.state.img_logo_stu + 1 });
    }
  }
}
