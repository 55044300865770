import React from 'react';
import style from '../../css/PcWork.module.css'

class Background extends React.Component{
  constructor(props){
    super(props)
    this.state = {children:0,textOne:'柔性按量生产',textTwo:'直接接单发货',textFont:0};
    this.factoryOne = '柔性按量生产';
    this.factoryTwo = '直接接单发货';
    this.brandStoreOne = '合体限量款';
    this.brandStoreTwo = '热销时尚款';
    this.designerOne = '时尚单品定制';
    this.designerTwo = '独家专属设计';
    this.meOne = '我时尚.我自创';
    this.meTwo = 'AI智能量体定制';
    this.timer = '';
  }

  changeText = () => {
    if(this.state.children === 0){
      this.setState({children:1});
    }else if(this.state.children === 1){
      this.setState({
        children:2,
      });
    }else if(this.state.children === 2){
      this.setState({
        children:3,
      })
    }else if(this.state.children === 3){
      this.setState({
        children:0,
      })
    }
  }

	componentDidMount(){
    this.props.onRef && this.props.onRef(this);
    this.timer = window.setInterval(this.changeText,6000);
  }

  componentWillUnmount(){
    window.clearInterval(this.timer);
  }

  resize =() => {
    this.setState({ textFont:window.innerWidth*0.014 })
  }

  render(){
    return(
        <>
          <div className={style.text} style={{fontSize:this.state.textFont}}> 
            <div className={style.backgroundTextOne}  id = 'backgroundOne'>
              <div className={this.state.children === 0?style.show:style.hide}>
                {this.factoryOne.split("").map((e,i) => { return <span key={i}>{e}</span> })}</div>
              <div className={this.state.children === 1?style.show:style.hide}>
                {this.brandStoreOne.split("").map((e,i) => { return <span key={i}>{e}</span> })}</div>
              <div className={this.state.children === 2?style.show:style.hide}>
                {this.designerOne.split("").map((e,i) => { return <span key={i}>{e}</span> })}</div>
              <div className={this.state.children === 3?style.show:style.hide}>
                {this.meOne.split("").map((e,i) => {return <span key={i}>{e}</span>})}</div>
            </div>
            <div className={style.backgroundTextTwo} id = 'backgroundTwo'>
              <div className={this.state.children === 0?style.show:style.hide}>
                {this.factoryTwo.split("").map((e,i) => { return <span key={i}>{e}</span> })}</div>
              <div className={this.state.children === 1?style.show:style.hide}>
                {this.brandStoreTwo.split("").map((e,i) => {return <span key={i}>{e}</span>})}</div>
              <div className={this.state.children === 2?style.show:style.hide}>
                {this.designerTwo.split("").map((e,i) => {return <span key={i}>{e}</span>})}</div>
              <div className={this.state.children === 3?style.show:style.hide}>
                {this.meTwo.split("").map((e,i) => {return <span key = {i}>{e}</span>})}</div>
            </div>
          </div>
        </>
    )
  }
}

export default Background;