import React from "react";
import style from "./css/Productname.module.css";
import close from "./img/ic_close.jpg";
import { Link } from "react-router-dom";
import Product from "./Product";

class Productname extends React.Component {
  render() {
    return (
      <div style={{ height: "100vh", minHeight: "723px" }}>
        <div className={style.close}>
          <Link to="/pager/DigitalTwin">
            <img src={close} alt="blue" />
          </Link>
        </div>
        <div className={style.pro_con}>
          <div className={style.content}>
            <Product number="01" zh="闻宝" en="Wengold EAM" />
            <Product number="02" zh="云展览" en="Meta Exhibition" />
          </div>
          <div className={style.content}>
            <Product number="03" zh="QK引擎" en="QuantKernel" />
            <Product number="04" zh="易镜" en="QuantKernel Studio" />
          </div>
          <div className={style.content}>
            <Product number="05" zh="尚智定" en="IFSC"/>
            <Product number="06" zh="DICO" en="ShangZhiChou" />
          </div>
          <div className={style.content}>
            <Product number="07" zh="数孪BMS" en="DT-BMS" />
            <Product number="08" zh="尚智健体" en="GYM" />
          </div>
          <div className={style.content}>
            <Product number="09" zh="元博宫" en="PalaceGo" />
            <Product number="10" zh="数孪人" en="PalaceMe" />
          </div>
        </div>
      </div>
    );
  }
}

export default Productname;
