import React from "react";
import { Popover,Modal,Button} from "antd";
import site from '../../utils/IfscCommon';
import style from "../../css/CellPhoneScpage.module.css";
import ic_logo from "../../images/ic_logo.jpg";
import ic_Hand_logo from "../../images/ic_Hand_logo.jpg";
import ic_qr from "../../images/img_ewm_t.jpg";
import DownPh from "./DownPh";

class BannerContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      phUserAgent: false,
    };
  }

  componentDidMount() {
    // var userAgentInfo = navigator.userAgent;
    // if (userAgentInfo.includes("Android")) {
    //   this.setState({phUserAgent: true});
    // } else {
    //   this.setState({phUserAgent: false});
    // }
  }

  download = () => {
    window.location.href = site.download;
    this.handleCancel();
  }

  Ios() {
    return (
      <div className={style.popoverShow}>
        <div><img src={ic_qr} alt="qr" /></div>
      </div>
    );
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  showModal = () => {
    window.location.href = site.download;

  }

  render() {
    let screenWidth =this.state.screenWidth;
    return (
      <>
        <div className={style.phBannerText} style={{ height: this.state.screenHeight }}>
          <div className={style.phBannerTextFlex}>
            <div className={style.phBannerText_One}><img src={ic_logo} alt="logo"/></div>
            <div className={style.phBannerText_Two} style={{ fontSize: screenWidth * 0.138}}>
              <img src={ic_Hand_logo} width={screenWidth * 0.25} alt="hand"/>
            </div>
          </div>
          <div className={style.phBannerText_Three} style={{ fontSize: screenWidth * 0.04,lineHeight: screenWidth * 0.056 + "px"}}>
            <span style={{ color:"#eee"}}>Infinite Fashion Fit</span>
          </div>
          <div style={{flex:'1' }}></div>
          <div className={style.phBannerText_Five} id = "download">
              {this.state.phUserAgent?(
                <div className={style.phContentFiveinnerCircle} onClick = {this.showModal}>
                  <DownPh />
                </div>
                ):(
                <Popover content={this.Ios} trigger="click" placement="top" getPopupContainer={triggerNode => triggerNode.parentNode}>
                  <div className={style.phContentFiveinnerCircle} >
                    <DownPh />
                  </div>
              </Popover>)
              }
          </div>
        </div>
          <Modal visible={this.state.visible} onCancel={this.handleCancel} className="cooperate" footer={null} closable={false}>
            {<>
              <div className={style.modalText}>是否下载?</div>
              <div className={style.modalAndroid}>
                <Button onClick={this.handleCancel} style={{ marginRight: "5px" }}>取消</Button>
                <Button type="primary" onClick={this.download}>下载</Button>
              </div></>}
          </Modal>
      </>
    );
  }
}
export default BannerContent;
