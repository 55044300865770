import React from "react";
import style from "../css/PcWork.module.css";
import ic_android from "../images/ic_android.jpg";
import ic_ewm from "../images/ic_ewm.jpg";
import ic_ios from "../images/ic_ios.jpg";
import img_factory_one from "../images/img_factory_one.jpg";
import img_factory_two from "../images/img_factory_two.jpg";
import img_brand_one from "../images/img_brand_one.jpg";
import img_brand_two from "../images/img_brand_two.jpg";
import img_stylist_one from "../images/img_stylist_one.jpg";
import img_stylist_two from "../images/img_stylist_two.jpg";
import img_me_one from "../images/img_me_one.jpg";
import img_me_two from "../images/img_me_two.jpg";

class PhoneWork extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      img_login_stu: 0,
      visible: false,
    };
    this.screenWidth = window.innerWidth;
    this.screenHeight = this.props.status?window.innerHeight:this.props.pcScreenHeight;
    this.pcWorkPhoneTimer = "";
    this.workPhone = [
      {
        backgroundImage: "linear-gradient(#74c2f8,#1f98eb)",
        one: img_factory_one,
        two: img_factory_two,
        top: "工厂",
        center: "Factory",
        endTop: "柔性按量生产",
        end: "直接接单发货",
      },
      {
        backgroundImage: "linear-gradient(#f6b384,#fd7e22)",
        one: img_brand_one,
        two: img_brand_two,
        top: "品牌馆",
        center: "Branding",
        endTop: "合体限量款",
        end: "热销时尚款",
      },
      {
        backgroundImage: "linear-gradient(#ed9b98,#ef605a)",
        one: img_stylist_one,
        two: img_stylist_two,
        top: "设计师",
        center: "Designer",
        endTop: "时尚单品定制",
        end: "独家专属设计",
      },
      {
        backgroundImage: "linear-gradient(#b9f174,#72b422)",
        one: img_me_one,
        two: img_me_two,
        top: "我自己",
        center: "Myself",
        endTop: "我时尚.我自创",
        end: "AI智能量体定制",
      },
    ];
  }

  login_alter = () => {
    if (this.state.img_login_stu >= 2) {
      this.setState({ img_login_stu: 0 });
    } else {
      this.setState({ img_login_stu: this.state.img_login_stu + 1 });
    }
  };

  componentDidMount() {
    this.pcWorkPhoneTimer = window.setInterval(this.login_alter, 4000);
  }

  componentWillUnmount() {
    window.clearInterval(this.pcWorkPhoneTimer);
  }

  render() {
    return (
      this.workPhone.map((v,i) =>{
        return <div key = {i} className="swiper-slide" style={{ backgroundImage: this.workPhone[i].backgroundImage,}}>
          <div className={style.pcAllPhone}>
            <div>
              <div className={style.pcAllPhoneImg}>
                <img className={style.pcwpImgOne} src={this.workPhone[i].one} alt="one" />
                <img className={style.pcwpImgTwo} src={this.workPhone[i].two} alt="two" />
              </div>
            </div>
            <div className={style.pcwPContentOneTextTop}>
              <span>{this.workPhone[i].top}</span>
              <span>{this.workPhone[i].center}</span>
            </div>
            <div className={style.pcwPContentOneTextBotton}>
              <span>{this.workPhone[i].endTop}</span>
              <span>{this.workPhone[i].end}</span>
            </div>
            <button className={style.pcwPContentOneTextButton} 
              style={{ padding:".1rem"}} 
              data-id="true">
              <div data-id="true">
                <div className={style.pcPhoneDownload} data-id="true">
                  <div style={{ opacity: this.state.img_login_stu === 0 ? 1 : 0 }} data-id="true">
                    <img src={ic_android} alt="android" id="phoneImg" width={this.props.status?"16px":this.screenWidth * 0.012} data-id="true"/>
                  </div>
                  <div style={{ opacity: this.state.img_login_stu === 1 ? 1 : 0 }} data-id="true">
                    <img src={ic_ewm} alt="ewm" id="phoneImg"  width={this.props.status?"16px":this.screenWidth * 0.012} data-id="true"/>
                  </div>
                  <div style={{ opacity: this.state.img_login_stu === 2 ? 1 : 0 }} data-id="true">
                    <img src={ic_ios} alt="ios" id="phoneImg"  width={this.props.status?"16px":this.screenWidth * 0.012}  data-id="true"/>
                  </div>
                </div>
                <div className={style.PhoneContentButton} data-id="true"><span data-id="true">下载</span></div>
              </div>
            </button>
          </div>
        </div>
      })
      
    );
  }
}

export default PhoneWork;
