import React from 'react';
import style from '../css/IfscCommon.module.css';

class Canvas extends React.PureComponent{
  constructor(props){
    super(props)
    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: this.props.status?window.innerHeight:(window.screen.height-111)*window.innerWidth/window.screen.width,
      phCanvasStu: 0
    }
    this.ctxPhText = '';
    this.canvasPhText = '';
    this.camera = {};
    this.textSet = [];
    this.yi = "data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkBAMAAACCzIhnAAAAAXNSR0IB2cksfwAAAAlwSFlzAAADsQAAA7EB9YPtSQAAABJQTFRF////2NjY/Pz8FxcXAAAAkpKSeKAaWwAAADRJREFUeJzty0ERABAQAEDGCKCBMCroX0WBO08Ps/vfUgCAD9QRm3lpK6Zk+o5dCgAAPHUAhmUu69jm73sAAAAASUVORK5CYII=";
    this.qi = "data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAAXNSR0IB2cksfwAAAAlwSFlzAAADsQAAA7EB9YPtSQAAARFQTFRF////QEBAAAAAv7+/f39/+vr6REREU1NTYmJibW1tdnZ22tra8/Pzj4+P7OzsoaGh5eXlBgYGY2Nj3t7eiYmJW1tbUFBQ19fXnZ2dIiIiZWVl0NDQsbGxk5OTrKysenp6ycnJpKSkWVlZra2ttLS0wcHBNzc30dHREhIS+fn5p6ens7OzGxsbXl5ehoaGTU1NbGxsrq6ug4ODYGBgIyMjLCwsAgIC4ODgfHx8kJCQ39/fFhYWSkpKXV1dcXFxeXl5YWFhVVVVSUlJERERV1dXn5+fGRkZPT09BQUFQ0NDu7u7aWlpwsLCVFRUpqamz8/Pd3d3QkJCCQkJ5ubmICAg8fHx4+Pj1dXVxsbGubm5+Pj44QokzQAAAbNJREFUeJztmGlTwjAQhtMYURRvREVERcUDLwQ8UfC+79v//0NskhZIUyyzrcyI+3zqdmbfh0k2GaaEIAiCIAjybzEop8mSNp3gJbJWQIm59noYpayVJAp+57uFJBYyp865C+o6+DEHJV45ytXoU+Jy5jgGYfpLlLSQpDpBMrVy5GXpvF/8wtRf+/ck7aGOznAX85R0+5FE7DAPCevxIelVJXU3ntFQH1jSr0os3CSUDgwCJUMiLOopGeZlDCgZEWGjnpIxXsaBknERltAkE6okKcpJoGRKdE9rEpmasuZgJiXKWaBkTnSntRGepzoLi0DJkuzPOCVkWZesAB1kVfavaZJ1XZKFSuJWwIZTQmJORw7qICE7QpMQlq5VGPADT/J2SEGTBMemuiS/4tiiTZDIP/XbFclOYjd4ibXdtfO6ZwiK+weMUwqXD48MG5CkyGOPCTnRT4UbpyDJGW89Nx+iFw04LkGOK9F7zR9vbr0ldyBJWPQmZXH/UPCQRECSR976VK2fMyxnvNRzwLbdHGFzkcra29e39w+XLzjwTzjZ/Ce4t3G+muBAEARBEARBEAQRfANfCB3qF9imiAAAAABJRU5ErkJggg==";
    this.mai = "data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAAXNSR0IB2cksfwAAAAlwSFlzAAADsQAAA7EB9YPtSQAAAc5QTFRF////f39/YGBgc3Nz1dXVQEBAAAAAnJyc9/f3sLCwSEhIqampv7+/oqKizc3NODg4yMjIBwcHFBQUdHR039/fg4ODxMTEmpqa+fn5JCQkBAQEaGho09PTkpKSTk5OR0dHQ0NDCgoKdXV14eHhMTExoaGhkJCQFxcXj4+PKSkp4uLiEBAQT09Pvr6+IiIis7OzTU1NKysr7Ozsr6+vQUFBn5+fGhoazs7O19fXhoaGa2trExMT3d3dY2NjNzc3ZmZm2NjYy8vLOjo6CwsLVFRUzMzMDAwM6Ojo+/v7fHx8z8/Ptra27+/vGxsbra2t8fHxqqqqPj4+rKysZGRkm5ubHx8fjIyMiYmJDg4O0NDQnZ2dSkpK0dHRp6enbm5u9vb2Pz8/CAgIvb294+Pjq6urNTU1VlZWcXFxe3t7PT099fX1sbGxAgICW1tbtLS09PT0REREo6Oj0tLSeHh4DQ0NNDQ0k5OT8/PzWlpaDw8P6urqwsLCdnZ2MjIy4ODghISEKCgol5eXampqNjY2lpaWKioqFRUVS0tLu7u78vLyGRkZX19fysrKYWFhaWlpurq6Ozs7CQkJ7e3tISEh+vr6mZmZLy8vuLi4goKCycnJeMydAwAAAnZJREFUeJztl/dXE0EQgCfAHRYwiYgCigqKAcREbEgsFBODogQpNlDEYANF7FhRwS72+t+6N3t3mxAQ2F3ynr75fribudk33yXZ29sAEARBEARB/MN45kRWdo6KxDDniJG7aOElprk4A5Il0o55SJZmQGLIOyBvdrgkX0HisGzGShY6vBocPv/yghVOYiCFPFnJP8gqdUdRMetTsnoNz7zYtpQnazFZp+6A9fx2y8o3WNlGTCqwsolX8tQd2e4cClSytApDfPaqN2NcoO4AyBczuWYLBMXNh7ZaYe02HRKA7Tscy07Yhec6XrA0Fcrtd9vn+jLsHQbYg8FeZ0Bon7ID9psNjRg01bDWzSw4gBJNXxESsRo2RDGuDB6MsVMJSlr0OQ7ZvwTXHG5lhyN44ag+R5tYAT1x+1qd8oI4lXYxeY91JIs7NUoAumptSbd94ThmJ7RKIO45iW1P2XkYsza9EoAcazk/7WQ9KOnVLQE44z3rxnzp6tPWm702zk3F6OezKw1piTkPSPL/SNIp5Q3P6+uYTpQ7dK6OaSTsb2YALlz0LZCj3XZE4BI7Bi/H9CtanJ3RlcEhHly9NqzZ4XOnWux6wJ1SIzc0Km7euu30bYTWSNLMvXP3nh7F8Kjb048v+2j4vtAUFz5QNjx8VCUaPn5iX02MPU3SPFMRJLqejyc/1Z2DolY08UIURoZkFaMvUxeOV69T62/q34riu/dyklCKYnyaf2zV5f1OeVLOwfa/riHw4eP0Q3on+YBPsg4Avh363JP7ZeYxHc1syNdv8pLvpvnD8zP+90F9v7r9SpuXgVkEnN/Sc4sgCIIgCIIgCCKz/AE6Ul2C4+XMswAAAABJRU5ErkJggg==";
    this.dou = "data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAAXNSR0IB2cksfwAAAAlwSFlzAAADsQAAA7EB9YPtSQAAAVZQTFRF////QEBAf39/AAAARUVFioqKz8/Pv7+/gICAnp6ep6enLy8vcHBwVVVV29vbSEhIlJSUDAwMkpKStra2FxcXnJycVFRUc3Nz0NDQR0dHWFhYBQUFuLi4tLS0mpqaISEhTk5OYGBgWlpaeHh4iYmJFhYW1NTU9/f3Gxsb1dXVyMjIhYWFtbW1Ozs7Pj4+9vb2YmJira2t5OTkDw8PMzMzkZGRj4+P6enpZmZmampqy8vLFRUVa2trSUlJTU1N1tbWQ0ND6+vrhISEEhISxMTEnZ2dJCQkqqqqs7OzXFxc8vLyEBAQ8fHxKSkpw8PDoaGhMTExlpaWZGRkvLy8UVFR7e3tBgYG3d3dS0tLmJiYh4eHXV1do6OjaGho6urqLi4uIyMjpaWlRkZG5+fnzMzMeXl5DQ0Npqamq6ur2dnZ+/v7Kysr39/fbW1tMDAwlZWV7+/v0dHRCb58QQAAAi5JREFUeJzt19lX00AUB+CbjNiCqAjI5lqRRRCkgLuI1gUUlMWyKii4oeD2/79o7k16MslAe+5M+uL9vcydpGe+TCZbASQSiUQikfy38YL4GSMqSF0RdazhOOTyYXxKo1ukKahPNKtETp5yiZzGTksSUWdauaN7FByFyjas23MpRKmzXCQ5kNeBTacJYc8khVDTZUC6HSM9QEg+hzmHnfPOEMqFCMEfXcT6EteA8C7AUYLiMlYF0JArWPeykTCEAFzFoq9fQwYGsb7mCBmikzUMGnIdyw5bI0JGsB29oSNjWBa5Y/u+viYqbPN+DBmfCKpJuwkYE0NuYnUrW2QUq9uZIlTcYRs1IXexuJctcj9o+csezwMae6qyIUKK2D50QEw/iiaQQh7TjpK1UaTnhnoSe8NWTtdT2vfM0hgOp/G8cm/m4nf8DO2dtSGmX6SXXUOghKXFs+tlac5wbenI/CusX3ONhUXjBawjsIT1Mo94UzYSKWRlNaj7WMZabFj9eZxAYD3aysiGduwYM7JJB8JC4G2NyDvsbPGQ7fdKfaBhw5gR6pR5yL/Xd9n0qZhEdrCzy0TgI9SC0JoUuAjUgnzCt7z6nBVSKPi+94W2frVFDlv4vRj9zRoJe0nk+2rFsFiSKghMRcb+gT1y2H3yIzR+Wn0KV7m6fmE72PzbxqiG4Pz+WAnVETepI+J58T/bGSFHP+pdI+lPIicIJbWZOEeIRCKRSCQSiUQikdQrfwGapzGblKrGNQAAAABJRU5ErkJggg==";
    this.he = "data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAAXNSR0IB2cksfwAAAAlwSFlzAAADsQAAA7EB9YPtSQAAARdQTFRF////zc3Ntra28fHxV1dXAAAAVVVVv7+/e3t7YGBgx8fHmJiY0tLSrq6uf39/u7u7CwsLFhYWQ0ND6urq09PTISEhMTEx1dXVo6OjHx8fwcHB6enpZ2dnDQ0NrKysKysrhYWFiIiIZmZm39/f9PT0cnJykZGRmpqaAgICHBwclJSUuLi4NjY2nJycFBQUSkpKw8PDfX19CgoKpqamJycn+vr6lpaWMjIyMzMz29vbTU1NUFBQsLCwLCwsXV1d7e3td3d3QEBAoaGh7+/vi4uLhoaGExMTMDAwkpKS9fX1GxsbICAgg4ODra2tlZWVmZmZh4eHHh4e2traY2Njubm5SUlJ3d3ds7Ozvr6+X19fn5+fysrKgICA3onYQwAAAYNJREFUeJztlolOwkAQQMdjqUVbD7xFRUU8EEXBA1Cxnoj3ffH/32HpsSyxRNjuNoTMS9Nud9J5nXRnUwAEQRAEQdqfrm75jp5eElJkS/qIiRqW6ugnNgMSHZrj0CU6BodsR0iiIzzsFEJGIrIco2OEMj4hxzHJOAiZmpagmImSembnhDu0eTd5bMEdLS4JVcSXaQEJgJVVZ7y2LtCR3KCOVPVe23Rvt9KiHNtUsZOxZ9JZOqWK2Mh29/ZpwgP63rl8bQUUDn0q4omjWrbjIhM5YRaacepDcXaeZVJd1Aczl0zsqnTNp4jE2O4rG39h2+bmlsehl0lL3HFVct9EZpWOHrgcAI/O80+lhhLl2dGkOB0AL9bz0ZzSWALwWjCvb9wOeDc3rPwHgOLx0W2sTvz8Ikl+CXxXFU3w48OBtI7+X6+I+D1CSbtKDN0D4RKvEEo6WVLptEqY9qhYh3kSLZHc8YFIAtm7Oq0SQ/FAdCWym9HGK+QUJECCIAiCIAiCIAgSCL/yszscGciwZgAAAABJRU5ErkJggg==";
    this.shi = "data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAAXNSR0IB2cksfwAAAAlwSFlzAAADsQAAA7EB9YPtSQAAAYNQTFRF////4eHhv7+/hISESkpKDw8PsLCwd3d3srKy29vbtra2kpKSbW1tSUlJJiYmBAQEAAAAV1dXCAgImZmZ7u7u2traxsbGnp6eioqKJCQkwMDAIyMjhYWFYGBgLS0tGBgYAwMD2NjYj4+PoqKiVlZW4uLiKysrqqqqRkZGFxcXREREcnJytbW1+Pj4eXl5QEBAXV1de3t7mpqaw8PD6+vrPT09FBQUycnJNDQ0bm5uh4eHp6enf39/Jycn6Ojo3NzcERERCwsLs7Ozrq6uNTU12dnZgICAXl5e8PDwZ2dn9fX1lJSUMTEx7e3tU1NTNzc35ubm1dXVOTk5HR0dRUVFbGxsiIiIu7u7zMzM3d3d9PT06urq39/fysrKq6uroaGhlpaWjIyMgoKCICAgBgYGvb29ExMTKSkpm5ubc3NzDQ0NGxsb9/f3jo6OiYmJGhoaa2trVVVVra2tHx8f4+PjHBwc8vLyQkJC1tbWn5+faWlpFRUVPDw85OTkcXFx0NDQ0tLSJBFM8QAAAdhJREFUeJztmOdTwjAYh0Nx4AJUHKi4FTcucFC34maouPfeWxy4/nRpU6CFFs+0eB6+zwdK3l5+T9KmuV4RAgAAAAAAiEZFqZOSU2SGpGokTqSlZ2RmaXV6Bq0shSpbn5PLLxjy8jUFhZlZRr0AtQxHUTGTUBJsmkrLyvXiVBA7KrmEKq6dL2EIUE0sqanlIsxcoU5UUN/Q2NRMLEEowtISEW9pbWvv6JSRj7HiNFsX26rS2YzdluyeXjuNJbLzMX3YYu0XlpWVDAyycUPD8ZSgESZtdCyiqrCEyTOxRwFYwq84ZFnGC9mDRfopUW5aIPl7EgGKLWGKN0waJD+XUCE4SbigoET5pUU5aEdwl4qbhD+fxJTQYpu+4hKR8yD5D5LfWF2J8zDGT9IcT8nEJE6x0VPMvhzYlh34J+L2y3FMl4UGO2OXNVpJZp2Ca+Ki3co7PHNR135+wbuYa15aXjGg1bV1zwbVtLm1vbPrdc7t7RPN9OCQSz46PjmVvOUhzs5JJBdc78vAfzd99Z3kmsSB0A3b+RY33PRdbMkxmeT+IdA3/LLja3+MJSF9KzLzPkewPD2/uPxiBn/t6xuhBL2bRIq+D4pPx6dqgjQfAAAAAAAAAAAgEfkCMZWJMefwg9IAAAAASUVORK5CYII=";
    this.timer = '';
    this.timet = '';
    this.timetTwo = '';
  }

  componentDidMount(){
    this.props.onRef && this.props.onRef(this);
    const parent = this;
    parent.canvasPhText = document.getElementById("pcCanvasText");
    parent.ctxPhText	=parent.canvasPhText.getContext("2d");
    this.change();
  }

  setDefault = (data) => {
    this.setState({ phCanvasStu: data })
  }

  resize = () =>{
    const parent = this;
    if(!this.props.status){
      this.setState({
        screenWidth:window.innerWidth,
        screenHeight:this.props.status?window.innerHeight:(window.screen.height-111)*window.innerWidth/window.screen.width
      })
  }
    
    parent.canvasPhText.width = parent.state.screenWidth;
    parent.canvasPhText.height = parent.state.screenHeight;
    if(this.camera.hasOwnProperty('focus')){
      parent.camera.display.x = parent.state.screenWidth*0.3;
      parent.camera.display.y = parent.state.screenHeight*0.8;
    }
    if(!this.props.status){
      let radius = this.state.screenWidth*0.052;
      let space = this.state.screenWidth*0.05;
      this.textSet = [
        {text:"AHN", sphereRadius:radius, sphereSpace:space, unitTime:100, time:1000},
        {text:"W06", sphereRadius:radius, sphereSpace:space, unitTime:120, time:7000},
        {text:"CJP", sphereRadius:radius, sphereSpace:space, unitTime:50, time:7000},
        {text:"X48", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
        {text:"ELS", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
        {text:"W06", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
        {text:"CHP", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
        {text:"Y08", sphereRadius:radius, sphereSpace:space, unitTime:150, time:7000},
        {text:"FHS", sphereRadius:radius, sphereSpace:space, unitTime:50, time:7000},
        {text:"Y08", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
      ];
    }
  }
  change = () => {
    const parent = this
    parent.camera = {
      focus : 400,
      self : {
        x : 0,
        y : 0,
        z : 0
      },
      rotate : {
        x : 0,
        y : 0,
        z : 0
      },
      up : {
        x : 0,
        y : 1,
        z : 0
      },
      zoom : 1,
      display : {
        x : parent.props.status?parent.state.screenWidth*0.7:parent.state.screenWidth*0.3,
        y : parent.props.status?parent.state.screenHeight/0.95:parent.state.screenHeight*0.8,
        z : 0
      }
    }
    var affine = {
      world : {
        size : function(p, size) {
          return {
            x :p.x * size.x,
            y : p.y * size.y,
            z : p.z * size.z
          }
        },
        rotate: {
          x : function(p, rotate) {
            return {
              x : p.x,
              y : p.y*Math.cos(dtr(rotate.x)) - p.z*Math.sin(dtr(rotate.x)),
              z : p.y*Math.sin(dtr(rotate.x)) + p.z*Math.cos(dtr(rotate.x))
            }
          },
          y : function(p, rotate) {
            return {
              x : p.x*Math.cos(dtr(rotate.y)) + p.z*Math.sin(dtr(rotate.y)),
              y : p.y,
              z : -p.x*Math.sin(dtr(rotate.y)) + p.z*Math.cos(dtr(rotate.y))
            }
          },
          z : function(p, rotate) {
            return {
              x : p.x*Math.cos(dtr(rotate.z)) - p.y*Math.sin(dtr(rotate.z)),
              y : p.x*Math.sin(dtr(rotate.z)) + p.y*Math.cos(dtr(rotate.z)),
              z : p.z
            }
          }
        },
        position : function(p, position) {
          return {
            x : p.x + position.x,
            y : p.y + position.y,
            z : p.z + position.z
          }
        },
      },
      view : {
        point : function(p) {
          return {
            x : p.x - parent.camera.self.x,
            y : p.y - parent.camera.self.y,
            z : p.z - parent.camera.self.z
          }
        },
        x : function(p) {
          return {
            x : p.x,
            y : p.y*Math.cos(dtr(parent.camera.rotate.x)) - p.z*Math.sin(dtr(parent.camera.rotate.x)),
            z : p.y*Math.sin(dtr(parent.camera.rotate.x)) + p.z*Math.cos(dtr(parent.camera.rotate.x))
          }
        },
        y : function(p) {
          return {
            x : p.x*Math.cos(dtr(parent.camera.rotate.y)) + p.z*Math.sin(dtr(parent.camera.rotate.y)),
            y : p.y,
            z : p.x*-Math.sin(dtr(parent.camera.rotate.y)) + p.z*Math.cos(dtr(parent.camera.rotate.y))
          }
        },
        viewReset : function(p) {
          return {
            x : p.x - parent.camera.self.x,
            y : p.y - parent.camera.self.y,
            z : p.z - parent.camera.self.z
          }
        },
        righthandedReversal : function(p) {
          return {
            x : p.x,
            y : -p.y,
            z : p.z,
          }
        }
      },
      perspective : function(p) {
        return {
          x : p.x * ((parent.camera.focus-parent.camera.self.z) / ((parent.camera.focus-parent.camera.self.z) - p.z)) * parent.camera.zoom,
          y : p.y * ((parent.camera.focus-parent.camera.self.z) / ((parent.camera.focus-parent.camera.self.z) - p.z)) * parent.camera.zoom,
          z : p.z * ((parent.camera.focus-parent.camera.self.z) / ((parent.camera.focus-parent.camera.self.z) - p.z)) * parent.camera.zoom,
          p : ((parent.camera.focus-parent.camera.self.z) / ((parent.camera.focus-parent.camera.self.z) - p.z)) * parent.camera.zoom,
        }
      },
      display : function(p, display) {
        return {
          x : p.x + display.x,
          y : p.y + display.y,
          z : p.z + display.z,
          p : p.p,
        }
      },
      process : function(model, size, rotate, position,display) {
        var ret = affine.world.size(model, size);
        ret = affine.world.rotate.x(ret, rotate);
        ret = affine.world.rotate.y(ret, rotate);
        ret = affine.world.rotate.z(ret, rotate);
        ret = affine.world.position(ret, position);
        ret = affine.view.point(ret);
        ret = affine.view.x(ret);
        ret = affine.view.y(ret);
        ret = affine.view.viewReset(ret);
        ret = affine.view.righthandedReversal(ret);
        ret = affine.perspective(ret);
        ret = affine.display(ret, display);
        return ret;
      }
    }
    var vertex3d = function(param) {
      this.affineIn = {}
      this.affineOut = {}
      if(param.vertex !== undefined) {
        this.affineIn.vertex = param.vertex;
      } else {
        this.affineIn.vertex = {x:0,y:0,z:0}
      }
      if(param.size !== undefined) {
        this.affineIn.size = param.size;
      } else {
        this.affineIn.size = {x:1,y:1,z:1}
      }
      if(param.rotate !== undefined) {
        this.affineIn.rotate = param.rotate;
      } else {
        this.affineIn.rotate = {x:0,y:0,z:0}
      }
      if(param.position !== undefined) {
        this.affineIn.position = param.position;
      } else {
        this.affineIn.position = {x:0,y:0,z:0}
      }
    }

    vertex3d.prototype = {
      vertexUpdate : function() {
        this.affineOut = affine.process(
          this.affineIn.vertex,
          this.affineIn.size,
          this.affineIn.rotate,
          this.affineIn.position,
          parent.camera.display
        );
      }
    }
      var dtr = function(v) {return v * Math.PI/180;}
      // polar to rectangle.
      var polarToRectangle =  function(dX, dY, radius) {
        var x = Math.sin(dtr(dX)) * Math.cos(dtr(dY)) * radius;
        var y = Math.sin(dtr(dX)) * Math.sin(dtr(dY)) * radius;
        var z = Math.cos(dtr(dX)) * radius;
        return {x:y, y:z, z:x}
      }
      var closeValue = function(minTime, maxTime) {
        this.flag = 0;
        this.progress = 0;
        this.startTime = 0;
        this.durationTime = 0;
        this.fromValue = 0;
        this.toValue = 0;
        this.minValue = 0;
        this.maxValue = 1;
        this.minDuration = minTime;
        this.maxDuration = maxTime;
      }
      closeValue.prototype = {
        init : function() {
          this.durationTime = this.minDuration + (this.maxDuration-this.minDuration) * Math.random();
          this.startTime = Date.now();
          this.progress = Math.min(1, ((Date.now()-this.startTime)/this.durationTime));
          this.fromValue = this.toValue;
          this.toValue = this.minValue + this.maxValue * Math.random();
          this.flag = 1;
          return this.fromValue + (this.toValue - this.fromValue) * this.progress;
        },
        update : function() {
          this.progress = Math.min(1, ((Date.now()-this.startTime)/this.durationTime));
          if(this.progress === 1) this.flag = 0;
          return this.fromValue + (this.toValue - this.fromValue) * this.progress;
        },
        execution : function() {
          if(this.flag === 0){
            this.init();
          } else if (this.flag === 1)	{
            this.update();
          }
        }
      }
      var strokeColor = "rgba(255,255,255,0.1)";
      var backgroundColor = "rgba(0,0,0,0)";
      var vibrateFlag = false;
      parent.canvasPhText.width = parent.state.screenWidth;
      parent.canvasPhText.height = parent.state.screenHeight;
      parent.camera.display.x = parent.state.screenWidth*0.3;
      parent.camera.display.y = parent.state.screenHeight*0.8;
      var redo = function(buffer,self,i) {
        var theta = Math.floor(Math.random()*100);
        var phi = Math.floor(Math.random()*100);
        if(buffer[(theta*400+(phi*4))] === 0) {
          self.charsMap[i].push({
              theta:theta-50 + 360 * Math.round(Math.random()*2)-1,
              phi:phi-50 + 360 * Math.round(Math.random()*2)-1
          })
        } 
        else {
          redo(buffer,self,i);
        }
      }
      parent.ctxPhText.strokeStyle = strokeColor;
      var	sphere = function(arg) {
        this.flag = true;
        this.type = "_";
        this.particleNum = arg.particleNum;
        this.center = {x:0, y:0, z:0}
        this.targetCenter = arg.center;
        this.radius = 0;
        this.targetRadius = arg.radius;
        this.degree = [];
        this.freeDegreeSpeed = [];
        for(let j = 0; j < this.particleNum; j++) {
          this.degree[j] = {theta:0, phi:0}
          this.freeDegreeSpeed[j] = {theta:1*Math.random()-0.5, phi:1*Math.random()-0.5}
        }
        this.charsMap = {}
        for(let i in chars) {
          var buffer = document.getElementById(i).getContext("2d").getImageData(0, 0, 100, 100).data;
          this.charsMap[i] = [];
          var self = this;
          for(let j=0; j<this.particleNum; j++) {
            redo(buffer,self,i);
          }
        }
        this.charsMap["@"] = [];
        for(let i = 0; i < this.particleNum; i++) {
          this.charsMap["@"][i] = {theta:360*Math.random(), phi:360*Math.random()}
        }
        this.charsMap["_"] = [];
        for(let i = 0; i<this.particleNum; i++) {
          this.charsMap["_"][i] = {theta:0, phi:0}
        }
        this.veticies = [];
        for(let i = 0; i < this.particleNum; i++) {
          this.veticies[i] = new vertex3d({});
        }
      }
      sphere.prototype = {
        update : function() {
          for(let i = 0; i < this.charsMap[this.type].length; i++) {
            if(this.degree[i].theta >= 30 && this.degree[i].phi >= 30) {
              this.flag = true;
              break;
            } else {
              this.flag = false;
            }
          }
          this.radius =  this.radius + (this.targetRadius - this.radius) / 8;
          this.center.x = this.center.x + (this.targetCenter.x - this.center.x) / 8;
          this.center.y = this.center.y + (this.targetCenter.y - this.center.y) / 8;
          this.center.z = this.center.z + (this.targetCenter.z - this.center.z) / 8;
          for(let i = 0; i<this.charsMap[this.type].length; i++) {
            if(this.type === "@") {
              this.charsMap[this.type][i].theta += this.freeDegreeSpeed[i].theta;
              this.charsMap[this.type][i].phi += this.freeDegreeSpeed[i].phi;
            }
            this.degree[i].theta = this.degree[i].theta + (this.charsMap[this.type][i].theta-this.degree[i].theta)/(4+20*Math.random());
            this.degree[i].phi = this.degree[i].phi + (this.charsMap[this.type][i].phi-this.degree[i].phi)/(4+20*Math.random());
            if(vibrateFlag === true) {
              var getPosition = polarToRectangle(this.degree[i].theta+90, this.degree[i].phi, this.radius+Math.random()*10);
            } else {
               getPosition = polarToRectangle(this.degree[i].theta+90, this.degree[i].phi, this.radius);
            }
            this.veticies[i].affineIn.vertex = {
              x:getPosition.x,
              y:getPosition.y,
              z:getPosition.z
            }
            this.veticies[i].affineIn.position = {
              x:this.center.x,
              y:this.center.y,
              z:this.center.z
            }
            this.veticies[i].vertexUpdate();
          }
        },
        draw : function() {
          if(this.flag === true) {
            parent.ctxPhText.beginPath();
            for(let i=0; i<this.veticies.length; i++) {
              for(let j=i; j<this.veticies.length; j++) {
                var distance = 
                (this.veticies[i].affineOut.x-this.veticies[j].affineOut.x)*(this.veticies[i].affineOut.x-this.veticies[j].affineOut.x) +
                (this.veticies[i].affineOut.y-this.veticies[j].affineOut.y)*(this.veticies[i].affineOut.y-this.veticies[j].affineOut.y);
                if(distance <= this.radius) {
                  parent.ctxPhText.moveTo(
                    this.veticies[i].affineOut.x,
                    this.veticies[i].affineOut.y
                  );
                  parent.ctxPhText.lineTo(
                    this.veticies[j].affineOut.x,
                    this.veticies[j].affineOut.y
                  );
                }
              }
            }
            parent.ctxPhText.closePath();
            parent.ctxPhText.stroke();
          }
        }
      }
      var sphereNum = 20;
      var s = [];
      var setup = function() {
        for(let i=0; i<sphereNum; i++) {
          // radius px number
          if(parent.props.status){
            s[i] = new sphere({radius:100, particleNum:170, center:{x:70*i - (sphereNum-1)*70/2,y:0,z:0}});
          }else{
            s[i] = new sphere({radius:100, particleNum:200, center:{x:70*i - (sphereNum-1)*70/2,y:0,z:0}});
          }
          
        }
      }
      var update = function() {
        for(let i=0; i<sphereNum; i++) {s[i].update();}
      }

      var draw = function() {
        for(let i=0; i<sphereNum; i++) {s[i].draw();}
      }
      var chars = {
        A : this.yi,
        B : this.yi ,
        C : this.yi ,
        D : this.yi ,
        E : this.yi ,
        F : this.yi ,
        H : this.qi,
        I : this.qi,
        J : this.qi,
        K : this.qi,
        L : this.qi,
        M : this.qi,
        N : this.mai,
        O : this.mai,
        P : this.mai,
        R : this.mai,
        S : this.mai,
        T : this.mai,
        U : this.dou,
        W : this.dou,
        X : this.dou,
        Y : this.dou,
        Z: this.dou,
        "!" : this.dou,
        "0" : this.he,
        "1" : this.he,
        "2" : this.he,
        "4" : this.he,
        "5" : this.shi,
        "6" : this.shi,
        "7" : this.shi,
        "8" : this.shi,
        "9" : this.shi
      }
      var charsLength = 0;
      var charCounter = 0;
      var bufferImages = {}
      var showImg = function(i,sizeCounter,sizeLength){
        bufferImages[i].onload = () =>  {
          sizeCounter++;
          if(sizeCounter === sizeLength) {
            bufferDraw();
          }
        }
      }
      for(let i in chars) {
        charsLength++;
        bufferImages[i] = new Image();
        bufferImages[i].src=chars[i];
        showImg(i,charCounter,charsLength);
      }
      var bufferDraw = function() {
        for(let i in chars) {
          parent.canvasPhText = document.createElement("canvas");
          parent.canvasPhText.id = i;
          document.getElementById("buffer").appendChild(parent.canvasPhText);
          document.getElementById(i).getContext("2d").drawImage(bufferImages[i],0,0,100,100);
        }
        start();
      }
      var textChanger = function(text, sphereRadius, sphereSpace, unitTime) {
        var changeIncrement = 0;
        var charNum = text.length;
        var center = [];
        for(let i=0; i<charNum; i++) {center[i] = {x:sphereSpace*i - sphereSpace*(charNum-1)/2, y:0, z:0}}
        var changer = function() {
          parent.timet = setTimeout(function() {
            s[changeIncrement].type = text[changeIncrement];
            s[changeIncrement].targetCenter = center[changeIncrement]; 
            s[changeIncrement].targetRadius = sphereRadius; 
            changeIncrement++;
            if(changeIncrement < charNum) {
              changer();
            }
          }, unitTime);
        }
        for(let i=charNum; i<s.length; i++) {
          s[i].type = "_";
        }
        changer();
      }
      if(this.props.status){
        parent.textSet = [
          {text:"AHN", sphereRadius:50, sphereSpace:60, unitTime:100, time:1000},
          {text:"W06", sphereRadius:50, sphereSpace:60, unitTime:120, time:7000},
          {text:"CJP", sphereRadius:50, sphereSpace:60, unitTime:50, time:7000},
          {text:"X48", sphereRadius:50, sphereSpace:60, unitTime:100, time:7000},
          {text:"ELS", sphereRadius:50, sphereSpace:60, unitTime:100, time:7000},
          {text:"W06", sphereRadius:50, sphereSpace:60, unitTime:100, time:7000},
          {text:"CHP", sphereRadius:50, sphereSpace:60, unitTime:100, time:7000},
          {text:"Y08", sphereRadius:50, sphereSpace:60, unitTime:150, time:7000},
          {text:"FHS", sphereRadius:50, sphereSpace:60, unitTime:50, time:7000},
          {text:"Y08", sphereRadius:50, sphereSpace:60, unitTime:100, time:7000},
        ];
      }else{
        let radius = this.state.screenWidth*0.052;
        let space = this.state.screenWidth*0.05;
        parent.textSet = [
          {text:"AHN", sphereRadius:radius, sphereSpace:space, unitTime:100, time:1000},
          {text:"W06", sphereRadius:radius, sphereSpace:space, unitTime:120, time:7000},
          {text:"CJP", sphereRadius:radius, sphereSpace:space, unitTime:50, time:7000},
          {text:"X48", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
          {text:"ELS", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
          {text:"W06", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
          {text:"CHP", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
          {text:"Y08", sphereRadius:radius, sphereSpace:space, unitTime:150, time:7000},
          {text:"FHS", sphereRadius:radius, sphereSpace:space, unitTime:50, time:7000},
          {text:"Y08", sphereRadius:radius, sphereSpace:space, unitTime:100, time:7000},
        ];
      }
      var textSetChangerIncrement = 0;
      var textSetChanger = function() {
        parent.timetTwo = setTimeout(function() {			
          textChanger(
            parent.textSet[textSetChangerIncrement].text,
            parent.textSet[textSetChangerIncrement].sphereRadius,
            parent.textSet[textSetChangerIncrement].sphereSpace,
            parent.textSet[textSetChangerIncrement].unitTime
          );
          textSetChangerIncrement++;
          if(textSetChangerIncrement === parent.textSet.length) {
            textSetChangerIncrement = 0;
          }
          textSetChanger();
        }, parent.textSet[textSetChangerIncrement].time);
      }
      var vibrateCV = new closeValue(200, 500);
      var start = () => {
        setup();
        parent.timer= setInterval(() => {
          if(vibrateCV.execution() > 0.8) {
            vibrateFlag = true;
          } else {
            vibrateFlag = false;
          }
          if(parent.props.status){
            if(parent.state.phCanvasStu === 0){
              strokeColor = "rgba(243,80,80,0.1)";
              backgroundColor = "rgba(222,77,70,0)"; 
              parent.camera.display.x = parent.state.screenWidth*0.5;
              parent.camera.display.y = parent.state.screenHeight*0.75;
            }else{
              strokeColor = "rgba(255,255,255,1)";
              backgroundColor = "rgba(222,77,70,0)"; 
              parent.camera.display.x = parent.state.screenWidth*0.6;
              parent.camera.display.y = parent.state.screenHeight*0.95; 
            }
          }else{
            strokeColor = "rgba(255,255,255,1)";
            backgroundColor = "rgba(222,77,70,0)";
          }
          parent.ctxPhText.clearRect(0, 0,parent.state.screenWidth,parent.state.screenHeight);
          parent.ctxPhText.fillStyle=backgroundColor;
          parent.ctxPhText.fillRect(0, 0,parent.state.screenWidth,parent.state.screenHeight);
          parent.ctxPhText.strokeStyle=strokeColor;
          update();
          draw();
        }, 1000/60);
        textSetChanger();
      }
      if(!parent.props.status){
        document.body.onmousemove = function(e) {
          parent.camera.rotate.x = e.pageY/window.innerHeight* 180 - 90;
          parent.camera.rotate.y = e.pageX/window.innerWidth * 180 - 90;
          document.onmousedown = function() {parent.camera.zoom = Math.random()*1+0.3};
          document.onmouseup = function() {parent.camera.zoom = 1};
        };
      }
  }

  componentWillUnmount(){
    window.clearInterval(this.timer);
    window.clearTimeout(this.timet);
    window.clearTimeout(this.timetTwo);
  }

    render(){
        return(
            <>
              <canvas className={style.phCanvasImg} id='pcCanvasText' width={this.state.screenWidth} height={this.state.screenHeight}></canvas>
              <div id="buffer" style={{display:'none'}}></div>
            </>
        )
    }
}

export default Canvas;