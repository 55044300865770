import React, {Component} from 'react';
import './css/HorCws.css'
import bgimg from '../../img/img_cws_bg_h.jpg'
import qrcode from '../../img/img_cws_code.jpg'

// FIXME Cws Home Page
class HorCws extends Component {
    render() {
        return (
            <div className='mbh-root-container'>
              <img className='mbh-bgimg-ver' src={bgimg} alt="bgimg"/>
              <div className="mbh-invitation-text">INVITATION</div>

              <div className="mbh-title-layer">
                <div className='mbh-title-num'>2020</div>
                <div className='mbh-title-chinese'>汽车生态云展览</div>
                <div className='mbh-title-english'>2020 Auto Ecosystem C-Exhibition</div>
              </div>

              <div className="mbh-slogan-layer">
                <div className='mbh-slogan-front'>心随云动，拥抱车彩</div>
                <div className="mbh-slogan-behind">心随云动，拥抱车彩</div>
              </div> 

              <div className="mbh-start-date">2020/12/25-12/30</div>
              <div className="mbh-start-text">相聚云端</div>

              <div className="mbh-hot-title">火热招商</div>
              <ul className="mbh-hot-content">
                    <li>名车整车馆 ：整车生态、平行进口、二手经销 等</li>
                    <li>车载综合馆 ：轮胎轮毂、车载电子、车友用品 等</li>
                    <li>养护服务馆 ：养护耗材、特色耗材、出行服务 等</li>
                    <li>美妆改装馆 ：美妆材料、改装搭配、汽改服务 等</li>
                    <li>智能出行馆 ：智能科技、车联物联、智能汽车 等</li>
              </ul>

              <p className='mbh-express-text' style={{color:'rgba(255, 255, 255, 0.8)'}}>
                鸣谢单位：<br/>
                上海市物联网行业协会、上海市浦东新区光电子行业协会、北京汽车协会、<br/>
                上海市科学技术委员会、上海市金桥管委会、上海市张江管委会、<br/>
                上海市外高桥管委会、上海市浦东新区科学技术管委会
              </p>

              <img className='mbh-qrcode-img' src={qrcode} alt=""/>
              <div className="mbh-qrcode-text">诚挚邀请，扫码观展</div>
              <div className="mbh-qrcode-text2">Scan the QR code to explore C-Exhibition</div>

              <footer className="mbh-footer-text">
                <a href="http://beian.miit.gov.cn/" style={{color:'rgba(255,255,255,0.8)'}}>
                  ©沪ICP备16002679号 </a>- 盾钰(上海)互联网科技有限公司 COPYRIGHT© 2015-Now ALL RIGHTS RESERVED BY DUNYU CO,.LTD
              </footer>

            </div>
        );
    }
}

export default HorCws;