import React from "react";
import Module from "./Module";
import molecule from "./img/ic_molecule.jpg";
import gltf from "../../gltf/health.gltf";

class Health extends React.Component {
  render() {
    return <Module src={molecule} zhVal="我们自己" enVal="Ourselves" module={gltf} camera="3.6" x="1.2" y="-1.7"/>
  }
}

export default Health;
