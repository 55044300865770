import React from "react";
import style from "../../css/PcScpage.module.css";

// banner swiper two page
class CanvasPcOne extends React.Component {
  render() {
    return (
      <canvas className={style.canvasPcOne} id = "pcCanvas" height={this.props.pcScreenHeight} width={this.props.pcScreenWidth}></canvas>
    )
  }

  constructor(props) {
    super(props);
    this.pcCanvas = "";
    this.ctx = "";
    this.linearGradient = "";
    this.x = parseInt(this.props.pcScreenHeight * 0.01);
    this.minX = 0;
    this.maxX = parseInt(this.props.pcScreenHeight *0.15);
    this.xstatus = false;
    this.canvasOnetimer = '';
  }

  componentDidMount() {
    this.pcCanvas = document.getElementById("pcCanvas");
    this.ctx = this.pcCanvas.getContext('2d');
    this.pcCanvas.width = this.props.pcScreenWidth;
    this.pcCanvas.height = this.props.pcScreenHeight;
    this.ctx.beginPath();
    this.action()
  }

  componentWillUnmount(){
    window.cancelAnimationFrame(this.canvasOnetimer)
  }

  action = () => {
    let pcCanvasWidth = this.pcCanvas.width;
    let pcCanvasHeight = this.pcCanvas.height;
    this.canvasOnetimer = window.requestAnimationFrame(this.action);
    if (this.xstatus === false && this.x < this.maxX) {
      this.x += 0.5;
    } else if (this.xstatus === true && this.x > this.minX) {
      this.x -= 0.5;
    }
    if (this.x === this.minX) {
      this.xstatus = false;
    } else if (this.x === this.maxX) {
      this.xstatus = true;
    }
    this.ctx.fillStyle="rgba(229,84,76)";
    this.ctx.beginPath();
    this.linearGradient = this.ctx.createLinearGradient(0, 0, 0, pcCanvasHeight);
    this.linearGradient.addColorStop(0, "#d5443e");
    this.linearGradient.addColorStop(1, "#ed5e58");
    this.ctx.fillStyle=this.linearGradient;
    this.ctx.moveTo(pcCanvasWidth / 10 + this.x / 2, 0);
    this.ctx.bezierCurveTo(
      pcCanvasWidth  * 0.25 + this.x / 2, pcCanvasHeight * 0.5,
      pcCanvasWidth  * -0.05 + this.x, pcCanvasHeight * 0.6,
      pcCanvasWidth / 10, pcCanvasHeight
    );
    this.ctx.lineTo(pcCanvasWidth / 10 * 3.8 + this.x, pcCanvasHeight);
    this.ctx.bezierCurveTo(
      pcCanvasWidth  * 0.45, pcCanvasHeight * 0.6,
      pcCanvasWidth  * 0.25 + this.x, pcCanvasHeight * 0.4,
      pcCanvasWidth  * 0.45, 0
    );
    this.ctx.fill();
    this.linearGradient = this.ctx.createLinearGradient(0, 0, 0, pcCanvasHeight);
    this.linearGradient.addColorStop(0, "#ed5e58");
    this.linearGradient.addColorStop(1, "#d5443e");
    this.ctx.fillStyle=this.linearGradient;
    this.ctx.fillRect(0, 0, pcCanvasWidth, pcCanvasHeight);
    this.ctx.beginPath();
    this.linearGradient = this.ctx.createLinearGradient(0, 0, 0, pcCanvasHeight);
    this.linearGradient.addColorStop(0, "#d5443e");
    this.linearGradient.addColorStop(1, "#ed5e58");
    this.ctx.fillStyle=this.linearGradient;
    this.ctx.moveTo(pcCanvasWidth * 0.5 + this.x / 2, 0);
    this.ctx.bezierCurveTo(
      pcCanvasWidth  * 0.7 + this.x / 2, pcCanvasHeight * 0.5,
      pcCanvasWidth  * 0.4 + this.x, pcCanvasHeight * 0.6,
      pcCanvasWidth  * 0.5, pcCanvasHeight
    );
    this.ctx.lineTo(pcCanvasWidth / 10 * 8.3 + this.x, pcCanvasHeight);
    this.ctx.bezierCurveTo(
      pcCanvasWidth * 0.9, pcCanvasHeight * 0.6,
      pcCanvasWidth * 0.7 + this.x, pcCanvasHeight * 0.4,
      pcCanvasWidth * 0.9, 0
    );
    this.ctx.fill();
  }
}

export default CanvasPcOne;