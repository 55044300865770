import React from "react";
import { TweenMax, TweenLite, TimelineMax } from 'gsap';

class Tween extends React.Component {
  render() {
    return (
      <div className={this.props.id} style={{ width: "100%" }} ref={e => (this.refNode = e)} ></div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      fireflies: 25,
    }
    this.refNode = "";
  }

  componentDidMount() {
    var height = window.innerHeight;
    var width = window.innerWidth;
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      if (height < 723) {
        height = 723
      }
    }
    for (var i = 0; i < this.state.fireflies; i++) {
      var firefly = document.createElement("div")
      firefly.className = i % 2 === 0 ? "firefly_red" : "firefly_blue";
      TweenLite.set(firefly, { x: Math.random() * width, y: Math.random() * height });
      this.refNode.append(firefly);
      flyTheFirefly(firefly);
    }

    function flyTheFirefly(elm) {
      var fadeTl = new TimelineMax({
        delay: Math.floor(Math.random() * 2) + 1,
        repeatDelay: Math.floor(Math.random() * 6) + 1,
        repeat: -1
      });

      // Fluorescence effectiveness
      fadeTl.to([elm], 0.25, { opacity: 0.25, yoyo: true, repeat: 1, repeatDelay: 0.2 }, Math.floor(Math.random() * 6) + 1);
      TweenMax.set([elm], { scale: Math.random() * 0.75 + 0.5 })
      TweenMax.to([elm], Math.random() * 100 + 100, {
        x: Math.random() * width,
        y: Math.random() * height,
        onComplete: flyTheFirefly,
        onCompleteParams: [elm]
      });
    }
  }
}

export default Tween;
