import React from "react";
import Hander from "./hander/Hander";
import End from "./end/End";

class PcScpage extends React.Component {
  render() {
    return (
      <div>
        <Hander pcScreenHeight = {this.state.pcScreenHeight} pcScreenWidth = {this.state.pcScreenWidth} onRef={this.handerRef}/>
        <End pcScreenHeight = {this.state.pcScreenHeight} pcScreenWidth = {this.state.pcScreenWidth}/>
      </div>
    );
  }

  constructor(props){
    super(props);
    this.state = {
      pcScreenHeight: ((window.screen.height - (window.screen.height-window.innerHeight)) * window.innerWidth) / window.screen.width,
      pcScreenWidth: window.innerWidth,
    }
  }

  componentDidMount(){
    this.resize();
    window.addEventListener('resize',this.resize);
  }

  resize = () =>{
    this.setState({
      pcScreenHeight: ((window.screen.height - (window.screen.height-window.innerHeight)) * window.innerWidth) / window.screen.width,
      pcScreenWidth: window.innerWidth,
    })
    this.header.work.resize();
    this.header.work.background.resize();
    this.header.work.workPhone.resize();
    this.header.canvas.resize();
    this.header.lanren.resize();
    this.header.canvasPcFour.resize();
    this.header.split.resize();
    this.header.splitTwo.resize();
    this.header.us.resize();
    this.header.setGifWidth();
  }

  handerRef = (ref) => {
    this.header = ref;
  }

  componentWillUnmount(){
    window.removeEventListener('resize',this.resize);
  }
}

export default PcScpage;
