import React from "react";
import { Link } from "react-router-dom";
import style from "./css/Dirving.module.css";
import TABLOGO from "../../img/ic_tab_logo.jpg";
import TABBUT from "./img/ic_button.jpg";
import Return from "./img/ic_return.jpg";
import QueueAnim from "rc-queue-anim";
import Pint from "../widget/Tween";
import Wengold from "../widget/Wengold"

class Show extends React.Component {
  render() {
    return (
      <QueueAnim type={['left', 'left']} ease={['easeOutQuart', 'easeInOutQuart']}>
        <div key="a" className={style.shadow} style={{ height: "100vh" }}>
          <div className={style.nav}>
            <Link to="/pager/DigitalTwin">
              <div className={style.nav_child}>
                <span className={style.nav_child_title_zh}>数字孪生</span>
                <span className={style.nav_child_title_en}>Digital Twins</span>
              </div>
            </Link>
            <Link to="/pager/NewIfsc">
              <div className={style.nav_child} onClick={this.shadow}>
                <span className={style.nav_child_title_zh}>尚智定</span>
                <span className={style.nav_child_title_en}>IFSC</span>
              </div>
            </Link>
            <Link to="">
              <div className={style.nav_child} onClick={()=>window.open("https://www.quantkernel.com", "_self")}>
                <span className={style.nav_child_title_zh}>易镜</span>
                <span className={style.nav_child_title_en}>QKS</span>
              </div>
            </Link>
          </div>
        </div>
      </QueueAnim>
    );
  }

  scrControl = (scroll) => {
    if (scroll === 0) {
      document.body.addEventListener('touchmove', this.bodyScroll, { passive: false });
    } else if (scroll === 1) { 
      document.body.removeEventListener('touchmove', this.bodyScroll, { passive: false });
    }
  }

  bodyScroll = (event) => {
    event.preventDefault();
  }

  componentDidMount() {
    this.scrControl(0)
  }

  componentWillUnmount() {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    this.scrControl(1)
  }
}
class Dirving extends React.Component {
  shadow = () => {
    if (document.getElementsByTagName("body")[0].style.overflow !== "hidden") {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      this.setState({ Show: <Show />, img: Return, width: "0.51rem", height: "0.64rem" });
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
      this.setState({ Show: '', img: TABBUT, width: "0.35rem", height: "0.75rem" });
    }
  }

  openQKSpage = () => {
    // this.shadow();
    // window.open("https://www.quantkernel.com", "_self");
    document.URL="https://www.quantkernel.com";
  }

  constructor(props) {
    super(props);
    this.state = {
      statu: "none",
      img: TABBUT,
      D: "",
      width: '0.35rem',
      height: '0.75rem'
    };
  }
  render() {
    return (
      <div className={style.content1} style={{ height: "100vh", position: "relative" }}>
        <Pint id="ball1" />
        <Wengold />
        <div className={style.head}>
          <img className={style.logo} src={TABLOGO} alt="tablogo" />
          <img className={style.but} src={this.state.img} onClick={this.shadow} style={{ width: this.state.width, height: this.state.height }} alt="but" />
        </div>
        <div className={style.banner} style={{ height: 80 + "vh" }} >
          <div className={style.banner_right}>
            <span className={style.banner_zh}>数字</span>
            <span className={style.banner_zh}>驱动商业</span>
            <span className={style.banner_en}>Driving Your Business</span>
          </div>
          <div className={style.banner_left}>
            <div className={style.hint}>
              <span className={style.hint_hang} ></span>
            </div>
          </div>
        </div>
        {this.state.Show}
      </div>
    );
  }
}

export default Dirving;
