import React from "react";
import WorkPhone from "../../utils/PhoneWork";
import Swiper from "swiper";
import { Modal } from "antd";
import style from "../../css/PcWork.module.css";
import ic_white_l from "../../images/ic_white_l.jpg";
import ic_white_r from "../../images/ic_white_r.jpg";
import ewm from "../../images/img_ewm_t.jpg";
import ic_del_def_code from "../../images/ic_gb_def.jpg";

class PcPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textStu: 0,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
  }

  Change = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  resize = () => {
    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  };

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    var parent = this;
    var myPhoneSwiper = new Swiper(".swiper-containerPcPhone", {
      speed: 300,
      effect: "coverflow",
      initialSlide: 0,
      loop: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-nextPh",
        prevEl: ".swiper-button-prevPh",
      },
      on: {
        slideChangeTransitionEnd: function () {
          parent.setState({ textStu: this.activeIndex });
        },
        click: function (e) {
          if (e.target.getAttribute("data-id") === "true") {parent.Change(); }
        },
      },
    });

    myPhoneSwiper.el.onmouseover = () => {
      myPhoneSwiper.autoplay.stop();
    };

    myPhoneSwiper.el.onmouseout = () => {
      myPhoneSwiper.autoplay.start();
    };
  }

  render() {
    return (
      <>
        <div className="swiper-containerPcPhone" style={{ height: "100%", width: "100%" }}>
          <div className="swiper-wrapper">
            <WorkPhone status = {false} pcScreenHeight = {this.props.pcScreenHeight} pcScreenWidth = {this.props.pcScreenWidth} />
          </div>
          <div className="swiper-button-prevPh">
            <img src={ic_white_l} className={style.pcWorkPhonePrev} width={this.state.screenWidth * 0.012} alt="white_l" />
          </div>
          <div className="swiper-button-nextPh">
            <img className={style.pcWorkPhoneNext} src={ic_white_r} width={this.state.screenWidth * 0.012} alt="white_r" />
          </div>
        </div>
        <Modal className="cooperate" visible={this.state.visible} onCancel={this.handleCancel} footer={null} closeIcon={<img src={ic_del_def_code} alt="code" />}>
          <div className={style.pcModal}>
            <img src={ewm} alt="ewm" />
            <span>微信扫码使用产品</span>
          </div>
        </Modal>
      </>
    );
  }
}

export default PcPhone;
