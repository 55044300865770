import React from "react";
import { Link } from "react-router-dom";
import style from "./css/Jumps.module.css";
import TABLOGO from "../../img/ic_tab_logo.jpg";

class Jumps extends React.Component {
  render() {
    return (
      <div className={style.head}>
        <Link to="/">
          <img className={style.logo} src={TABLOGO} onClick={this.home} alt="tablogo" />
        </Link>
        <div className={style.nav}>
          <Link to="/pager/DigitalTwin">
            <div className={`${style.nav_child} ${this.props.status==="left" ? style.nav_activt : null}`}>
              <span className={style.nav_child_title_zh}>数字孪生</span>
              <span className={style.nav_child_title_en}>Digital Twins</span>
            </div>
          </Link>
          <Link to="/pager/NewIfsc">
            <div className={`${style.nav_child} ${this.props.status==="right" ? style.nav_activt : null}`}>
              <span className={style.nav_child_title_zh}>尚智定</span>
              <span className={style.nav_child_title_en}>IFSC</span>
            </div>
          </Link>
          <Link to="">
            <div className={`${style.nav_child} ${this.props.status === "right" ? style.nav_activt : null}`}
              onClick={()=>window.open("https://www.quantkernel.com", "_self")}>
              <span className={style.nav_child_title_zh}>易镜</span>
              <span className={style.nav_child_title_en}>QKS</span>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default Jumps;
