import React from "react";

class Line extends React.Component {
  render() {
    return <canvas id="canvas" style={{ position: "absolute", top: "50%", left: "-350px", transform: "translate3d(0, -50%, 0)" }}></canvas>
  }

  componentDidMount() {
    var parent = this;
    var canvas = document.getElementById("canvas");
    canvas.height = 800;
    canvas.width = window.innerWidth;
    var ctx = canvas.getContext('2d');

    var arr = [];
    var color = ["#7ddde2", "#b0dfe2", "#9fe2e9", "#c3e2ac",];
    var width = [2, 25, 20, 25];
    var speed = 20;

    class Person {
      constructor(personColor, personWidth, personSpeed) {
        this.i = 1;
        this.Rx = 720;
        this.RTy1 = 150;
        this.RBy1 = 650;
        this.RTy2 = 0;
        this.RBy2 = 800;
        this.Lx = 0;
        this.LTy = 250;
        this.LBy = 550;
        this.speed = personSpeed;
        this.color = personColor;
        this.width = personWidth;
        this.sum = 0;
        this.velocity = personSpeed;
      }
      cross() {
        ctx.beginPath()
        ctx.globalCompositeOperation = "destination-over";
        ctx.strokeStyle = this.color;
        ctx.lineWidth = this.width;
        ctx.moveTo(350, 350);
        ctx.bezierCurveTo(this.Lx, this.LBy, this.Lx, this.LTy, 350, 450);
        ctx.moveTo(350, 350);
        ctx.bezierCurveTo(this.Rx, this.RTy1, 450, this.RTy2, 450, 400);
        ctx.moveTo(350, 450);
        ctx.bezierCurveTo(this.Rx, this.RBy1, 450, this.RBy2, 450, 400);
        ctx.stroke();
      }
      move() {
        if (this.RBy2 <= 800 && this.RBy2 >= 0) {
          if (this.speed > 10) {
            this.speed -= 0.15;
          }
          this.Lx += this.speed * this.i;
          this.LTy += this.speed / 2 * this.i;
          this.LBy -= this.speed / 2 * this.i;
          this.Rx -= this.speed * this.i;
          this.RTy1 += this.speed / 2 * this.i;
          this.RBy1 -= this.speed / 2 * this.i;
          this.RTy2 += this.speed * this.i;
          this.RBy2 -= this.speed * this.i;
          if (this.width > 5) {
            this.width -= 0.25 * this.i;
          }
        }
      }
      draw() {
        this.move();
        this.cross();
      }
    }

    function init() {
      for (var i = 0; i < 4; i++) {
        if (i > 1) {
          speed -= i
        }
        arr.push(new Person(color[i], width[i], speed))
      }
    }

    init()
    animstion()

    function animstion() {
      parent.anim = requestAnimationFrame(animstion);
      canvas.width = "800";
      if (arr[3].RBy2 < 0) {
        cancelAnimationFrame(parent.anim);
        rebound(0);
      } else if (arr[3].RBy2 > 800) {
        cancelAnimationFrame(parent.anim);
        rebound(800);
      }
      arr.forEach(function (e, i) {
        arr[i].draw();
      })
    }

    function rebound(RBy2) {
      parent.timer = setTimeout(function () {
        arr.forEach(function (e, i) {
          arr[i].i = -arr[i].i;
          arr[i].RBy2 = RBy2;
          arr[i].speed = arr[i].velocity;
        })
        animstion();
      }, 1500)
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.anim);
    clearTimeout(this.timer)
  }
}

export default Line;
