import React from "react";
import Module from "./Module";
import mechanical from "./img/ic_mechanical.jpg";
import gltf from "../../gltf/industrial.gltf";

class Industrial extends React.Component {
  render() {
    return <Module src={mechanical} zhVal="工业制造与物联" enVal="Industry & loT" module={gltf} camera="1.6" x="0.8" y="-0.2"/>
  }
}

export default Industrial;
