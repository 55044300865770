import React from "react";
import style from "../../css/PcScpage.module.css";
import ic_white_under from "../../images/ic_white_under.jpg";

class SplitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenHeight: ((window.screen.height - 111) * window.innerWidth) / window.screen.width,
      screenWidth: window.innerWidth,
    };
		this.pcsplitTimer = "";
  }

  componentDidMount() {
    const parent = this;
    this.props.onRef && this.props.onRef(this);
    parent.contentImg();
  }

  resize = () => {
    this.setState({
      screenHeight: ((window.screen.height - 111) * window.innerWidth) / window.screen.width,
      screenWidth: window.innerWidth,
      contentImg: ((-window.innerWidth * (window.screen.height - 111)) / window.screen.width) * 0.08,
    });
  };

  scrollIntoView = (status) => {
    const anchorElement = document.getElementById(status);
    if (anchorElement) {
      anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };

  contentImg = () => {
    let contentImg = this.state.contentImg, screenHeight = this.state.screenHeight;
    this.pcsplitTimer = window.requestAnimationFrame(this.contentImg);
    if ( contentImg < -screenHeight * 0.06 && this.state.contentNum === 0) {
      this.setState({ contentImg: contentImg + 0.3  });
    } else if ( contentImg > -screenHeight * 0.08 && this.state.contentNum === 1) {
      this.setState({ contentImg: contentImg - 0.3 });
    }
    if (contentImg >= -screenHeight * 0.06) {
      this.setState({ contentNum: 1 });
    } else if (contentImg <= -screenHeight * 0.08) {
      this.setState({ contentNum: 0 });
    }
  };

  componentWillUnmount(){
		window.cancelAnimationFrame(this.pcsplitTimer);
	}

  render() {
    return (
        <div className={style.contentBottom} id="contentBottom"
          onClick={() => this.scrollIntoView(this.props.splitPage)}
          style={{ transform: "translate(0," + this.state.contentImg + "px)" }}>
          <img className={style.contentBottomImg} src={ic_white_under} alt="logo" height = {this.state.screenWidth * 0.009} style = {{minHeight: '10px'}}/>
        </div>
    );
  }
}

export default SplitPage;
