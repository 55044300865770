import React from "react";
import { Modal } from "antd";
import Flame from "../../utils/Flame";
import Foot from "../../../Foot";
import style from "../../css/CellPhoneScpage.module.css";
import ic_del_def_code from "../../images/ic_gb_def.jpg";
import img_bj from "../../images/img_bj.jpg";

class End extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    }
  }

  phFriend = () => {
    this.setState({visible: true});
  }

  handleCancel = () => {
    this.setState({visible: false});
  }

  render() {
    return (
      <>
        <div className={style.phFriend} style={{ height: window.innerHeight * 0.3 }}>
          <div className={style.phFriendText}>
            <div className={style.friendLeft}></div>
            <span onClick={this.phFriend}>立即合作</span>
            <div className={style.friendRight}></div>
            <Modal className = "cooperate" visible={this.state.visible} onCancel={this.handleCancel} footer={null} closeIcon={<img src={ic_del_def_code} alt="del" />}>
              <span>请在pc端打开此页面与我们合作</span>
            </Modal>
          </div>
          <img className={style.phFriendImg} src={img_bj} alt="friend" height={window.innerHeight * 0.3}/>
        </div>
        <Flame />
        <Foot bgcolor={"rgba(223, 82, 76)"} ifsc_na = {true} />
      </>
    );
  }
}

export default End;
