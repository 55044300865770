import React from "react";
import Foot from "./Foot";
import PcDss from "./pc/Dss";
import PcVision from "./pc/Vision";
import PcVehicle from "./pc/Vehicle";
import PcBiology from "./pc/Biology";
import PhoneDss from "./phone/Dss";
import PhoneVirtual from "./phone/Virtual";
import PhoneVirtual2 from "./phone/Virtual2";
import PhoneVision from "./phone/Vision";

class DigitalTwin extends React.Component {
  render() {
    return (
      <div>
        <div className="container" style={{width:"100vw",padding:"0"}}>
          <div>{this.state.one}</div>
          <div>{this.state.two}</div>
          <div>{this.state.three}</div>
          <div><Foot /></div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      one: "",
      two: "",
      three: ""
    }
    this.vehicle = <PcVehicle GETstop={this.stop} GETstart={this.start} />;
    this.biology = <PcBiology GETstop={this.stop} GETstart={this.start} />;
    this.module = [];
  }

  stop = () => {
    clearInterval(this.inttime);
  }

  start = () => {
    var i = 0;
    var parent = this;
    this.inttime = setInterval(function () {
      i++;
      parent.setState({ three: parent.module[i] });
      if (i === 1) {
        i = -1;
      }
    }, 5000);
  }

  UNSAFE_componentWillMount() {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.setState({ one: <PcVision />, two: <PcDss />, three: this.vehicle });
      this.module = [
        this.vehicle,
        this.biology
      ];
    } else {
      this.setState({ one: <PhoneVision />, two: <PhoneDss />, three: (<PhoneVirtual GETstop={this.stop} GETstart={this.start} />) });
      this.module = [
        <PhoneVirtual GETstop={this.stop} GETstart={this.start} />,
        <PhoneVirtual2 GETstop={this.stop} GETstart={this.start} />
      ];
    }
    this.start();
  }

  componentDidMount() {
    function loadJs(url, callback) {
      var script = document.createElement('script');
      script.type = "text/javascript";
      if (typeof (callback) != "undefined") {
        if (script.readyState) {
          script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              callback();
            }
          }
        } else {
          script.onload = function () {
            callback();
          }
        }
      }
      script.src = url;
      document.body.appendChild(script);
    }
    loadJs("./chart.js");
  }

  componentWillUnmount() {
    clearInterval(this.inttime);
  }
}

export default DigitalTwin;
