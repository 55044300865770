import React from "react";
import style from "../../css/PcScpage.module.css";
import ic_red_l from "../../images/ic_red_l.jpg";
import ic_white_r from "../../images/ic_white_r.jpg";

// banner two page circleChange
export default class Distance extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      contentStu: 0
    }
  }
  render() {
    let pcScreenHeight = this.props.pcScreenHeight;
    let pcScreenWidth = this.props.pcScreenWidth;
    return (
      <div id="contaniner" className={style.bannerContentThree} style={{transform:'translate(0px,'+pcScreenHeight*0.15+'px)'}}>
        <div className={`${style.contentThreeLeft} swiper-button-prev`}
          style={{ opacity: this.state.contentStu === 0 ? "1" : "0", width: pcScreenWidth * 0.02, height: pcScreenHeight * 0.05 }}>
          <img className={style.contentLeft} src={ic_white_r} alt="right" height = {pcScreenWidth * 0.01} style = {{minHeight:'10px'}}/>
        </div>
        <div id="contaniner" className={`${style.contentThreeRight} swiper-button-next`}
          style={{ opacity: this.state.contentStu === 1 ? "1" : "0", width: pcScreenWidth * 0.02, height: pcScreenHeight * 0.05 }}>
          <img src={ic_red_l} className={style.contentRight} alt="left" height = {pcScreenWidth * 0.01}style = {{minHeight:'10px'}}/>
        </div>
      </div>
    );
  }
  componentDidMount(){
    this.props.onRef && this.props.onRef(this);
  }
  setDefault = (data) => {
    this.setState({ contentStu: data })
  }
}

