import React from "react";
import * as THREE from "three/build/three.module";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { MarchingCubes } from "three/examples/jsm/objects/MarchingCubes";
import vision_tex from "../../img/img_vision_texture.jpg";

class Three extends React.Component {
  render() {
    return <div id="containers"></div>;
  }

  constructor(props) {
    super(props);
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.state = {
        cheight: window.innerHeight >= 723 ? window.innerHeight : 723,
        cwidth: window.innerWidth >= 1200 ? window.innerWidth : 1200
      }
    } else {
      this.state = {
        cheight: window.innerHeight,
        cwidth: window.innerWidth
      }
    }
    this.container = "";
    this.amera = "";
    this.scene = "";
    this.renderer = "";
    this.materials = "";
    this.current_material = "";
    this.light = "";
    this.pointLight = "";
    this.ambientLight = "";
    this.effect = "";
    this.resolution = 0;
    this.effectController = "";
    this.time = 0;
    this.clock = new THREE.Clock();
  }

  size() {
    this.renderer.setSize(this.state.cwidth, this.state.cheight);
  }

  init = () => {
    this.container = document.getElementById("containers");
    this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);
    this.camera.position.set(-500, 500, 1500);
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000022);
    var spotLight = new THREE.SpotLight(0xffffff);
    this.scene.add(spotLight);
    this.light = new THREE.DirectionalLight(0xffffff);
    this.light.position.set(0.5, 0.5, 1);
    this.scene.add(this.light);
    this.pointLight = new THREE.PointLight(0xff3300);
    this.pointLight.position.set(0, 0, 100);
    this.scene.add(this.pointLight);
    this.ambientLight = new THREE.AmbientLight(0x080808);
    this.scene.add(this.ambientLight);
    var texture = new THREE.TextureLoader().load(vision_tex);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;

    this.materials = {
      textured: {
        m: new THREE.MeshPhongMaterial({
          color: 0xffffff,
          specular: 0x111111,
          shininess: 1,
          map: texture
        }), h: 0, s: 0, l: 1
      }
    }
    this.current_material = "textured";
    this.resolution = 28;
    this.effect = new MarchingCubes(this.resolution, this.materials[this.current_material].m, true, true);
    this.effect.position.set(0, 0, 0);
    this.effect.scale.set(700, 700, 700);
    this.effect.enableUvs = true;
    this.effect.enableColors = false;
    this.scene.add(this.effect);
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.domElement.style.position = "absolute";
    this.renderer.domElement.style.top = "0px";
    this.renderer.domElement.style.left = "0";
    this.renderer.domElement.style.zIndex = "-1";
    this.container.appendChild(this.renderer.domElement);
    this.size();
    new OrbitControls(this.camera, this.renderer.domElement);
    this.setupGui();
  }

  animate() {
    this.renders();
  }

  renders() {
    var delta = this.clock.getDelta();
    this.time += delta * this.effectController.speed * 0.5;
    if (this.effectController.resolution !== this.resolution) {
      this.resolution = this.effectController.resolution;
      this.effect.init(Math.floor(this.resolution));
    }
    if (this.effectController.isolation !== this.effect.isolation) {
      this.effect.isolation = this.effectController.isolation;
    }
    this.updateCubes(this.effect, this.time,
      this.effectController.numBlobs,
      this.effectController.floor,
      this.effectController.wallx,
      this.effectController.wallz);
    if (this.effect.material instanceof THREE.ShaderMaterial) {
      this.effect.material.uniforms["uBaseColor"].value.setHSL(
        this.effectController.hue,
        this.effectController.saturation,
        this.effectController.lightness
      );
    } else {
      this.effect.material.color.setHSL(
        this.effectController.hue,
        this.effectController.saturation,
        this.effectController.lightness
      );
    }
    this.light.position.set(
      this.effectController.lx,
      this.effectController.ly,
      this.effectController.lz
    );
    this.light.position.normalize();
    this.pointLight.color.setHSL(
      this.effectController.lhue,
      this.effectController.lsaturation,
      this.effectController.llightness
    );
    this.renderer.render(this.scene, this.camera);
  }

  setupGui() {
    var createHandler = function (id) {
      return function () {
        var mat_old = this.materials[this.current_material];
        mat_old.h = m_h.getValue();
        mat_old.s = m_s.getValue();
        mat_old.l = m_l.getValue();
        this.current_material = id;
        var mat = this.materials[id];
        this.effect.material = mat.m;
        m_h.setValue(mat.h);
        m_s.setValue(mat.s);
        m_l.setValue(mat.l);
        this.effect.enableUvs = this.current_material === "textured" ? true : false;
        this.effect.enableColors = this.current_material === "colors" || this.current_material === "multiColors" ? true : false;
      }
    }

    this.effectController = {
      material: "textured",
      speed: 0.75,
      numBlobs: 6,
      resolution: 60,
      isolation: 30,
      floor: false,
      wallx: false,
      wallz: false,
      hue: 0,
      saturation: 0,
      lightness: 1,
      lhue: 0.325,
      lsaturation: 0.325,
      llightness: 0.325,
      lx: 0.125,
      ly: 1,
      lz: 1,
    }
    var m_h, m_s, m_l;
    for (var m in this.materials) {
      this.effectController[m] = createHandler(m);
    }
  }

  updateCubes(object, time, numblobs, floor, wallx, wallz) {
    object.reset();
    var i, ballx, bally, ballz, subtract, strength;
    var rainbow = [
      new THREE.Color(0xff0000),
      new THREE.Color(0xff7f00),
      new THREE.Color(0xffff00),
      new THREE.Color(0x00ff00),
      new THREE.Color(0x0000ff),
      new THREE.Color(0x4b0082),
      new THREE.Color(0x9400d3)
    ];
    subtract = 12;
    strength = 1.2 / ((Math.sqrt(numblobs) - 1) / 4 + 1);
    for (i = 0; i < numblobs; i++) {
      ballx = Math.sin(i + 1.26 * time * (1.03 + 0.5 * Math.cos(0.21 * i))) * 0.27 + 0.5;
      bally = Math.abs(Math.cos(i + 1.12 * time * Math.cos(1.22 + 0.1424 * i))) * 0.77; // dip into the floor
      ballz = Math.cos(i + 1.32 * time * 0.1 * Math.sin(0.92 + 0.53 * i)) * 0.27 + 0.5;
      if (this.current_material === "multiColors") {
        object.addBall(ballx, bally, ballz, strength, subtract, rainbow[i % 7]);
      } else {
        object.addBall(ballx, bally, ballz, strength, subtract);
      }
    }
    if (floor) object.addPlaneY(2, 12);
    if (wallz) object.addPlaneZ(2, 12);
    if (wallx) object.addPlaneX(2, 12);
  }

  componentDidMount() {
    this.init();
    this.inttime = setInterval(() => {
      this.animate();
    }, 1000 / 60);
  }

  UNSAFE_componentWillMount() {
    var parent = this;
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.addEventListener("resize", onWindowResize);
    }
    function onWindowResize(e) {
      var height = window.innerHeight;
      var width = window.innerWidth;
      if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        if (height < 723) {
          height = 723
        }
      }
      parent.camera.aspect = width / height;
      parent.camera.updateProjectionMatrix();
      parent.renderer.setSize(width, height);
    }
  }

  componentWillUnmount() {
    clearInterval(this.inttime);
    this.renderer.forceContextLoss();
  }
}

export default Three;
