import React from "react";
import style from "../css/Foot.module.css";
import Partner from "./pc/Partner";
import subscribe from "../img/ic_subscribe.jpg";
import code from "../img/ic_qr.jpg";
import mail from "../img/ic_mail.jpg";
import earth from "../img/ic_earth.jpg";
import na from "../img/ic_na.jpg";
import ifsc_na from "./ifsc/images/ic_beimei.jpg" 
import WeChat from "../img/ic_weixin.jpg";
import logo from "../img/ic_logo.jpg";
import ifsc_code from "./ifsc/images/img_ewm_t.jpg";

class Foot extends React.Component {
  render() {
    return (
      <div className={style.content4} style={{ backgroundColor: !this.props.show ? this.props.bgcolor?this.props.bgcolor:"#000022" : null }}>
        {this.props.show ? <Partner /> : null}
        <div className={style.foot} style={{ border: !this.props.show ? 0 : null }}>
          <div className={style.introduce}>
            <div className={style.foot_content}>
              <div className={style.foot_content_left}>
                <img className={style.foot_logo} src={this.props.img?this.props.img:logo} style={this.props.imgCss?this.props.imgCss:null} alt="logo" />
              </div>
              <div className={style.foot_content_right}>
                <div className={style.button}>
                  <span onClick={this.PROPOSER} style={{ backgroundColor: this.state.bac1, color: this.state.col1 }} >
                    PROPOSER
                  </span>
                  <span onClick={this.PROPOSEE} style={{ backgroundColor: this.state.bac2, color: this.state.col2 }} >
                    PROPOSEE
                  </span>
                </div>
                <div className={style.contact}>
                  <input className={style.address} placeholder="Your Email" style={{backgroundColor:this.props.bgcolor?this.props.bgcolor:null}}/>
                  <img className={style.subscribe} src={subscribe} alt="subscribe" />
                </div>
              </div>
            </div>
            <div className={style.details}>
              <div className={style.details_left}>
                <div className={style.details_childer}>
                  <img src={WeChat} alt="weixin" />
                  <span style={{color:this.props.bgcolor?"#ffffff":null}}>盾钰孪生云</span>
                </div>
                <div className={style.details_childer}>
                  <img src={mail} alt="mail" /> <span style={{color:this.props.bgcolor?"#ffffff":null}}>admin@wengold.net</span>
                </div>
              </div>
              <div className={style.details_right}>
                <div className={style.details_childer}>
                  <img src={earth} alt="earth" />
                  <span style={{color:this.props.bgcolor?"#ffffff":null}}>浦东张江高科技园区，上海，中国</span>
                </div>
                <div className={style.details_childer}>
                  <img src={this.props.ifsc_na?ifsc_na:na} alt="na" style={this.props.ifsc_na?this.na:null} />
                  <span style={{color:this.props.bgcolor?"#ffffff":null}}>52 Hope St Mountain View，CA 94042，U.S.A</span>
                </div>
              </div>
            </div>
            <div className={style.foot_copyright} style={{color:this.props.bgcolor?"#ffffff":null}}>
              <a href="http://beian.miit.gov.cn" style={{color:this.props.bgcolor?"#ffffff":null}}>©沪ICP备16002679号</a> - 盾钰(上海)互联网科技有限公司 COPYRIGHT© 2015-Now ALL RIGHTS RESERVED BY DUNYU CO,.LTD
            </div>
          </div>
          <div className={style.code}>
            <img src={this.props.ifsc_na?ifsc_code:code} alt="code" />
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      partner1: "",
      partner2: "",
      partner3: "",
      partner4: "",
      partner5: "",
      partner6: "",
      partner7: "",
      bac1: "",
      bac2: "#000022",
      col1: "",
      col2: "rgba(180,180,182,1)",
      havebac: "#000022",
      nocol: "rgba(180,180,182,1)",
      nobac: "#fff",
      havecol: "rgba(0,1,31,1)",
    };

    this.na = {
      width: '20px',
      height: "20px",
    }
  }

  PROPOSEE = () => {
    this.setState({
      bac2: this.state.havebac,
      col2: this.state.nocol,
      bac1: this.state.nobac,
      col1: this.state.havecol
    });
  };

  PROPOSER = () => {
    this.setState({
      bac1: this.state.havebac,
      col1: this.state.nocol,
      bac2: this.state.nobac,
      col2: this.state.havecol
    });
  };
}

export default Foot;
