import React from "react";
import { Modal, Button } from "antd";
import site from "../../utils/IfscCommon";
import style from "../../css/Work.module.css";
import ewm from "../../images/img_ewm_t.jpg";
import ic_del_def_code from "../../images/ic_gb_def.jpg";

export default class ModalCooperate extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      phUserAgent: false,
      visible: false
    }
  }
  render(){
    return(
      <>
      <Modal
          className = "cooperate"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          closeIcon={this.state.phUserAgent ? null : <img src={ic_del_def_code} alt="code" />}
          closable={this.state.phUserAgent ? false : true}>
          {this.state.phUserAgent ? <>
              <div className={style.modalText}>是否下载?</div>
              <div className={style.modalAndroid}>
                <Button onClick={this.handleCancel} style={{ marginRight: "5px" }}>取消</Button>
                <Button type="primary" onClick={this.download}>下载</Button>
              </div>
            </> : <div className={style.modal}><img src={ewm} alt="ewm" /><span>微信扫码使用产品</span></div>}
        </Modal>
      </>
    )
  }

  componentDidMount(){
    this.props.onRef && this.props.onRef(this);
  }
  setDefault = (user,visible) => {
    this.setState({
      phUserAgent: user,
      visible: visible
    })
  }
  handleCancel = () => { 
    this.setState({ visible: false });
  }
  download = () => {
    window.location.href = site.download;
    this.handleCancel();
  }
}