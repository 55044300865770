import React from "react";
import style from "../../css/PcScpage.module.css";

// Why Us Circle
class CanvasPcThree extends React.Component {
  render() {
    return (
        <canvas id="ChooseCanvas" className={style.ChooseCanvas} width={window.innerWidth} height={this.state.screenHeight}></canvas>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: (window.screen.height * window.innerWidth) / window.screen.width,
      pcCircleSize: 0,
      num: 0,
      pcCircleTwo: 0,
    };
    this.ctxFoul = "";
    this.canvasFoul = "";
    this.CanvasThreetimer = "";
  }

  componentDidMount() {
    const parent = this;
    parent.canvasFoul = document.getElementById("ChooseCanvas");
    parent.ctxFoul = parent.canvasFoul.getContext("2d");
    parent.pcCircleChange();
    parent.resize();
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.CanvasThreetimer);
    window.removeEventListener('resize',this.resize);
  }

  resize = () => {
    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: (window.screen.height * window.innerWidth) / window.screen.width
    });
  };

  pcCircleAction = () => {
    const parent = this;
    parent.setState({
      pcCircleSize: parent.state.pcCircleSize + 0.002,
      num: parent.state.num + 1,
    });
  };

  pcCircleChange = () => {
    const parent = this;
    parent.canvasFoul.width = window.innerWidth;
    parent.canvasFoul.height = ((window.screen.height - 111) * window.innerWidth) / window.screen.width;
    parent.pcCircleAction();
    if (parent.state.pcCircleSize >= Math.PI * 2) {
      parent.setState({ pcCircleSize: parent.state.pcCircleSize - Math.PI * 2 });
    }
    if (parent.state.num > 100) {
      parent.setState({ pcCircleTwo: parent.state.pcCircleTwo + 0.004 });
      if (parent.state.num > 130) {
        parent.setState({  num: 0 });
      }
    } else {
      parent.setState({ pcCircleTwo: parent.state.pcCircleTwo + 0.02 });
    }
    parent.ctxFoul.beginPath();
    parent.ctxFoul.translate(0, 0);
    var colThree = parent.ctxFoul.createLinearGradient(0,0,parent.canvasFoul.width,parent.canvasFoul.height);
    colThree.addColorStop(0, "#EE605A");
    colThree.addColorStop(1, "#CE3C35");
    parent.ctxFoul.lineWidth = parent.canvasFoul.width * 0.2;
    parent.ctxFoul.strokeStyle=colThree;
    parent.ctxFoul.fillStyle=colThree;
    parent.ctxFoul.beginPath();
    parent.ctxFoul.moveTo(parent.canvasFoul.width * -0.2,parent.canvasFoul.width * 0.41);
    parent.ctxFoul.bezierCurveTo(
      parent.canvasFoul.width * 0.104,
      0,
      parent.canvasFoul.width * 0.38,
      parent.canvasFoul.height * 0.31,
      parent.canvasFoul.width * 1.1,
      parent.canvasFoul.height * 0.2
    );
    parent.ctxFoul.stroke();
    parent.ctxFoul.fillRect(
      0,
      parent.canvasFoul.height * 0.2,
      parent.canvasFoul.width,
      parent.canvasFoul.height
    );
    parent.ctxFoul.fillStyle="#eee";
    parent.ctxFoul.strokeStyle="#fff";
    parent.ctxFoul.lineWidth = 2;
    parent.ctxFoul.fillStyle="rgba(233,140,136)";
    parent.ctxFoul.strokeStyle="rgba(233,140,136)";
    parent.ctxFoul.beginPath();
    parent.ctxFoul.arc(
      parent.canvasFoul.width * 0.52,
      parent.canvasFoul.height * 0.6192,
      parent.canvasFoul.width * 0.1172,
      0 - this.state.pcCircleSize,
      (Math.PI / 4) * 7 - this.state.pcCircleSize,
      false
    );
    parent.ctxFoul.stroke();
    parent.ctxFoul.closePath();
    parent.ctxFoul.strokeStyle="rgba(233,140,136)";
    parent.ctxFoul.beginPath();
    parent.ctxFoul.setLineDash([5, 3]);
    parent.ctxFoul.arc(
      parent.canvasFoul.width * 0.52,parent.canvasFoul.height * 0.62,parent.canvasFoul.width * 0.094,0,Math.PI * 2);
    parent.ctxFoul.stroke();
    parent.ctxFoul.closePath();
    parent.ctxFoul.strokeStyle="rgba(233,140,136)";
    parent.ctxFoul.beginPath();
    parent.ctxFoul.shadowBlur = 2;
    parent.ctxFoul.shadowOffsetX = 0;
    parent.ctxFoul.shadowColor = "rgba(255,255,255)";
    parent.ctxFoul.arc(
      parent.canvasFoul.width * 0.52,
      parent.canvasFoul.height * 0.62,
      parent.canvasFoul.width * 0.146,
      Math.PI / 4 + parent.state.pcCircleTwo,
      Math.PI / 1.5 + parent.state.pcCircleTwo
    );
    parent.ctxFoul.stroke();
    parent.ctxFoul.closePath();
    parent.ctxFoul.beginPath();
    parent.ctxFoul.arc(
      parent.canvasFoul.width * 0.52,
      parent.canvasFoul.height * 0.62,
      parent.canvasFoul.width * 0.146,
      Math.PI * 1.3 + parent.state.pcCircleTwo,
      Math.PI * 1.7 + parent.state.pcCircleTwo
    );
    parent.ctxFoul.stroke();
    parent.ctxFoul.closePath();
    parent.ctxFoul.beginPath();
    parent.ctxFoul.setLineDash([0, 0]);
    parent.ctxFoul.strokeStyle="rgba(233,140,136)";
    parent.ctxFoul.stroke();
    parent.ctxFoul.closePath();
    parent.ctxFoul.translate(
      parent.canvasFoul.width * 0.52,
      parent.canvasFoul.height * 0.62
    );
    parent.ctxFoul.rotate(-this.state.pcCircleSize);
    parent.ctxFoul.beginPath();
    parent.ctxFoul.arc(
      parent.canvasFoul.width * 0.116,0,parent.canvasFoul.width * 0.005,0,Math.PI * 2,false);
    parent.ctxFoul.fill();
    parent.ctxFoul.closePath();
    this.CanvasThreetimer = window.requestAnimationFrame(this.pcCircleChange);
  };
}

export default CanvasPcThree;
