import React from "react";
import style from "./css/Virtual.module.css";
import QueueAnim from "rc-queue-anim";
import left from "./img/img_exercise.jpg";
import right from "./img/img_molecule.jpg";
import jia from "./img/ic_jia.jpg";
import btn from "./img/ic_btn.jpg";

class Biology extends React.Component {
  render() {
    return (
      <QueueAnim type="left" duration={1500}>
        <div key="content" className={style.content1} style={{ height: this.state.inHeight, minHeight: '723px ' }}>
          <div className={style.virtual}>
            <div className={style.img_left} style={{ width: this.state.dragwidth + '%' }}>
              <img src={left} style={{ width: this.state.inWidth }} alt="left" />
            </div>
            <div className={style.img_right} style={{ width: (100 - this.state.dragwidth) + '%' }}>
              <img src={right} style={{ width: this.state.inWidth }} alt="right" />
            </div>
          </div>
          <div className={style.add}>
            <div className={style.add_font}>
              <span className={style.add_zh}>超越虚拟现实</span>
              <div className={style.font_virtual}>
                <span className={style.add_en}>Virtual Reality</span>
                <img className={style.add_img} src={jia} alt="jia" />
              </div>
            </div>
          </div>
          <div className={style.bar} style={{ left: this.state.dragwidth + '%' }}>
            <img src={btn} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd} onDrag={this.ondrag} draggable="true" alt="btn" />
          </div>
        </div>
      </QueueAnim>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      dragwidth: 50,
      start: "",
      stop: "",
      inWidth: window.innerWidth,
      inHeight: window.innerHeight
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("resize", this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }

  resize = () => {
    if (this.state.inWidth < window.innerWidth) {
      this.setState({ inWidth: window.innerWidth, inHeight: window.innerHeight })
    }
  }

  onDragStart = (e) => {
    this.setState({ start: e.clientX });
    this.props.GETstop('stop')
  }

  onDragEnd = () => {
    this.props.GETstart('start')
  }

  ondrag = (e) => {
    e.preventDefault();
    this.setState({ stop: e.clientX });
    if (this.state.dragwidth > window.innerWidth - 40) {
      this.setState({
        dragwidth: window.innerWidth - 40
      });
    } else {
      this.setState({
        dragwidth: (this.state.start - (this.state.start - this.state.stop)) / window.innerWidth * 100
      });
    }
  }
}

export default Biology;
