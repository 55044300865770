import React, { Component } from 'react'
import './css/VerCws.css'
import bgimg from '../../img/img_cws_bg_v.jpg'
import qrcode from '../../img/img_cws_code.jpg'

// FIXME Cws Home Page
class VerCws extends Component {
    render() {
        return (
            <div className="mb-root-container">
                <img className="mb-bgimg-ver" src={bgimg} alt="bgimg" />
                <div className="mb-invitation-text">INVITATION</div>

                <div className='mb-title-layer'>
                    <div className='mb-title-num'>2020</div>
                    <div className='mb-title-chinese'>汽车生态云展览</div>
                    <div className='mb-title-english'>2020 Auto Ecosystem C-Exhibition</div>
                </div>

                <div className="slogan-date-layer">
                  <div className="mb-slogan-layer">
                      <div className='mb-slogan-front'>心随云动，拥抱车彩</div>
                      <div className="mb-slogan-behind">心随云动，拥抱车彩</div>
                  </div> 
                  <div className="mb-start-date">2020/12/25-12/30</div>
                  <div className="mb-start-text">相聚云端</div>
                </div>

                <div className="mb-hot-title">火热招商</div>
                <ul className="mb-hot-content">
                    <li>名车整车馆 ：整车生态、平行进口、二手经销 等</li>
                    <li>车载综合馆 ：轮胎轮毂、车载电子、车友用品 等</li>
                    <li>养护服务馆 ：养护耗材、特色耗材、出行服务 等</li>
                    <li>美妆改装馆 ：美妆材料、改装搭配、汽改服务 等</li>
                    <li>智能出行馆 ：智能科技、车联物联、智能汽车 等</li>
                </ul>

                <div className='mb-qrcode-layer'>
                  <img className='mb-qrcode-img' src={qrcode} alt=""/>
                  <div className='mb-qrcode-text'>
                    <div>诚挚邀请，扫码观展</div>
                    <div>Scan the QR code to explore C-Exhibition</div>

                    <div className='mb-express-text'>
                      鸣谢单位：<br/>上海市物联网行业协会、上海市浦东新区光电子行业协会、<br/>
                      北京汽车协会、上海市科学技术委员会、上海市金桥管委会、<br/>
                      上海市张江管委会、上海市外高桥管委会、<br/>
                      上海市浦东新区科学技术管委会
                    </div>
                  </div>
                </div>

                <footer className='mb-footer-text'>
                  <a href='http://beian.miit.gov.cn/' style={{color:'rgba(255,255,255,0.8)'}}> 
                    ©沪ICP备16002679号 </a>- 盾钰(上海)互联网科技有限公司<br/>
                    COPYRIGHT© 2015-Now ALL RIGHTS RESERVED BY DUNYU CO,.LTD
                </footer>
                
            </div>
        )
    }
}

export default VerCws;