import React from "react";
import style from "./css/Code.module.css";
import qr from "./img/img_qr.jpg";
import Jumps from "./Jumps";

class Code extends React.Component {
  render() {
    return (
      <div className={style.content1} style={{ height: window.innerHeight }}>
        <Jumps status="right" />
        <div className={style.banner} style={{ height: window.innerHeight * 0.8 }}>
          <div className={style.banner_right}>
            <span className={style.banner_zh}>你只需要二维码</span>
            <span className={style.banner_en}>All you need is a QR</span>
            <img className={style.codeimg} src={qr} alt="qr" />
          </div>
        </div>
      </div>
    );
  }
}

export default Code;
