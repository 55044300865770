import React from "react";
import PCCwsAdv from "./pc/CwsAdv";
import MBCwsAdv from "./phone/CwsAdv";

// FIXME Cws Page
class Cws extends React.Component {
  render() {
    return (<div style={{ position: "relative" }}>{this.state.one}</div>);
  }

  constructor(props) {
    super(props);
    this.state = { one: "" }
  }

  UNSAFE_componentWillMount() {
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.setState({ one: <PCCwsAdv parent={this} history={this.props.history} /> });
    } else {
      this.setState({ one: <MBCwsAdv parent={this} /> });
    }
  }
}

export default Cws;
