import React from "react";
import { Link } from "react-router-dom";
import style from "./css/Vision.module.css";
import { Carousel } from "antd";
import Ball from "../widget/Three";
import Jumps from "./Jumps";

class Vision extends React.Component {
  render() {
    return (
      <div className={style.content1} style={{ height: window.innerHeight }}>
        <Ball />
        <Jumps status="left" />
        <div className={style.banner} style={{ height: window.innerHeight * 0.8 }} >
          <div className={style.banner_right}>
            <span className={style.banner_zh}>VISION</span>
            <Carousel dots={false} ref={el => (this.slider = el)}>
              <div className={style.banner_en}>内部 Inside</div>
              <div className={style.banner_en}>探索 Explore</div>
              <div className={style.banner_en}>推演 Inference</div>
              <div className={style.banner_en}>孪生 Twins</div>
            </Carousel>
          </div>
          <Link to="/pager/Commodity">
            <div className={style.product}>
              <span className={style.product_zh}>产品</span>
              <span className={style.product_en}>Look Inside</span>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  home = () => {
    document.getElementsByClassName("times")[0].style.display = "block";
  }

  DigitalTwins = () => {
    document.getElementsByClassName("times")[0].style.display = "none";
  }

  componentDidMount() {
    var parent = this;
    this.inttime = setInterval(function () {
      parent.slider.next();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.inttime);
  }
}

export default Vision;
