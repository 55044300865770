import React from "react";
import Module from "./Module";
import molecule from "./img/ic_spiral.jpg";
import gltf from "../../gltf/health.gltf";

class Health extends React.Component {
  render() {
    return <Module src={molecule} zhVal="健康与生命科学" enVal="Health & Life Sciences" module={gltf} camera="2.1" x="0.9" y="-0.5"/>
  }
}

export default Health;