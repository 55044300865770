import React from "react";
import style from "../../css/Work.module.css";

class CanvasThree extends React.PureComponent {
  render() {
    return (
      <canvas width={this.state.screenWidth} height={this.state.screenHeight * 0.55} id="phCanvasThree" className={style.phCanvasTwo}></canvas>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      circleSize: 0,
      circleSizeTwo:0,
      num:0,
    }
    this.canvasOneTwo = "";
    this.ctxThree = "";
    this.timer = "";
  }

  circleVariation = () => {
    const parent = this;
    parent.setState({
      circleSize: this.state.circleSize + 0.002,
      num:parent.state.num+1
    });
  }

  circleChange = () => {
    const parent = this;
    this.circleVariation();
    if (parent.state.circleSize >= Math.PI * 2) {
      parent.setState({circleSize: parent.state.circleSize - Math.PI * 2});
    }
    if(parent.state.num>100){
      parent.setState({ circleSizeTwo:parent.state.circleSizeTwo+0.006 });
      if(parent.state.num>130){
        parent.setState({ num:0 })
      }
    }else{
      parent.setState({ circleSizeTwo: parent.state.circleSizeTwo+0.002 });
    }
    parent.canvasOneTwo.width = this.state.screenWidth;
    parent.canvasOneTwo.height = this.state.screenHeight * 0.55;
    parent.ctxThree.translate(0, 0);
    var phCanvasTwoCol = parent.ctxThree.createLinearGradient(
      parent.canvasOneTwo.height / 2,0,0,parent.canvasOneTwo.height);
    phCanvasTwoCol.addColorStop(0, "#EE605A");
    phCanvasTwoCol.addColorStop(1, "#CE3C35");
    parent.ctxThree.fillStyle=phCanvasTwoCol;
    parent.ctxThree.fillRect(
      0,0,parent.canvasOneTwo.width,parent.canvasOneTwo.height);
    parent.ctxThree.beginPath();
    parent.ctxThree.arc(
      parent.canvasOneTwo.width * 0.493,
      parent.canvasOneTwo.height * 0.539,
      parent.canvasOneTwo.width * 0.213,
      0 + this.state.circleSize,
      (Math.PI * 2) / 1.2 + this.state.circleSize
    );
    parent.ctxThree.strokeStyle="rgba(233,140,136)";
    parent.ctxThree.fillStyle="rgba(233,140,136)";
    parent.ctxThree.stroke();
    parent.ctxThree.closePath();
    parent.ctxThree.setLineDash([0, 0]);
    parent.ctxThree.setLineDash([5, 3]);
    parent.ctxThree.beginPath();
    parent.ctxThree.arc(
      parent.canvasOneTwo.width * 0.493,parent.canvasOneTwo.height * 0.539,parent.canvasOneTwo.width * 0.16,0,Math.PI * 2);
    parent.ctxThree.stroke();
    parent.ctxThree.closePath();
    parent.ctxThree.setLineDash([5, 3]);
    parent.ctxThree.beginPath();
    parent.ctxThree.arc(
      parent.canvasOneTwo.width * 0.493,
      parent.canvasOneTwo.height * 0.539,
      parent.canvasOneTwo.width * 0.267,
      Math.PI / 2 / 1.5 + this.state.circleSize,
      (Math.PI / 2) * 1.4 + this.state.circleSize
    );
    parent.ctxThree.stroke();
    parent.ctxThree.closePath();
    parent.ctxThree.beginPath();
    parent.ctxThree.arc(
      parent.canvasOneTwo.width * 0.493,
      parent.canvasOneTwo.height * 0.539,
      parent.canvasOneTwo.width * 0.267,
      Math.PI * 1.3 + this.state.circleSize + 0.01,
      Math.PI * 1.7 + this.state.circleSize + 0.01
    );
    parent.ctxThree.stroke();
    parent.ctxThree.closePath();
    parent.ctxThree.setLineDash([0, 0]);
    parent.ctxThree.shadowBlur = 1.5;
    parent.ctxThree.shadowOffsetX = 0;
    parent.ctxThree.shadowColor = "rgba(255,255,255)";
    parent.ctxThree.beginPath();
    parent.ctxThree.arc(
      parent.canvasOneTwo.width * 0.493,
      parent.canvasOneTwo.height * 0.539,
      parent.canvasOneTwo.width * 0.333,
      (Math.PI / 2) * 1.6 + this.state.circleSize,
      Math.PI * 1.1 + this.state.circleSize
    );
    parent.ctxThree.stroke();
    parent.ctxThree.closePath();
    parent.ctxThree.beginPath();
    parent.ctxThree.arc(
      parent.canvasOneTwo.width * 0.493,
      parent.canvasOneTwo.height * 0.539,
      parent.canvasOneTwo.width * 0.333,
      (Math.PI * 2) / 1.05 + this.state.circleSize,
      Math.PI / 2 / 2.5 + this.state.circleSize
    );
    parent.ctxThree.stroke();
    parent.ctxThree.closePath();
    parent.ctxThree.shadowBlur = 0;
    parent.ctxThree.setLineDash([5, 3]);
    parent.ctxThree.beginPath();
    parent.ctxThree.arc(
      parent.canvasOneTwo.width * 0.493,
      parent.canvasOneTwo.height * 0.539,
      parent.canvasOneTwo.width * 0.427,
      (Math.PI / 2) * 1.6 - this.state.circleSizeTwo,
      Math.PI * 1.1 - this.state.circleSizeTwo
    );
    parent.ctxThree.stroke();
    parent.ctxThree.closePath();
    parent.ctxThree.beginPath();
    parent.ctxThree.arc(
      parent.canvasOneTwo.width * 0.493,
      parent.canvasOneTwo.height * 0.539,
      parent.canvasOneTwo.width * 0.427,
      (Math.PI * 2) / 1.05 - this.state.circleSizeTwo,
      Math.PI / 2 / 2.5 - this.state.circleSizeTwo
    );
    parent.ctxThree.stroke();
    parent.ctxThree.closePath();
    parent.ctxThree.translate(
      parent.canvasOneTwo.width * 0.493,parent.canvasOneTwo.height * 0.539);
    parent.ctxThree.rotate(this.state.circleSize);
    parent.ctxThree.beginPath();
    parent.ctxThree.arc(
      parent.canvasOneTwo.width * 0.213,0,parent.canvasOneTwo.width * 0.02133,0,Math.PI * 2);
    parent.ctxThree.fill();
    parent.ctxThree.closePath();
    this.timer = window.requestAnimationFrame(this.circleChange);
  }

  componentDidMount() {
    this.canvasOneTwo = document.getElementById("phCanvasThree");
    this.ctxThree = this.canvasOneTwo.getContext("2d");
    this.circleChange();
  }


  componentWillUnmount() {
    window.cancelAnimationFrame(this.timer);
  }
}

export default CanvasThree;
