import React from "react";
import style from "./css/Dirving.module.css";
import Pint from "../widget/Tween";
import Wengold from "../widget/Wengold";
import Jumps from "./Jumps";

class Dirving extends React.Component {
  render() {
    return (
      <div className={style.content1} style={{ height: "100vh", minHeight: '723px', position: "relative" }}>
        <Jumps />
        <Pint />
        <Wengold />
        <div className={style.banner} style={{ height: "80vh", minHeight: 723 * 0.8 + 'px ' }}>
          <div className={style.banner_right}>
            <span className={style.banner_zh}>数字驱动商业</span>
            <span className={style.banner_en}>Driving Your Business</span>
          </div>
        </div>
        <div className={style.banner_left}>
          <div className={style.hint}>
            <span className={style.hint_hang}></span>
          </div>
        </div>
      </div>
    );
  }
}

export default Dirving;
