import React from "react";
import {Popover} from 'antd';
import style from "../../css/PcScpage.module.css";
import ic_android from "../../images/ic_android.jpg";
import ic_ewm from "../../images/ic_ewm.jpg";
import ic_ios from "../../images/ic_ios.jpg";
import ic_qr from "../../images/img_ewm_t.jpg";

export default class Scan extends React.Component {
  constructor(props){
    super(props);
    this.state = { img_login_stu: 0 }
  }
  render() {
    let widthCircle =  this.props.widthCircle;
    return (
      <div className={style.bannerContentFive} id = "download" style={{ width: widthCircle, height: widthCircle }} onMouseOver={this.bannerContentFiveOver} onMouseOut={this.bannerContentFiveOut}>
        <Popover placement="bottom" getPopupContainer={triggerNode => triggerNode.parentNode}
          content={ <div>
            <div className={style.pcPopover}><img src={ic_qr} alt="qr" style={{width:"3rem"}} /></div>
          </div>}
          trigger="hover">
        <div className={style.contentFiveinnerCircle} id="contentFiveinnerCircle" style={{ width: widthCircle, height: widthCircle }}>
          <div style={{ opacity: this.state.img_login_stu === 0 ? 1 : 0 }}>
            <img src={ic_android} alt="android" width={widthCircle} height={widthCircle} />
          </div>
          <div style={{ opacity: this.state.img_login_stu === 1 ? 1 : 0 }}>
            <img src={ic_ewm} alt="ewm" width={widthCircle} height={widthCircle} />
          </div>
          <div style={{ opacity: this.state.img_login_stu === 2 ? 1 : 0 }}>
            <img src={ic_ios} alt="ios" width={widthCircle} height={widthCircle} />
          </div>
        </div>
        </Popover>
      </div>
    );
  }
  componentDidMount(){
    this.handTimer = window.setInterval(this.login_alter, 4000);
  }

  componentWillUnmount(){
    window.clearInterval(this.handTimer);
  }

  login_alter = () => {
    if (this.state.img_login_stu >= 2) {
      this.setState({ img_login_stu: 0 });
    } else {
      this.setState({ img_login_stu: this.state.img_login_stu + 1 });
    }
  };
  bannerContentFiveOver = () => {
    window.clearInterval(this.handTimer);
  };

  bannerContentFiveOut = () => {
    this.handTimer = window.setInterval(this.login_alter, 4000);
  };
}
