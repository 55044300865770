import React from 'react';
import CanvasPcThree from "./CanvasPcThree";
import style from "../../css/PcScpage.module.css";
import ic_gxh from "../../images/ic_gxh.jpg";
import ic_home from "../../images/ic_home.jpg";
import ic_lt from "../../images/ic_lt.jpg";
import ic_fit from "../../images/ic_fit.jpg";
import img_modulels from "../../images/img_models.jpg";

class PcUs extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        screenHeight:
        ((window.screen.height - 111) * window.innerWidth) /
        window.screen.width,
        screenWidth: window.innerWidth,
      };
      this.aiPhone = "拍照分析身型专属量体";
      this.fit = '面料样式我自主';
      this.living_home = '特有平面智能立裁快速适衣';
      this.individuality = '服装与涂鸦';
    }

    componentDidMount(){
      this.props.onRef && this.props.onRef(this);
    }

    resize = () =>{
      this.setState({
        screenHeight: ((window.screen.height - 111) * window.innerWidth) / window.screen.width,
        screenWidth: window.innerWidth,
      })
    }

    render(){
      return(
        <>
          <div className={style.pcChoose} style={{ height: this.state.screenHeight }}>
          <div className={style.pcChooseOne}>
            <div className={style.pcChooseOneOne}>
              <span className={style.pcChooseOneTextOne} style={{ fontSize: this.state.screenWidth * 0.01458 }}>选择我们</span>
              <span className={style.pcChooseOneTextTwo} style={{ fontSize: this.state.screenWidth * 0.01041 }} >Why Us</span>
              <div className={style.as}>
                <div className={style.lineChooseOne} style={{ width: this.state.screenWidth * 0.01823 }}></div>
                <div className={style.lineChooseTwo} style={{ width: this.state.screenWidth * 0.00781 }}></div>
                <div className={style.lineChooseThree} style={{ width: this.state.screenWidth * 0.0052 }}></div>
              </div>
            </div>
            <div className={style.pcChooseOneTwo} style={{ marginTop: this.state.screenHeight * 0.22 }}>
              <div className={style.pcChooseOneTwoOneLeft}>
                <div>
                  <img width={this.state.screenWidth * 0.0458} src={ic_lt} alt="lt"/>
                  <div className={style.pcChooseCircleOne} style={{ width: this.state.screenWidth * 0.0458, height: this.state.screenWidth * 0.0458 }}>
                    <div
                      className={style.pcChooseCircleLittle}
                      style={{ width: this.state.screenWidth * 0.006, height: this.state.screenWidth * 0.006, left: this.state.screenWidth * 0.017 }}></div>
                  </div>
                </div>
                <div className={style.pcChooseContent} style={{ fontSize: this.state.screenWidth * 0.0103 }}>
                  <span>AI量体</span>
                  <br />
                  <div className={style.contentTopLeft} style={{ fontSize: this.state.screenWidth * 0.00729 }}>
                    {this.aiPhone.split("").map((e, i) => {
                      return ( <span key={i} style={{ "--s": (0.5 / 10) * (i + 1) + "s" }}>{e}</span>);})}
                    <br />
                  </div>
                </div>
              </div>
              <div className={style.pcChooseOneTwoOneRight}>
                <div className={style.pcCircleOne}>
                  <img width={this.state.screenWidth * 0.0458} src={ic_fit} alt="fit"/>
                  <div className={style.pcChooseCircle}
                    style={{ width: this.state.screenWidth * 0.0458, height: this.state.screenWidth * 0.0458 }}>
                    <div className={style.pcChooseCircleLittle} 
                      style={{ width: this.state.screenWidth * 0.006, height: this.state.screenWidth * 0.006,left: this.state.screenWidth * 0.017 }}></div>
                  </div>
                </div>
                <div className={style.pcChooseContentRight} style={{ fontSize: this.state.screenWidth * 0.0103 }}>
                  <span>自由</span>
                  <br />
                  <div className={style.contentTopRight} style={{ fontSize: this.state.screenWidth * 0.00729 }}>
                    {this.fit.split("").map((e, i) => {
                      return ( <span key={i} style={{ "--s": (0.5 / 7) * (i + 1) + "s" }}>{e}</span>)})}
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className={style.pcChooseOneThree} style={{ top: this.state.screenHeight * 0.8 }}>
              <div className={style.pcChooseOneTwoTwoLeft}>
                <div>
                  <img width={this.state.screenWidth * 0.0458} src={ic_home}alt="home"/>
                  <div className={style.pcChooseCircleThree}
                    style={{ width: this.state.screenWidth * 0.0458, height: this.state.screenWidth * 0.0458,}}>
                    <div className={style.pcChooseCircleLittle}
                      style={{ width: this.state.screenWidth * 0.006, height: this.state.screenWidth * 0.006, left: this.state.screenWidth * 0.017 }}></div>
                  </div>
                </div>
                <div className={style.pcChooseContentTwo} style={{ fontSize: this.state.screenWidth * 0.0103 }}>
                  <span>合体</span>
                  <br />
                  <div className={style.contentBottomLeft} style={{ fontSize: this.state.screenWidth * 0.00729 }}>
                    {this.living_home.split("").map((e, i) => {
                      return ( <span key={i} style={{ "--s": (0.5 / 14) * (i + 1) + "s" }}>{e}</span> );})}
                    <br />
                  </div>
                </div>
              </div>
              <div className={style.pcChooseOneTwoTwoRight}>
                <div>
                  <img width={this.state.screenWidth * 0.0458} src={ic_gxh} alt="gxh"/>
                  <div
                    className={style.ChooseCircleFour}
                    style={{ width: this.state.screenWidth * 0.0458, height: this.state.screenWidth * 0.0458 }}>
                    <div className={style.pcChooseCircleLittle} 
                      style={{ width: this.state.screenWidth * 0.006,height: this.state.screenWidth * 0.006, left: this.state.screenWidth * 0.017 }}></div>
                  </div>
                </div>
                <div className={style.pcChooseContentTwoRight} style={{ fontSize: this.state.screenWidth * 0.0103 }}>
                  <span>定制</span>
                  <br />
                  <span className={style.contentBottomRight} style={{ fontSize: this.state.screenWidth * 0.00729 }}>
                    {this.individuality.split("").map((e, i) => {
                      return ( <span key={i} style={{ "--s": (0.5 / 5) * (i + 1) + "s" }}>{e}</span>); })}
                    <br />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={style.pcChooseCanvas}><CanvasPcThree /></div>
          <div className={style.pcChooseCanvasImg}><img width={window.innerWidth * 0.07} src={img_modulels} alt="modulels" /></div>
        </div>
        </>
        )
    }
}


export default PcUs;