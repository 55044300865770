import React from "react";
import PcProductname from "./pc/Productname";
import PhoneProductname from "./phone/Productname";
import Line from "./widget/Line"

class Commodity extends React.Component {
  render() {
    return (
      <div style={{ position: "relative", overflow: "hidden", backgroundColor: "rgb(224, 237, 242)" }}>
        <Line />
        <div style={{ position: "relative" }}>{this.state.one}</div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = { one: "" }
  }

  UNSAFE_componentWillMount() {
    if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.setState({ one: <PcProductname parent={this} history={this.props.history} /> });
    } else {
      this.setState({ one: <PhoneProductname parent={this} /> });
    }
  }
}

export default Commodity;
