import React from "react";
import "../css/Home.css";
import UrlList from "./utils/UrlList";

import logoS from "../img/ic_logoS.jpg";
import logoW from "../img/ic_logoW.jpg";
import arrows from "../img/ic_arrows.jpg";
import scan from "../img/ic_scan.jpg";
import phone from "../img/bg_phone.jpg";
import model from "../img/bg_GirlModel1.jpg";
import chat from "../img/bg_chat.jpg";
import play from "../img/ic_play.jpg";
import factory from "../img/ic_factory.jpg";
import branding from "../img/ic_branding.jpg";
import designer from "../img/ic_designer.jpg";
import myself from "../img/ic_myself.jpg";
import chooseFactory from "../img/ic_chooseFactory.jpg";
import chooseBranding from "../img/ic_chooseBranding.jpg";
import chooseDesigner from "../img/ic_chooseDesigner.jpg";
import chooseMyself from "../img/ic_chooseMyself.jpg";
import human from "../img/bg_human.gif";
import figure from "../img/ic_figure.jpg";
import modelShow from "../img/ic_modelShow.jpg";
import freeChoose from "../img/ic_freeChoose.jpg";
import custom from "../img/ic_custom.jpg";
import cooperation from "../img/bg_cooperation.jpg";
import wechat from "../img/ic_wechat.jpg";
import email from "../img/ic_email.jpg";
import adress from "../img/ic_adress.jpg";
import north from "../img/ic_north.jpg";
import QRcode from "../img/bg_QRcode.jpg";
import IFSC from "../img/bg_IFSC.jpg";

//移动端
import returnWengold from "../img/ic_returnWengold.jpg";
import xsScan from "../img/ic_xsScan.jpg";
import xsCooperation from "../img/bg_xsCooperation.jpg";
import black from "../img/ic_black.jpg";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconIndex: 0, // 卡片索引
      cardIndex: 1,
      ifscAnmation: false,
      videoShow: false,
      isCover: false,
      QRcodeShow: false,
    };
  }
  reasonIcons = [
    { resasonIcon: figure, reasonHead: "拍照量体", reasonMain: "（手机拍照分析身形，精准量体）" },
    { resasonIcon: modelShow, reasonHead: "模特秀场", reasonMain: "（虚拟模特的时尚fashion秀）" },
    { resasonIcon: freeChoose, reasonHead: "自由选择", reasonMain: "（面料样式可以自行选择）" },
    { resasonIcon: custom, reasonHead: "时尚定制", reasonMain: "（服装和涂鸦）" },
  ]
  contactIcons = [
    { contactIcon: wechat, contactText: "盾钰孪生云" },
    { contactIcon: email, contactText: "admin@wengold.net" },
  ]
  contactIcon = [
    { contactIcon: adress, contactText: "浦东张江高科技园区，上海，中国" },
    { contactIcon: north, contactText: "52 Hope St, Mountain View, CA 94042, U.S.A" },
  ]
  icons = [
    { iconImg: factory, iconChoose: chooseFactory, head: "工厂/Factory", main_1: "柔性按量生产", main_2: "直接接单发货" },
    { iconImg: branding, iconChoose: chooseBranding, head: "品牌馆/Branding", main_1: "合体限量款", main_2: "热销时尚款" },
    { iconImg: designer, iconChoose: chooseDesigner, head: "设计师/Designer", main_1: "时尚单品定制", main_2: "独家专属设计" },
    { iconImg: myself, iconChoose: chooseMyself, head: "我自己/Myself", main_1: "我时尚.我自创", main_2: "AI智能量体定制" },
  ]
   //移动端
   xsCards = [
    { cardImg: phone, cardTextCn: "购物", cardTextEn: "Shopping" },
    { cardImg: model, cardTextCn: "走秀", cardTextEn: "Show" },
    { cardImg: chat, cardTextCn: "设计师", cardTextEn: "Designer" },
  ];
   xsContatcIcons =  [
    { contactIcon: wechat, contactText: "盾钰孪生云" },
    { contactIcon: email, contactText: "admin@wengold.net" },
    { contactIcon: adress, contactText: "浦东张江高科技园区，上海，中国" },
    { contactIcon: north, contactText: "52 Hope St, Mountain View, CA 94042, U.S.A" },
  ];
  
  //选中卡片改变样式
  handelDOSomething = (i) => {
    this.setState({ iconIndex: i });
  };

  //切换图片
  getPhone = () => {
    this.setState({ cardIndex: 0 });
  };

  getModel = () => {
    this.setState({ cardIndex: 1 });
  };

  getDesigner = () => {
    this.setState({ cardIndex: 2 });
  };

  //点击合作跳转
  goCooperation = () => {
    window.location.href = UrlList.IFSCFactory;
  };

  //IFSC二维码
  //PC端
  handeIFSC = () => {
    this.setState({ ifscAnmation: true, QRcodeShow: false, videoShow: false, isCover: false });
    document.body.parentNode.style.overflow = "hidden"; //禁止横竖向滚动条
  };
  //关闭遮罩层
  colseCover = () => {
    this.setState({ isCover: false, ifscAnmation: false });
    document.body.parentNode.style.overflow = "auto"; //恢复横竖向滚动条
  };
  //移动端
  getIFSC = () => {
    this.setState({ isCover: false, videoShow: false, QRcodeShow: false });
  };

  //返回官网
  //PC端
  backHome = () => {
    this.props.history.push("/");
  };

  //视频播放
  handeVideo = (i) => {
    this.setState({ videoShow: !this.state.videoShow, isCover: true, cardIndex: i });
  };

  //二维码显示
  doCode = () => {
    this.setState({ QRcodeShow: true, isCover: true });
  };
  render() {
    const { iconIndex,  cardIndex,  ifscAnmation, videoShow, QRcodeShow, isCover } = this.state;
    return (
      <div className="container-fluid">
        {isCover ? <div className="grayCover" onClick={this.getIFSC}></div> : null}
        {QRcodeShow ? (
          <div className="IFSCBox visible-sm visible-xs">
            <img src={IFSC} alt="IFSC" className="IFSCImg" style={{borderRadius:"4px",objectFit:"contain"}}/>
          </div>
        ) : null}
        {videoShow ? (
          <div className="VideoShow">
            <iframe src={ cardIndex === 0 ? UrlList.VideoSrc1 : UrlList.VideoSrc} title="video" scrolling="no" frameBorder="no" framespacing="0" allowFullScreen={true}></iframe>
            <div className="closeVideo visible-sm visible-xs" onClick={this.getIFSC}>
              <img src={black} alt="black" />
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className=" col-md-12 col-lg-12 visible-md visible-lg">
            <div className="headLogo">
              <div className="logoS">
                <div className="logosImg">
                  <img src={logoS} alt="logo" />
                </div>
                <div className="logosText">尚智定</div>
              </div>
              <div className="logoW" onClick={this.backHome}>
                <div className="logowImg">
                  <img src={logoW} alt="logo" />
                </div>
                <div className="logowText">WENGOLD</div>
                <div className="logowImgBack">
                  <img src={arrows} alt="logo" />
                </div>
              </div>
            </div>
          </div>
          <div className=" col-sm-12 col-xs-12 visible-sm visible-xs">
            <div className="headLogo">
              <div className="logoS" onClick={this.backHome}>
                <div className="xsloading ">
                  <img src={returnWengold} alt="logo" />
                  <span className="xsdot"></span>
                </div>
              </div>
              <div className="logoRight">
                <img src={logoS} alt="logo" className="logoRightImg" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* PC端 */}
          <div className="headMain visible-md visible-lg">
            <div className="col-md-7 col-sm-7  headMainText ">
              <div className="mainTitle" style={{ marginTop: "1.5rem" }}>
                尚智定
              </div>
              <div className="mainTitle">Infinite Fashion Fit</div>
              <div className="mainTitle ">
                <div className="loading" onMouseOver={this.handeIFSC} onMouseOut={this.colseCover}>
                  <img src={scan} alt="scanImg" className="scan" />
                  <span className="dot"></span>
                </div>
              </div>
              <div className={ifscAnmation ? "IFSCCode IFECIn" : "IFSCCode IFECOut"}>
                <img src={IFSC} alt="IFSC" className="IFSCImg" />
              </div>
            </div>
            <div className="col-md-5 col-lg-5  headMainImgs">
              <div className="mainImgFirst mainImg" onClick={this.getPhone} style={{ animation: cardIndex === 0 ? "bigger 0.5s  linear forwards" : "smaller 0.5s  linear forwards" }}>
                <img src={phone} alt="img" />
                <div className="blackCover">
                </div>
                <div className="mainPlay" onClick={this.handeVideo}>
                  <img src={play} alt="play" className="playImg" />
                </div>
                {cardIndex === 0 ? (
                  <div className="mainShow IFECIn">
                    <p>购物</p>
                    <p>Shopping</p>
                  </div>
                ) : (
                  <div className="interflow">购物</div>
                )}
              </div>
              <div
                className="mainImgSecond mainImg"
                onClick={this.getModel}
                style={{ animation: cardIndex === 0 ? "smaller 0.5s  linear forwards" : cardIndex === 1 ? "bigger 0.5s  linear forwards" : "smaller 0.5s  linear forwards" }}
              >
                <img src={model} alt="img" />
                <div className="blackCover">
                </div>
                <div className="mainPlay" onClick={this.handeVideo}>
                  <img src={play} alt="play" className="playImg" />
                </div>
                {cardIndex === 1 ? (
                  <div className="mainShow IFECIn">
                    <p>走秀</p>
                    <p>Show</p>
                  </div>
                ) : (
                  <div className="interflow ">走秀</div>
                )}
              </div>
              <div
                className="mainImgThird mainImg"
                onClick={this.getDesigner}
                style={{ animation: cardIndex === 0 ? "smaller 0.5s  linear forwards" : cardIndex === 1 ? "smaller 0.5s  linear forwards" : "bigger 0.5s  linear forwards" }}
              >
                <img src={chat} alt="img" />
                <div className="blackCover">
                </div>
                {cardIndex === 2 ? (
                  <div className="mainShow IFECIn">
                    <p>设计师</p>
                    <p>Designer</p>
                  </div>
                ) : (
                  <div className="interflow">设计师</div>
                )}
              </div>
            </div>
          </div>
          {/* 移动端 */}
          <div className="headMain visible-sm visible-xs" style={{ position: "relative" }}>
            <div className="fixedScan" onClick={this.doCode}>
              <img src={xsScan} alt="scanImg" />
            </div>
            <h1 className="text-center xsHead" style={{ fontSize: "1.22rem" }}>
              尚智定
            </h1>
            <h1 className="text-center xsHead" style={{ fontSize: "0.59rem" }}>
              Infinite Fashion Fit
            </h1>
            <div className="col-xs-12 col-sm-12 xsCards" style={{ marginLeft: "0.2rem", width: "98%" }}>
              <div className="cardsScroll">
                {this.xsCards.map((val, i) => {
                  return (
                    <div className="mainImgFirst mainImg" key={i}>
                      <img src={val.cardImg} alt="img" />
                      <div
                        className="mainPlay"
                        onClick={() => {
                          this.handeVideo(i);
                        }}
                      >
                        <img src={play} alt="play" className="playImg" style={{ display: i === 2 ? "none" : "block" }} />
                      </div>
                      <div className="mainShow">
                        <p>{val.cardTextCn}</p>
                        <p>{val.cardTextEn}</p>
                      </div>
                      <div className="xsBlackCover">
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="canDo">
            <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 doSomethingCan">
              <div className="doHead" style={{ fontSize: "0.96rem", marginTop: "1.5rem" }}>
                我们可以
              </div>
              <div className="doHead" style={{ fontSize: "0.95rem", color: "#75797D" }}>
                we can
              </div>
            </div>
            {/* PC端 */}
            {this.icons.map((val, i) => {
              return (
                <div
                  className="col-md-2 col-lg-2 visible-md visible-lg doSomething "
                  style={{ backgroundColor: iconIndex === i ? "#FF6660" : "#fff"}}
                  key={i}
                  onClick={() => {
                    this.handelDOSomething(i);
                  }}
                >
                  <div className="thingsIcon">
                    <img src={iconIndex === i ? val.iconChoose : val.iconImg} alt="factory" className="doIcons" />
                  </div>
                  <div className="iconsHead" style={{ color: iconIndex === i ? "#FFF" : "#212A34" }}>
                    {val.head}
                  </div>
                  <div className="iconsMain" style={{ color: iconIndex === i ? "#FFC8C6" : "#9AA2AA" }}>
                    {val.main_1}
                  </div>
                  <div className="iconsMain" style={{ top: "70%", color: iconIndex === i ? "#FFC8C6" : "#9AA2AA" }}>
                    {val.main_2}
                  </div>
                </div>
              );
            })}
          </div>
          {/* 移动端 */}
          <div className="xsCanDo visible-sm visible-xs ">
            <div className="col-xs-12 col-sm-12 xsCards" style={{ height: "8.56rem", marginLeft: "0.2rem", width: "98%" }}>
              <div className="cardsScroll">
                {this.icons.map((val, i) => {
                  return (
                    <div
                      className="doSomething"
                      style={{ backgroundColor: iconIndex === i ? "#FF6660" : "#fff", borderRadius: "0.16rem" }}
                      key={i}
                      onClick={() => {
                        this.handelDOSomething(i);
                      }}
                    >
                      <div className="thingsIcon">
                        <img src={iconIndex === i ? val.iconChoose : val.iconImg} alt="factory" className="doIcons" />
                      </div>
                      <div className="iconsTextBox">
                        <div className="iconsHead" style={{ paddingBottom: "1rem", color: iconIndex === i ? "#FFF" : "#212A34" }}>
                          {val.head}
                        </div>
                        <div className="iconsMain" style={{ color: iconIndex === i ? "#FFC8C6" : "#9AA2AA" }}>
                          {val.main_1}
                        </div>
                        <div className="iconsMain" style={{ color: iconIndex === i ? "#FFC8C6" : "#9AA2AA" }}>
                          {val.main_2}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="chooseUS">
            <div className="chooseMains">
              <div className="col-lg-5 col-md-5  col-sm-12 col-xs-12 chooseMain">
                <div className="chooseMainHead">
                  <div className="chooseTextCn">为什么</div>
                  <div className="chooseTextEn">why us ?</div>
                </div>
                <div className="chooseReason">
                  {this.reasonIcons.map((val, i) => {
                    return (
                      <div className="reasons" key={i}>
                        <div className="reasonsImg">
                          <img src={val.resasonIcon} alt="figure" className="reasonIcon" />
                        </div>
                        <div className="reasonsHead">{val.reasonHead}</div>
                        <div className="reasonsMain">{val.reasonMain}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 chooseMain" style={{ marginTop: "0" }}>
                <img src={human} alt="human" className="human" />
                <div className="digital">
                  <p style={{color:"#fff",fontSize:"0.44rem"}}>数字人</p>
                  <p style={{color:"#D2FE9E",fontSize:"0.3rem"}}>Digital Human</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="footer">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 fotterImg">
              <img src={cooperation} alt="cooperation" className="visible-lg visible-md" />
              <img src={xsCooperation} alt="cooperation" className="visible-sm visible-xs" />
             <div className="footCover"></div>
              <div className="fotterButton" onClick={this.goCooperation}>
                <div className="buttonBox visible-lg visible-md ">
                  <div className="buttonTextCn">合作</div>
                  <div className="buttonTextEn">Cooperation</div>
                </div>
                <p className="buttonTextCn  visible-xs" style={{ lineHeight: "30px" }}>
                  合作 <span className="buttonTextEn">Cooperation</span>
                </p>
                <p className="buttonTextCn  visible-sm">
                  合作 <span className="buttonTextEn">Cooperation</span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 contact">
              <div className="contactBox">
                {/* PC端 */}
                <div className="hidden-xs hidden-sm details">
                  <div className="detail">
                    {this.contactIcons.map((val, i) => {
                      return (
                        <div className="detailBox" key={i}>
                          <div className="detailImg">
                            <img src={val.contactIcon} alt="img" />
                          </div>
                          <div className="detailText">{val.contactText}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="detail" style={{ width: "52%" }}>
                    {this.contactIcon.map((val, i) => {
                      return (
                        <div className="detailBox" key={i}>
                          <div className="detailImg">
                            <img src={val.contactIcon} alt="img" />
                          </div>
                          <div className="detailText">{val.contactText}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* 移动端 */}
                <div className=" visible-sm visible-xs details">
                  <div className="detail">
                    {this.xsContatcIcons.map((val, i) => {
                      return (
                        <div className="detailBox" key={i}>
                          <div className="detailImg">
                            <img src={val.contactIcon} alt="img" />
                          </div>
                          <div className="detailText">{val.contactText}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="scanCom ">
                  <img src={QRcode} alt="QRcode" />
                </div>
              </div>
              <div className="address">
                <div className="addressText visible-lg visible-md" style={{paddingTop:"0.5rem"}}>
                  <a href={UrlList.ICP} style={{color: "#fff"}}> ©沪ICP备16002679号</a> - 盾钰(上海)互联网科技有限公司 COPYRIGHT© 2015-Now ALL RIGHTS RESERVED BY DUNYU CO,.LTD
                </div>
                <div className="addressText hidden-md hidden-lg">
                  <a href={UrlList.ICP}  style={{color: "#fff"}}> ©沪ICP备16002679号</a> - 盾钰(上海)互联网科技有限公司 <br />
                      Copyright© 2015-Now All Rights Reserved by DunYu Co,.Ltd
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
