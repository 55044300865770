import React from "react";
import Flame from "../../utils/Flame";
import Foot from '../../../Foot';
import site from '../../utils/IfscCommon'
import style from "../../css/PcScpage.module.css";
import img_bj from '../../images/img_bj.jpg';

class End extends React.Component {
  myClick = () => {
    window.location.href = site.address;
  };

  friendss = () => {
    window.location.href =  site.friend;
  };

  render() {
    return (
      <>
        <div className={style.pcCooperation} style={{ height: this.props.pcScreenHeight}} >
          <div className={style.pcCooperationFriend}>
            <img src={img_bj} alt = "bj" width={this.props.pcScreenWidth} height={this.props.pcScreenHeight} />
          </div>
          <div className={style.pcCooperationDiv} style={{ fontSize: this.props.pcScreenWidth * 0.0166 , lineHeight: this.props.pcScreenWidth * 0.001,}}>
            <div className={style.friendLeft}></div>
            <span className={style.friendCenter} onClick = {(e) => this.friendss(e)}>立即合作</span>
            <div className={style.friendRight}></div>
          </div>
        </div>
        <Flame />
        <Foot bgcolor={"rgba(223, 82, 76)"} ifsc_na = {true} />
      </>
    );
  }
  
}
export default End;
