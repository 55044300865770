import React, { Component } from "react";
import  HorCws from "./HorCws";
import  VerCws from "./VerCws"

// FIXME Cws Phone Page
export default class CwsAdv extends Component {
  constructor(props){
   super(props)
    this.state = {
      orientationVer:false
    }
  }

  componentDidMount() {
    window.addEventListener("orientationchange", this.orientationchange);
    this.orientationchange();
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.orientationchange);
  }

  orientationchange = () => {
    if (window.orientation === 0) {
      this.setState({
        orientationVer: true
      });
    } else {
      this.setState({
        orientationVer: false
      });
    }
  };

  render() {
    return(
      <div>{this.state.orientationVer ? <VerCws />: <HorCws />}</div>
    )
  }
}