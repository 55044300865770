import React from "react";
import classNames from 'classnames';
import style from "../css/Lanren.module.css";
import logo from '../images/ic_logo.jpg';

class Lanren extends React.PureComponent {
  constructor(props) {
    super(props);
     this.state = {
      width: window.innerWidth,
      height: this.props.status?window.innerHeight:((window.screen.height - (window.screen.height-window.innerHeight)) * window.innerWidth) / window.screen.width,
      lanrenStu: 0
     }
     this.RES_X = this.props.status?(window.innerWidth * 0.5) / 19 : window.innerWidth*0.15/19;
     this.RES_Y = this.props.status?(window.innerHeight * 0.63) / 19 :window.innerHeight*0.7/19;
     this.SIZE = 24;
     this.entities = [];
     this.LanrenTimet = '';
  }

  toggle = (targetEntity) =>  {
    const parent = this;
    this.entities.forEach(function(entity) {
      var dx = targetEntity.x - entity.x;
      var dy = targetEntity.y - entity.y;
      var distance = Math.sqrt(dx * dx + dy * dy);
      parent.LanrenTimet = setTimeout(function() {
        entity.element.className='';
        const data = entity.element.offsetWidth;
        entity.element.name = data;
        entity.element.className=style.grow;
      }, Math.round(distance * 1.8));
    })
  }

  componentWillUnmount(){
    window.clearTimeout(this.LanrenTimet);
  }

  componentDidMount(){
    this.props.onRef && this.props.onRef(this);
    var wrapper = document.getElementById('wrapper');
    for (var x = 0; x < this.RES_X; x++) {
      for (var y = 0; y < this.RES_Y; y++) {
        var el = document.createElement('div');
        el.setAttribute('class',style.grow);
        var imgs = document.createElement('img');
        imgs.src = logo;
        imgs.alt = 'load';
        el.appendChild(imgs)
        wrapper.appendChild(el);
        var entity = {
          element: el,
          x: x * this.SIZE,
          y: y * this.SIZE
        }
        el.style.left = entity.x + 'px';
        el.style.top = entity.y + 'px';
        this.entities.push(entity);
      }
    }
  }

  resize = () => {
    if(!this.props.status){
      this.setState({
        width: window.innerWidth,
        height: this.props.status?window.innerHeight:((window.screen.height - (window.screen.height-window.innerHeight)) * window.innerWidth) / window.screen.width
      })
    }
  }

  setDefault = (data) => {
    this.setState({ lanrenStu: data })
  }
  
  render() {
    return (
        <div className={classNames(this.state.lanrenStu === 0?'' :style.wrapper)} 
          style={{
            width:this.props.status?window.innerWidth * 0.5:this.state.width*0.15,
            height:this.props.status?window.innerHeight * 0.63:this.state.height*0.7,
            display:this.state.lanrenStu === 0?"none":"block"}}
          id='wrapper'
          onClick={this.toggle}></div>
    );
  }
}
export default Lanren;
