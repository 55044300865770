import React from "react";
import Home from "./pcScpage/PcScpage";
import CellPhone from "./cellPhoneScpage/CellPhoneScpage";
class Ifsc extends React.Component {
  constructor(props) {
    super(props);
    this.state = { interviewStatus: false, reception: 0, idKey: 0 };
  }

  UNSAFE_componentWillMount() {
    var parent = this;
    var userAgentInfo = navigator.userAgent;
    var visit = ["Android","iPhone","SymbianOS","Windows Phone","iPad","iPod"];
    visit.forEach(element => {
      if (userAgentInfo.includes(element)) {
        parent.state.reception += 1;
        this.setState({ idKey: this.state.idKey + 1 });
      }
    });

    if (this.state.reception > 0) {
      this.setState({ interviewStatus: true });
    } else {
      this.setState({ interviewStatus: false });
    }
  }

  render() {
    return (
      <>
        {this.state.interviewStatus ? <CellPhone /> : <Home/>}
      </>
    );
  }
}

export default Ifsc;
