import React from "react";
import Module from "./Module";
import DNA from "./img/ic_transfer.jpg";
import gltf from "../../gltf/consumption.gltf";

class Consumption extends React.Component {
  render() {
    return <Module src={DNA} zhVal="消费品数字化" enVal="Consumer Goods" module={gltf} camera="2.2" x="0.8" y="-0.4"/>
  }
}

export default Consumption;
