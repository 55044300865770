import React from "react";
import Hand from "./hand/Hand";
import PhWork from "./work/PhWork";
import End from "./end/End";

class cellPhoneScpage extends React.Component {
  scrollIntoView = (status) => {
    const anchorElement = document.getElementById(status);
    if (anchorElement) {
      anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };
  render() {
    return (
      <>
        <Hand />
        <PhWork />
        <End />
      </>
    );
  }
}

export default cellPhoneScpage;
