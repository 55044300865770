import React from "react";
import { Carousel } from "antd";
import Swiper from "swiper";
import CanvasPcOne from "./CanvasPcOne";
import CanvasPcTwo from "./CanvasPcTwo";
import CanvasPcFour from './CanvasPcFour';
import BannerImg from "./BannerImg";
import Canvas from "../../utils/Canvas";
import Lanren from "../../utils/Lanren";
import Us from '../us/PcUs';
import Work from './PcWork';
import Scan from "./Scan";
import Split from './SplitPage';
import style from "../../css/PcScpage.module.css";
import img_module from "../../images/img_model.jpg";
import female_annimation4 from "../../images/img_female_annimation4.gif";
import female_annimation5 from "../../images/img_female_annimation5.gif";
import female_annimation6 from "../../images/img_female_annimation6.gif";
import man_annimation4 from "../../images/img_man_annimation4.gif";
import man_annimation5 from "../../images/img_man_annimation5.gif";
import man_annimation6 from "../../images/img_man_annimation6.gif";
import ic_juxin from "../../images/ic_juxin.jpg";
import ic_Hand_logo from "../../images/ic_Hand_logo.jpg";
import ic_red_l from "../../images/ic_red_l.jpg";
import ic_white_r from "../../images/ic_white_r.jpg";
import Distance from "./Distance";

class Hander extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img_logo_stu: false,
      contentStu: 1,
    };
    this.female = [0,1,2,3,4,5];
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    const parent = this;
    new Swiper(".swiper-containerOne", {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        slideChangeTransitionStart: function () {
          parent.lanren.setDefault(this.activeIndex);
          if (this.activeIndex === 1) {
            parent.distance.setDefault(0);
            parent.bannerImg.setDefault(true);
          } else {
            parent.distance.setDefault(1);
            parent.bannerImg.setDefault(false);
          }
        },
      },
    });
    new Swiper(".swiper-containerThr", {
      autoplay: { delay: 5000 },
    });
    this.loadGif();
  }

  loadGif = () => {
    let img = new Image();
    img.src = man_annimation4;
    img.setAttribute("style",`width:${this.props.pcScreenWidth * 0.75}px`)
    img.setAttribute("id",`img1`)
    img.onload = () => {
      document.getElementById("img_1").appendChild(img);
      let img1 = new Image();
      img1.src = man_annimation5;
      img1.setAttribute("style",`width:${this.props.pcScreenWidth * 0.75}px`)
      img1.setAttribute("id",`img2`)
      img1.onload = () => {
        document.getElementById("img_2").appendChild(img1)
        let img2 = new Image();
        img2.src = man_annimation6;
        img2.setAttribute("style",`width:${this.props.pcScreenWidth * 0.75}px`)
        img2.setAttribute("id",`img3`)
        img2.onload = () => {
          document.getElementById("img_3").appendChild(img2)
          let img3 = new Image();
          img3.src = female_annimation4;
          img3.setAttribute("style",`width:${this.props.pcScreenWidth * 0.75}px`)
          img3.setAttribute("id",`img4`)
          img3.onload = () => {
            document.getElementById("img_4").appendChild(img3)
            let img4 = new Image();
            img4.src = female_annimation5;
            img4.setAttribute("style",`width:${this.props.pcScreenWidth * 0.75}px`)
            img4.setAttribute("id",`img5`)
            img4.onload = () => {
              document.getElementById("img_5").appendChild(img4)
              let img5 = new Image();
              img5.src = female_annimation6;
              img5.setAttribute("style",`width:${this.props.pcScreenWidth * 0.75}px`)
              img5.setAttribute("id",`img6`)
              img5.onload = () => {
                document.getElementById("img_6").appendChild(img5)
              }
            }
          }
        }
      }
    }
  }

  setGifWidth = () => {
    let arr = [1,2,3,4,5,6]
    arr.forEach((e,i)=>{
      document.getElementById(`img`+e) && document.getElementById(`img`+e).setAttribute("style",`width:${this.props.pcScreenWidth * 0.75}px`)
    })
  }

  workRef = (ref) => {
    this.work = ref;
  }
  CanvasRef = (ref) => {
    this.canvas = ref;
  }
  lanrenRef = (ref) => {
    this.lanren = ref;
  }
  CanvasPcFourRef = (ref) => {
    this.canvasPcFour = ref;
  }
  splitRef = (ref) => {
    this.split = ref;
  }
  splitTwoRef = (ref) => {
    this.splitTwo = ref;
  }
  distanceRef = (ref) => {
    this.distance = ref;
  }
  BannerImgRef = (ref) => {
    this.bannerImg = ref;
  }
  usRef = (ref) => {
    this.us = ref;
  }

  render() {
    let pcScreenHeight = this.props.pcScreenHeight;
    let pcScreenWidth = this.props.pcScreenWidth;
    let widthCircle = pcScreenWidth * 0.025;
    return (
      <>
        <div className={style.pcScpageBanner} style={{ height: pcScreenHeight }}>
          <div className={style.myDiv}>
            <div className={style.bannerContentOne}>
              <div className={style.bannerOneText}>
                <div style={{ fontSize: pcScreenWidth * 0.01 }}>
                  <span>Infinite Fashion Fit </span>
                  <img src={ic_juxin} alt="juxin" width={pcScreenWidth * 0.015}/>
                </div>
              </div>
              <BannerImg onRef={this.BannerImgRef} pcScreenWidth={pcScreenWidth} />
            </div>
            <div style={{ marginTop: pcScreenHeight * 0.1,marginLeft: pcScreenWidth * 0.26}}>
              <img src={ic_Hand_logo} alt="hand" className={style.Hand_logo} />
            </div>
            <div id="contaniner" className={style.bannerContentThree} style={{transform:'translate(0px,'+pcScreenHeight*0.15+'px)'}}>
              <div className={`${style.contentThreeLeft} swiper-button-prev`}
                style={{ opacity: this.state.contentStu === 0 ? "1" : "0", width: pcScreenWidth * 0.02, height: pcScreenHeight * 0.05 }}>
                <img className={style.contentLeft} src={ic_white_r} alt="right" height = {pcScreenWidth * 0.01} style = {{minHeight:'10px'}}/>
              </div>
              <div id="contaniner" className={`${style.contentThreeRight} swiper-button-next`}
                style={{ opacity: this.state.contentStu === 1 ? "1" : "0", width: pcScreenWidth * 0.02, height: pcScreenHeight * 0.05 }}>
                <img src={ic_red_l} className={style.contentRight} alt="left" height = {pcScreenWidth * 0.01}style = {{minHeight:'10px'}}/>
              </div>
            </div>
            <Distance pcScreenHeight = {pcScreenHeight} pcScreenWidth = {pcScreenWidth} onRef={this.distanceRef} />
            <div>
              <Canvas status = {false} pcScreenHeight = {pcScreenHeight} pcScreenWidth = {pcScreenWidth} onRef = {this.CanvasRef} />
            </div>
              <Scan widthCircle={widthCircle}/>
            <div className={style.myCanLanren} style={{ height: pcScreenHeight * 0.7,top:pcScreenHeight*0.28}}>
              <Lanren onRef={this.lanrenRef} status = {false} pcScreenHeight = {pcScreenHeight} pcScreenWidth = {pcScreenWidth} />
            </div>
          </div>
          <div className={style.myCan}>
            <div style={{ height: pcScreenHeight }}>
              <div className="swiper-containerOne" style={{ height: "100%" }}>
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <CanvasPcFour onRef={this.CanvasPcFourRef} pcScreenHeight = {pcScreenHeight} pcScreenWidth = {pcScreenWidth} />
                    <div className={style.pcCarouselGif}>
                      <Carousel autoplay="true" effect="fade" dots="false">
                        {this.female.map((e,i)=>{
                          return <div key={i} id={`img_${i + 1}`}></div>
                        })}
                      </Carousel>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className={style.myCanImg}>
                      <img src={img_module} className={style.myCanImgShow} width={window.innerWidth * 0.15} alt="img_module"/>
                      <CanvasPcTwo pcScreenHeight = {pcScreenHeight} pcScreenWidth = {pcScreenWidth} />
                    </div>
                    <CanvasPcOne pcScreenHeight = {pcScreenHeight} pcScreenWidth = {pcScreenWidth} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Split onRef={this.splitRef} splitPage = {"splitOne"} />
        <div id="splitOne"></div>
        <Work pcScreenHeight = {pcScreenHeight} pcScreenWidth = {pcScreenWidth} onRef={this.workRef}/>
        <div id="splitTwo"></div>
        <Us onRef={this.usRef} />
        <Split onRef={this.splitTwoRef} splitPage = {"splitThree"} />
        <div id="splitThree"></div>
      </>
    );
  }
}

export default Hander;
