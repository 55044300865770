import React from "react";
import ic_red_under from "../../images/ic_red_under.jpg";
import style from "../../css/PcScpage.module.css";

export default class ContentBottom extends React.Component {
  constructor(props){
    super(props);
    this.state = { contentImgTwo: 0 }
  }
  render() {
    return (
      <div className={style.contentBottom}
            id="contentBottom"
            onClick={() => this.props.scrollIntoView("splitTwo")}
            style={{ transform: "translate(0," + this.state.contentImgTwo + "px)" }}>
            <img className={style.contentBottomImg} src={ic_red_under} alt="logo" height = {this.props.screenWidth * 0.009} style = {{minHeight: '10px'}}/>
          </div>
    );
  }
  componentDidMount(){
    this.props.onRef && this.props.onRef(this);
    this.changeImg();
  }

  setDefault = (data,size) => {
    this.setState({ 
      contentImgTwo: data,
      screenHeight: size
    })
  }

  changeImg = () =>{
    this.pcWorktimer = window.requestAnimationFrame(this.changeImg);
    if (this.state.screenHeight > 487) {
      if (
        this.state.contentImgTwo < -this.state.screenHeight * 0.08 &&
        this.state.contentNumTwo === 0
      ) {
        this.setState({ contentImgTwo: this.state.contentImgTwo + 0.3 });
      } else if (
        this.state.contentImgTwo > -this.state.screenHeight * 0.1 &&
        this.state.contentNumTwo === 1
      ) {
        this.setState({ contentImgTwo: this.state.contentImgTwo - 0.3 });
      }
      if (this.state.contentImgTwo >= -this.state.screenHeight * 0.08) {
        this.setState({ contentNumTwo: 1 });
      } else if (this.state.contentImgTwo <= -this.state.screenHeight * 0.1) {
        this.setState({ contentNumTwo: 0 });
      }
    } else {
      if (this.state.contentImgTwo < -18.96 && this.state.contentNumTwo === 0) {
        this.setState({ contentImgTwo: this.state.contentImgTwo + 0.5 });
      } else if (
        this.state.contentImgTwo > -28.7 &&
        this.state.contentNumTwo === 1
      ) {
        this.setState({ contentImgTwo: this.state.contentImgTwo - 0.5 });
      }
      if (this.state.contentImgTwo >= -18.96) {
        this.setState({ contentNumTwo: 1 });
      } else if (this.state.contentImgTwo <= -28.7) {
        this.setState({ contentNumTwo: 0 });
      }
    }
  }

}
