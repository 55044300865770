import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import 'raf/polyfill';
import "rc-texty/assets/index.css";
import "antd/dist/antd.css";
import "swiper/css/swiper.min.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';


ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
