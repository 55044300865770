import React from "react";
import style from "./css/Paste.module.css";
import paster from "./img/ic_paster.jpg";
import phone from "./img/ic_phone.jpg";
import drug from "./img/img_drug.jpg";
import el from "./img/img_el.jpg";
import jiu from "./img/img_jiu.jpg";
import kh from "./img/img_kh.jpg";
import bg from "./img/img_bg.jpg";

class Paste extends React.Component {
  render() {
    return (
      <div className={style.bg} style={{ height: window.innerHeight, minHeight: "723px " }}>
        <div className={style.paste_top}>
          <span className={style.paste_zh}>闻宝粘贴码</span>
          <div className={style.pendant}>
            <span className={style.paste_wire}></span>
            <a href="https://wengold.net/paster/w/index.html">
              <img src={paster} alt="paster"/>
            </a>
          </div>
        </div>
        <div className={style.paste_bottom}>
          <img src={kh} className={style.paste_kh} alt="kh"/>
          <img src={drug} className={style.paste_drug} alt="drug"/>
          <img src={jiu} className={style.paste_jiu} alt="jiu"/>
          <img src={phone} className={style.paste_phone} alt="phone"/>
          <img src={el} className={style.paste_el} alt="el"/>
        </div>
        <img src={bg} className={style.bgs} alt="bg"/>
      </div>
    );
  }
}

export default Paste;
