import React from 'react';
import { createHashHistory } from 'history';
import arrows from "../img/ic_back.png";
import ic_dico_logo from "../img/ic_dico_logo.png";
import ic_bg_left from "../img/ic_bg_left.png";
import ic_bg_right from "../img/ic_bg_right.png";
import Style from "../css/Dico.module.css";
const history = createHashHistory();
const Dico = () => {
  const backHome = () => {
    history.push("/");
  }
  return (
    <>
      <div className={Style.content}>
        <div className={Style.head}>
          <div className={Style.head_con} >
            <div className={Style.head_l}>
              <img src={ic_dico_logo} alt="img"/>
              <span>DICO</span>
            </div>
            <img className={Style.head_r} onClick={() => backHome()} src={arrows} alt="img" />
          </div>
          <span className={Style.head_title}>关于DICO</span>
          <span className={Style.head_intr}>DICO是一款关注于服装设计行业社区类应用APP，平台具有社交分享互动属性，分享喜爱的创作者作品，有助于在社交媒体上传播。为具有独特创意新锐设计师提供作品展示的空间，创作者可以通过更新、回报和互动，建立更紧密的联系。建立有共同兴趣爱好的服装设计粉丝群，一起交流与提升品质。</span>
        </div>

        <div className={Style.con_bot}>
          <div className={Style.con_bot_item}>
            <img src={ic_bg_left} alt="img"/>
            <span>发布创作</span>
          </div>
          <div className={Style.con_bot_item}>
            <img src={ic_bg_right} alt="img"/>
            <span>新锐设计师</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dico